import { styled } from "@mui/material/styles";
import React from "react";
import wrapSvgText from "../utils/wrapSvgText";

const StyledText = styled("text")(({ color, theme }) => ({
  fill: color || theme.palette.text.primary,
  stroke: "none",
  textAnchor: "middle",
  dominantBaseline: "central",
  pointerEvents: "none"
}));

export interface Props {
  className?: string;
  x?: number;
  y?: number;
  width: number;
  height: number;
  rotation?: number;
  color?: string;
  children: React.ReactNode;
}

export const SvgText = ({
  className = "",
  x = 0,
  y = 0,
  width,
  height,
  rotation = 0,
  color,
  children
}: Props) => {
  if (typeof children !== "string") return;
  const { lines, size } = wrapSvgText(children, width);
  const lineHeight = size.height / lines.length;
  return lines.map((line, index) => (
    <StyledText
      key={`svgtextline${index}`}
      className={className}
      x={x + width / 2}
      y={y + height / 2 - size.height / 2 + lineHeight * (index + 0.5)}
      transform={`rotate(${rotation},${x},${y})`}
      color={color}
    >
      {line}
    </StyledText>
  ));
};
