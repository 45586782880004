import { SeedTypes } from "../schema";
/**
 * Generate an element identifier for the given type.
 * @param type The type of the identifier to get.
 * @param seeds The object containing the seeds for each type. The seed of the corresponding type will be incremented.
 * @returns a unique identifier for the given type.
 */
export default function generateElementId(type, seeds) {
    const seedType = SeedTypes.parse(type);
    let prefix = getElementPrefix(type);
    const uid = `${prefix}.${seeds[seedType]}`;
    seeds[seedType]++;
    return uid;
}
export function getElementPrefix(type) {
    switch (type) {
        // Annotation types
        case "Action":
            return "AI";
        case "Assumption":
            return "AS";
        case "Lesson":
            return "L";
        case "Opportunity":
            return "O";
        case "Risk":
            return "R";
        // Component types
        case "Data":
            return "D";
        case "Facility":
            return "F";
        case "Hardware":
            return "HW";
        case "Organization":
            return "ORG";
        case "Person":
            return "P";
        case "Product":
            return "PRD";
        case "Project":
            return "PRJ";
        case "Service":
            return "S";
        case "Software":
            return "SW";
        case "System":
            return "SYS";
        // Other types
        case "Milestone":
            return "MS";
        case "Requirement":
            return "REQ";
        case "Stakeholder":
            return "SH";
        default:
            throw new Error(`Cannot generate element ID for type: ${type}`);
    }
}
