import { utils as xlsx } from "xlsx";
import { findElementParents } from "../../search";
import findElementInModel from "../../search/findElementInModel";
import formatDate from "../../utils/formatDate";
import formatWorksheet from "./formatWorksheet";
export default function createAnnotationsSheet(model, mode = undefined) {
    const annotations = mode
        ? model.annotations.filter(a => a.attributes.type === mode)
        : model.annotations;
    const rows = annotations.map(a => {
        const elementId = a.relationships.find(r => r.type === "annotates")?.targetId || "";
        const element = findElementInModel(model, elementId);
        const elementName = element && "name" in element.attributes ? element.attributes.name : "";
        const elementOwner = elementId
            ? findElementParents(model.stakeholders, elementId, "responsible_for")[0]
                ?.attributes.name || ""
            : "";
        const { text, type, priority, closedOn, remarks } = a.attributes;
        const row = {
            id: a.id,
            text,
            priority: closedOn ? "Closed" : priority,
            owner: findElementParents(model.stakeholders, a.id, "responsible_for")[0]
                ?.attributes.name || "",
            remarks: remarks || "",
            closedOn: closedOn ? formatDate(closedOn) : "",
            elementName,
            elementId,
            elementOwner
        };
        if (!mode) {
            row.type = type;
        }
        return row;
    });
    const ws = xlsx.json_to_sheet(rows);
    const headers = [
        "ID",
        `${mode || "Annotation"}`,
        "Priority",
        "Owner",
        "Remarks",
        "Closed On",
        "Element Name",
        "Element ID",
        "Element Owner"
    ];
    if (!mode)
        headers.splice(1, 0, "Type");
    xlsx.sheet_add_aoa(ws, [headers], { origin: "A1" });
    formatWorksheet(ws, [headers, ...rows.map(r => Object.values(r))], {
        groupCols: [7, 8, 9]
    });
    return ws;
}
