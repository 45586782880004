import ChecklistIcon from "@mui/icons-material/Checklist";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import ListIcon from "@mui/icons-material/List";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import SpeakerNotesOutlinedIcon from "@mui/icons-material/SpeakerNotesOutlined";
import { SvgIconProps } from "@mui/material/SvgIcon";

export enum Type {
  Allocation = "Allocation",
  Annotations = "Annotations",
  Description = "Description",
  Link = "Link",
  Specification = "Specification"
}

export interface Props extends SvgIconProps {
  type: Type;
  isOn: boolean;
}

export default function InteractionIcon({ type, isOn, ...props }: Props) {
  props.opacity = isOn ? 1.0 : 0.5;

  switch (type) {
    case Type.Allocation:
      return isOn ? <ListIcon {...props} /> : <ListOutlinedIcon {...props} />;
    case Type.Annotations:
      return isOn ? (
        <SpeakerNotesIcon {...props} />
      ) : (
        <SpeakerNotesOutlinedIcon {...props} />
      );
    case Type.Description:
      return isOn ? (
        <DescriptionIcon {...props} />
      ) : (
        <DescriptionOutlinedIcon {...props} />
      );
    case Type.Link:
      return isOn ? <LinkIcon {...props} /> : <LinkOffIcon {...props} />;
    case Type.Specification:
      return isOn ? (
        <ChecklistIcon {...props} />
      ) : (
        <ChecklistOutlinedIcon {...props} />
      );
  }
}
