import { ElementRelationshipTypes } from "../schema";
export default function getTargetMetatypes(type) {
    const metatypes = [];
    Object.keys(ElementRelationshipTypes).forEach(emt => {
        const relationships = ElementRelationshipTypes[emt];
        if (relationships.targetOf.includes(type)) {
            metatypes.push(emt);
        }
    });
    return metatypes;
}
