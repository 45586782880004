export default function formatWorksheet(ws, rows, options) {
    const MIN_COLUMN_WIDTH = 15;
    const MAX_COLUMN_WIDTH = 100;
    const columnWidths = rows.length
        ? rows[0].map(h => MIN_COLUMN_WIDTH)
        : [];
    rows.forEach(r => r.forEach((value, c) => {
        if (c >= columnWidths.length)
            return;
        if (value.length > columnWidths[c]) {
            columnWidths[c] = value.length + 1;
        }
    }));
    ws["!cols"] = columnWidths.map((w, i) => {
        const colInfo = {
            width: Math.min(w, MAX_COLUMN_WIDTH)
        };
        if (options && options.groupCols?.includes(i)) {
            colInfo.level = 1;
        }
        return colInfo;
    });
    ws["!outline"] = { above: true, left: true };
}
