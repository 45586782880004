import {
  ActionReducerMapBuilder,
  createSelector,
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit";
import * as ModelModifiers from "@se-toolkit/model-js/modifiers";
import {
  ElementAttributes,
  ElementMetatype,
  ElementType,
  Model,
  ModificationInstruction,
  Relationship,
  RelationshipType
} from "@se-toolkit/model-js/schema";
import {
  createEmptyModel,
  getElementsArray
} from "@se-toolkit/model-js/schemautils";
import {
  copyModel,
  getTimeStamp,
  handleError
} from "@se-toolkit/model-js/utils";
import { validateModel } from "@se-toolkit/model-js/validators";
import { loadState } from "../../utils/persistState";
import { RootState } from "../store";
import { fetchModel } from "./fetchModel";

export interface ModelState {
  error: string | null;
  lastSaved: number; // timestamp in seconds
  model: Model;
  modificationCounter: number; // number of changes
}

const modelId = localStorage.getItem("modelId");
let persistedModel: Model | null = modelId
  ? (loadState(modelId) as Model)
  : (loadState("model") as Model); // backwards compabitibility with versions < 0.13
try {
  validateModel(persistedModel);
} catch {
  persistedModel = null;
}

const createInitialState = (model = persistedModel): ModelState => ({
  error: null,
  lastSaved: getTimeStamp(),
  model: model || createEmptyModel(),
  modificationCounter: 0
});

const modifyModel = (state: ModelState, fn: (copy: Model) => boolean) => {
  const copy = copyModel(state.model);
  try {
    if (fn(copy)) {
      state.model = copy;
      state.modificationCounter++;
    }
  } catch (e: any) {
    state.error = handleError(e, "parse");
  }
};

const modelSlice = createSlice({
  name: "model",
  initialState: createInitialState(),
  reducers: {
    applyModification(state, action: PayloadAction<ModificationInstruction>) {
      modifyModel(state, copy =>
        ModelModifiers.applyModification(copy, action.payload)
      );
    },
    clearError(state) {
      state.error = null;
    },
    createElement(
      state,
      action: PayloadAction<{
        metatype: ElementMetatype;
        attributes: Partial<ElementType<ElementMetatype>["attributes"]>;
        relateToTargetId?: string;
        relateToSourceId?: string;
        relationshipType?: RelationshipType;
      }>
    ) {
      const {
        metatype,
        attributes,
        relateToTargetId,
        relateToSourceId,
        relationshipType
      } = action.payload;
      modifyModel(state, copy => {
        const element = ModelModifiers.createElement(
          copy,
          metatype,
          attributes
        );
        if ((relateToTargetId || relateToSourceId) && relationshipType) {
          ModelModifiers.createRelationship(copy, {
            sourceId: relateToSourceId ? relateToSourceId : element.id,
            targetId: relateToTargetId ? relateToTargetId : element.id,
            type: relationshipType
          });
        }
        return !!element;
      });
    },
    createNewModel(state) {
      return createInitialState(createEmptyModel());
    },
    createRelationship(state, action: PayloadAction<Relationship>) {
      modifyModel(state, copy =>
        ModelModifiers.createRelationship(copy, action.payload)
      );
    },
    deleteElements(state, action: PayloadAction<string[]>) {
      modifyModel(state, copy =>
        ModelModifiers.deleteElements(copy, action.payload)
      );
    },
    deleteRelationship(state, action: PayloadAction<Relationship>) {
      modifyModel(state, copy =>
        ModelModifiers.deleteRelationship(copy, action.payload)
      );
    },
    insertRelationship(
      state,
      action: PayloadAction<{
        id: string;
        relationship: Relationship;
        oldSourceIds?: string[];
      }>
    ) {
      const { id, relationship, oldSourceIds } = action.payload;
      modifyModel(state, copy =>
        ModelModifiers.insertRelationship(copy, id, relationship, oldSourceIds)
      );
    },
    loadModel(state, action) {
      return createInitialState(action.payload);
    },
    moveRelationships: (
      state,
      action: PayloadAction<{
        relationships: Relationship[];
        newSourceId: string;
      }>
    ) => {
      const { relationships, newSourceId } = action.payload;
      modifyModel(state, copy =>
        ModelModifiers.moveRelationships(copy, relationships, newSourceId)
      );
    },
    purgeChangeLogs(
      state,
      action: PayloadAction<
        | {
            preserveLastEntries?: number;
            preserveAfterDate?: number;
          }
        | undefined
      >
    ) {
      modifyModel(state, copy => {
        ModelModifiers.purgeChangeLogs(copy, action.payload);
        return true;
      });
    },
    replaceRelationship(
      state,
      action: PayloadAction<{
        relationship: Relationship;
        newTargetId: string;
      }>
    ) {
      const { relationship, newTargetId } = action.payload;
      modifyModel(state, copy =>
        ModelModifiers.replaceRelationship(copy, relationship, newTargetId)
      );
    },
    resetModificationCounter(state) {
      state.modificationCounter = 0;
    },
    setLastSaved(state) {
      state.lastSaved = getTimeStamp();
    },
    toggleRelationship(state, action: PayloadAction<Relationship>) {
      const copy = copyModel(state.model);
      try {
        if (
          ModelModifiers.deleteRelationship(copy, action.payload) ||
          ModelModifiers.createRelationship(copy, action.payload)
        ) {
          state.model = copy;
          state.modificationCounter++;
        }
      } catch (e: any) {
        state.error = handleError(e, "parse");
      }
    },
    updateElement(
      state,
      action: PayloadAction<{
        id: string;
        attributes: ElementAttributes;
      }>
    ) {
      const { id, attributes } = action.payload;
      modifyModel(state, copy =>
        ModelModifiers.updateElement(copy, id, attributes)
      );
    },
    updateElementId(
      state,
      action: PayloadAction<{
        oldId: string;
        newId: string;
      }>
    ) {
      const { oldId, newId } = action.payload;
      modifyModel(state, copy =>
        ModelModifiers.updateElementId(copy, oldId, newId)
      );
    },
    updateModelName(
      state,
      action: PayloadAction<{
        newName: string;
        generateNewId?: boolean;
      }>
    ) {
      const { newName, generateNewId } = action.payload;
      modifyModel(state, copy =>
        ModelModifiers.updateModelName(copy, newName, generateNewId)
      );
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<ModelState>) => {
    builder.addCase(fetchModel.fulfilled, (state, action) => {
      return createInitialState(action.payload);
    });
  }
});

export const selectModelError = (state: RootState) => state.model.present.error;
export const selectModel = (state: RootState) => state.model.present.model;
export const makeSelectModelElements = <T extends ElementMetatype>(
  metatype: T
) =>
  createSelector(
    (state: RootState) => state.model.present,
    (state: ModelState) => {
      return getElementsArray(state.model, metatype);
    }
  );
export const selectModificationCounter = (state: RootState) =>
  state.model.present.modificationCounter;
export const selectLastSaved = (state: RootState) =>
  state.model.present.lastSaved;

export const {
  applyModification,
  clearError,
  createElement,
  createNewModel,
  createRelationship,
  deleteElements,
  deleteRelationship,
  insertRelationship,
  loadModel,
  moveRelationships,
  purgeChangeLogs,
  updateModelName,
  replaceRelationship,
  resetModificationCounter,
  setLastSaved,
  toggleRelationship,
  updateElement,
  updateElementId
} = modelSlice.actions;

export default modelSlice.reducer;
