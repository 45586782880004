import Grid from "@mui/material/Grid";
import { PieValueType } from "@mui/x-charts/models/seriesType/pie";
import { countComponents, countMetatypes } from "@se-toolkit/model-js/analysis";
import { ComponentType, ElementMetatype } from "@se-toolkit/model-js/schema";
import React from "react";
import { useSelector } from "react-redux";
import { selectModel } from "../../../features";
import { SETColours } from "../../../theme";
import PaperPie from "../../components/PaperPie";

export default function ComponentStats() {
  const model = useSelector(selectModel);

  const metatypeCount = React.useMemo(() => countMetatypes(model), [model]);
  const metatypesData = React.useMemo(() => {
    const metatypesData: PieValueType[] = [];
    for (const [key, value] of metatypeCount.items) {
      metatypesData.push({
        id: key,
        value,
        label: key,
        color: SETColours.metatype[key as ElementMetatype]
      });
    }
    return metatypesData;
  }, [metatypeCount]);

  const componentCount = React.useMemo(
    () => countComponents(model.components),
    [model.components]
  );
  const componentTypesData = React.useMemo(() => {
    const componentTypesData: PieValueType[] = [];
    for (const [key, value] of componentCount.items) {
      componentTypesData.push({
        id: key,
        value,
        label: key,
        color: SETColours.componentType[key as ComponentType]
      });
    }
    return componentTypesData;
  }, [componentCount]);

  return (
    <Grid
      sx={{ margin: 0, padding: 0 }}
      container={true}
      spacing={1}
      justifyContent="center"
    >
      <Grid item={true} xs={12} md={6}>
        <PaperPie
          title={`Elements\n(total: ${metatypeCount.total})`}
          data={metatypesData}
        />
      </Grid>
      <Grid item={true} xs={12} md={6}>
        <PaperPie
          title={`Components\n(total: ${componentCount.total})`}
          data={componentTypesData}
        />
      </Grid>
    </Grid>
  );
}
