import { findElement, findElementParents } from "@se-toolkit/model-js/search";
import React from "react";
import { useSelector } from "react-redux";
import { makeSelectModelElements } from "../../../features";
import { Shape } from "../../../layouts";
import { MapElement } from "../../diagrams/shapes/MapElement";
import useUpdateMilestoneStatus from "../../featurehooks/useUpdateMilestoneStatus";
import { Anchor } from "../../interactions/useAnchor";
import calculateMilestoneStatusColours from "./calculateMilestoneStatusColours";

export interface Args {
  selectedIds?: string[];
  annotationsAnchor?: Anchor;
  descriptionAnchor?: Anchor;
}

export default function useDrawMilestone({
  selectedIds = [],
  annotationsAnchor,
  descriptionAnchor
}: Args) {
  const annotations = useSelector(makeSelectModelElements("Annotation"));
  const milestones = useSelector(makeSelectModelElements("Milestone"));

  const { decrementStatus, incrementStatus } = useUpdateMilestoneStatus();

  return React.useCallback(
    (shape: Shape) => {
      const milestone = findElement(milestones, shape.id);
      if (!milestone) {
        console.warn("Milestone not found", shape.id);
        return null;
      }

      const { leftFill, rightFill } = calculateMilestoneStatusColours(
        milestone.attributes.status
      );

      const hasDescription: boolean = !!milestone.attributes.description;
      const hasAnnotations: boolean = !!findElementParents(
        annotations,
        milestone.id,
        "annotates"
      ).length;

      return (
        <MapElement
          shape={shape}
          text={milestone.attributes.name}
          leftFill={leftFill}
          rightFill={rightFill}
          hasAnnotations={hasAnnotations}
          hasDescription={hasDescription}
          isSelected={selectedIds.includes(shape.id)}
          areAnnotationsFixed={annotationsAnchor?.isFixed}
          isDescriptionFixed={descriptionAnchor?.isFixed}
          handleClickLeft={() =>
            decrementStatus(shape.id, milestone.attributes.status)
          }
          handleClickRight={() =>
            incrementStatus(shape.id, milestone.attributes.status)
          }
          setAnnotationsAnchor={annotationsAnchor?.set}
          setDescriptionAnchor={descriptionAnchor?.set}
        />
      );
    },
    [
      annotationsAnchor?.isFixed,
      annotationsAnchor?.set,
      annotations,
      decrementStatus,
      descriptionAnchor?.isFixed,
      descriptionAnchor?.set,
      incrementStatus,
      milestones,
      selectedIds
    ]
  );
}
