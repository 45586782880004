import { useTheme } from "@mui/material/styles";
import React from "react";
import { Layout, Shape } from "../../../layouts";

export interface Props {
  layout: Layout;
  drawShape: (shape: Shape) => React.ReactNode;
}

export const ShapesLayer = ({ layout, drawShape }: Props) => {
  const theme = useTheme();

  const shapes = layout.shapes.map(shape => {
    return <React.Fragment key={shape.id}>{drawShape(shape)}</React.Fragment>;
  });

  return (
    <g fill={theme.palette.shape.fill} stroke={theme.palette.shape.stroke}>
      {shapes}
    </g>
  );
};
