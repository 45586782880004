import SaveIcon from "@mui/icons-material/SaveAlt";
import IconButton from "@mui/material/IconButton";
import { Theme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { importModelFromJsonString } from "@se-toolkit/model-js/import-export";
import { handleError } from "@se-toolkit/model-js/utils";
import React from "react";
import { enqueueSnackbar, loadModel, useAppDispatch } from "../../features";
import AlertDialog from "../AlertDialog";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      // override styles here
    },
    button: {
      margin: theme.spacing(1)
    },
    input: {
      display: "none"
    },
    icon: {
      color: theme.palette.secondary.main,
      transform: "rotate(180deg)"
    }
  });

export interface Props extends WithStyles<typeof styles> {}

const FileInputButton = ({ classes }: Props) => {
  const dispatch = useAppDispatch();

  const [warningOpen, setWarningOpen] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  function handleInput(event: React.ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;

    if (files == null || files.length < 1) {
      return;
    }
    const file = files[0];

    const reader = new FileReader();

    reader.onload = () => {
      try {
        const json = importModelFromJsonString(reader.result);
        dispatch(loadModel(json));
      } catch (e: any) {
        dispatch(
          enqueueSnackbar(handleError(e, "parse"), { variant: "error" })
        );
      }
    };

    reader.readAsText(file);
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleInput(e);
    if (inputRef.current) {
      inputRef.current.value = ""; // solves: choosing the same file doesn't trigger the onChange event
    }
  };

  const onClick = () => {
    setWarningOpen(true);
  };

  const onClose = () => {
    setWarningOpen(false);
  };

  const onSubmit = () => {
    setWarningOpen(false);
    inputRef.current!.click();
  };

  return (
    <div className={classes.root}>
      <input
        ref={inputRef}
        accept=".json"
        className={classes.input}
        id="icon-button-file"
        type="file"
        onChange={onChange}
      />
      <Tooltip
        title="Import"
        aria-label="Import"
        placement="bottom"
        disableFocusListener={true}
      >
        <IconButton
          className={classes.button}
          aria-label="Import"
          onClick={onClick}
          size="large"
        >
          <SaveIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
      <AlertDialog
        question="Discard the current model and import another one?"
        explanation="Imports a model.
        The data is handled locally by the browser and is not sent to a remote server.
        The current model will be lost so be sure that you have exported it before importing another one."
        open={warningOpen}
        handleClose={onClose}
        handleSubmit={onSubmit}
      />
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(FileInputButton);
