// Define the relationships which are bi-directional
export const isBidirectionalRelationshipType = (type) => {
    return ["input_from"].includes(type);
};
// Define the relationships applicable to each element type
export const ElementRelationshipTypes = {
    Annotation: {
        sourceOf: ["annotates"],
        targetOf: ["responsible_for"]
    },
    Component: {
        sourceOf: ["built_from", "input_from", "specified_by"],
        targetOf: ["annotates", "built_from", "input_from", "responsible_for"]
    },
    Milestone: {
        sourceOf: ["preceded_by"],
        targetOf: ["annotates", "preceded_by", "responsible_for"]
    },
    Requirement: {
        sourceOf: ["derived_from"],
        targetOf: ["annotates", "derived_from", "responsible_for", "specified_by"]
    },
    Stakeholder: {
        sourceOf: ["responsible_for"],
        targetOf: ["annotates", "responsible_for"]
    }
};
