import React from "react";

export default function useStopPropogation() {
  return React.useCallback((e: React.KeyboardEvent) => {
    if (["Enter", "Escape"].includes(e.key)) return;
    if (e.key.match(/^f{1}[0-9]{1}[0-2]{0,1}$/)) return;
    if (["d", "s", "y", "z"].includes(e.key) && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      return;
    }
    e.stopPropagation();
  }, []);
}
