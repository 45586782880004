import React from "react";
import { useWindowEventListener } from "../interactions";

export default function useRefDimensions(ref: React.RefObject<HTMLDivElement>) {
  const [width, setWidth] = React.useState(ref.current?.clientWidth || 0);
  const [height, setHeight] = React.useState(ref.current?.clientHeight || 0);

  const handleResize = React.useCallback(() => {
    const element = ref.current;
    if (element) {
      setWidth(element.clientWidth);
      setHeight(element.clientHeight);
    }
  }, [ref]);

  React.useLayoutEffect(() => {
    handleResize();
  }, [handleResize]);

  useWindowEventListener("resize", handleResize);

  return {
    width,
    height
  };
}
