import { utils as xlsx } from "xlsx";
import { findElement } from "../../search";
import formatWorksheet from "./formatWorksheet";
export default function createInterfacesSheet(model) {
    const rows = [];
    model.components.forEach(e => {
        const relationships = e.relationships.filter(r => r.type === "input_from");
        rows.push(...relationships.map(relationship => {
            const sourceName = findElement(model.components, relationship.targetId)?.attributes
                .name || "";
            return {
                originId: relationship.targetId,
                originName: sourceName,
                destinationId: e.id,
                destinationName: e.attributes.name
            };
        }));
    });
    const ws = xlsx.json_to_sheet(rows);
    const headers = [
        "Origin ID",
        "Origin Name",
        "Destination ID",
        "Destination Name"
    ];
    xlsx.sheet_add_aoa(ws, [headers], { origin: "A1" });
    formatWorksheet(ws, [headers, ...rows.map(r => Object.values(r))]);
    return ws;
}
