import { SeedTypes } from "../schema";
import { createEmptyElement, generateElementId, getElementsArray } from "../schemautils";
import findElementInModel from "../search/findElementInModel";
import getTimeStamp from "../utils/getTimeStamp";
import sortElements from "../utils/sortElements";
/**
 * Create an element in the model.
 * @param model The model.
 * @param metatype The metatype of the element.
 * @param attributes The attributes of the element.
 * @param withId The ID of the element to create.
 * @returns A reference to the new element.
 */
export default function createElement(model, metatype, attributes, withId) {
    const hasSubtype = "type" in attributes && SeedTypes.safeParse(attributes.type).success;
    const type = (hasSubtype ? attributes.type : metatype);
    const id = withId || generateElementId(type, model.uidSeeds);
    if (findElementInModel(model, id)) {
        throw new Error(`Failed to create ${metatype.toLowerCase()}. An element with id ${id} already exists.`);
    }
    const element = createEmptyElement(id, metatype, attributes);
    element.changeLog.push({
        time: getTimeStamp(),
        operation: "create",
        description: `Created ${hasSubtype ? `${type.toLowerCase()} ` : ""}${metatype.toLowerCase()}: ${id}.`
    });
    const elementsArray = getElementsArray(model, metatype);
    elementsArray.push(element);
    sortElements(elementsArray);
    return element;
}
