import { useTheme } from "@mui/material/styles";
import React from "react";
import { Connector, Layout } from "../../../layouts";

export interface Props {
  layout: Layout;
  drawConnector: (connector: Connector) => React.ReactNode;
}

export const ConnectorsLayer = ({ layout, drawConnector }: Props) => {
  const theme = useTheme();

  const connectors = layout.connectors.map(connector => {
    return (
      <React.Fragment key={`${connector.sourceId}-${connector.targetId}`}>
        {drawConnector(connector)}
      </React.Fragment>
    );
  });

  return (
    <>
      <defs>
        <radialGradient
          id="gradientDefinition"
          cx="50%"
          cy="50%"
          r="50%"
          fx="50%"
          fy="50%"
        >
          <stop
            stopColor={theme.palette.connector.hover}
            offset="0%"
            stopOpacity="1"
          />
          <stop
            stopColor={theme.palette.connector.hover}
            offset="100%"
            stopOpacity="0"
          />
        </radialGradient>
      </defs>
      <g fill="none" stroke={theme.palette.connector.stroke}>
        {connectors}
      </g>
    </>
  );
};
