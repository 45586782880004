import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { alpha, darken, lighten, styled } from "@mui/material/styles";
import {
  DataGrid,
  DataGridProps,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridRowClassNameParams,
  GridSortModel,
  GridToolbar,
  gridClasses
} from "@mui/x-data-grid";
import React from "react";
import { useStopPropogation } from "../interactions";
import {
  useDynamicConfig,
  useStaticConfig
} from "../utils/ConfigContextProvider";
import NoDataTableOverlay from "./NoDataTableOverlay";

const StripedDataGrid = styled(DataGrid)(({ theme }) => {
  const hover = {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    "@media (hover: none)": {
      backgroundColor: "transparent"
    }
  };
  const selected = {
    backgroundColor: alpha(
      theme.palette.primary.main,
      0.2 + theme.palette.action.selectedOpacity
    )
  };
  return {
    [`& .${gridClasses.columnHeader}`]: {
      backgroundColor:
        theme.palette.mode === "dark"
          ? darken(theme.palette.primary.dark, 0.25)
          : lighten(theme.palette.primary.light, 0.25),
      color: theme.palette.common.white
    },
    [`& .${gridClasses.row}.odd`]: {
      backgroundColor:
        theme.palette.mode === "dark"
          ? darken(theme.palette.grey[900], 0.25)
          : theme.palette.grey[100],
      "&:hover, &.Mui-hovered": hover,
      "&.Mui-selected": selected
    },
    [`& .${gridClasses.row}.even`]: {
      "&:hover, &.Mui-hovered": hover,
      "&.Mui-selected": selected
    },
    [`& .${gridClasses.row}.highlight`]: {
      backgroundColor:
        theme.palette.mode === "dark"
          ? darken(theme.palette.info.dark, 0.25)
          : theme.palette.info.light,
      "&:hover, &.Mui-hovered": hover,
      "&.Mui-selected": selected
    }
  };
});

export interface Props extends DataGridProps {
  tableName: string;
  gridRef?: React.Ref<HTMLDivElement>;
  title: string;
  children?: JSX.Element | JSX.Element[];
}

export default function PaperTable({
  tableName,
  gridRef,
  title,
  children,
  ...dataGridProps
}: Props) {
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useDynamicConfig<GridColumnVisibilityModel>(
      `${tableName}.columnVisibilityModel`,
      dataGridProps.initialState?.columns?.columnVisibilityModel
    );

  const [paginationModel, setPaginationModel] =
    useDynamicConfig<GridPaginationModel>(`${tableName}.paginationModel`, {
      pageSize: 20,
      page: 0,
      ...dataGridProps.initialState?.pagination?.paginationModel
    });

  const [filterModel, setFilterModel] = useStaticConfig<GridFilterModel>(
    `${tableName}.filterModel`,
    {
      items: [],
      quickFilterValues: [],
      quickFilterExcludeHiddenColumns: true,
      quickFilterLogicOperator: GridLogicOperator.Or,
      ...dataGridProps.initialState?.filter?.filterModel
    }
  );
  const onFilterModelChange = React.useCallback(
    (filterModel: GridFilterModel) => {
      setFilterModel(filterModel);
      setPaginationModel({ ...paginationModel, page: 0 });
    },
    [paginationModel, setFilterModel, setPaginationModel]
  );
  const quickFilterParser = React.useCallback(
    (searchInput: string) => searchInput.split(",").map(value => value.trim()),
    []
  );
  const quickFilterFormatter = React.useCallback(
    (quickFilterValues: string[]) => quickFilterValues.join(", "),
    []
  );

  const [sortModel, setSortModel] = useDynamicConfig<GridSortModel>(
    `${tableName}.sortModel`,
    dataGridProps.initialState?.sorting?.sortModel
  );

  const getRowClassName = React.useCallback(
    (params: GridRowClassNameParams<any>): string => {
      if (params.row.isHighlighted) return "highlight";
      return params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd";
    },
    []
  );

  const stopPropagation = useStopPropogation();

  const getRowHeight = React.useCallback(() => "auto", []);

  return (
    <Paper
      ref={gridRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        padding: 1
      }}
      onKeyDown={stopPropagation}
      onKeyUp={stopPropagation}
    >
      <Stack direction="row" spacing={4}>
        <Typography variant="h5">{title}</Typography>
        {children}
      </Stack>
      <Box sx={{ position: "relative", height: "100%" }}>
        <Box
          sx={{
            bottom: 0,
            left: 0,
            position: "absolute",
            right: 0,
            top: 0
          }}
        >
          <StripedDataGrid
            sx={{
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "8px"
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "15px"
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "22px"
              },
              "& .MuiDataGrid-actionsCell .MuiIconButton-root": {
                padding: 0
              }
            }}
            density="compact"
            getRowClassName={getRowClassName}
            getRowHeight={getRowHeight}
            slots={{
              toolbar: GridToolbar,
              noRowsOverlay: () => <NoDataTableOverlay text="No rows." />,
              noResultsOverlay: () => (
                <NoDataTableOverlay text="No results found in visible columns." />
              )
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: {
                  quickFilterParser,
                  quickFilterFormatter,
                  debounceMs: 500
                },
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true }
              }
            }}
            disableDensitySelector={true}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={setColumnVisibilityModel}
            filterModel={filterModel}
            onFilterModelChange={onFilterModelChange}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[5, 10, 15, 20, 25]}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            {...dataGridProps}
          />
        </Box>
      </Box>
    </Paper>
  );
}
