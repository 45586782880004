import findElementInModel from "../search/findElementInModel";
import getTimeStamp from "../utils/getTimeStamp";
/**
 * Delete a relationship between elements of the model.
 * @param model The model.
 * @param relationship The relationship to delete.
 * @returns true if the model was modified or false if not.
 */
export default function deleteRelationship(model, relationship) {
    const { sourceId, targetId, type } = relationship;
    const sourceElement = findElementInModel(model, sourceId);
    if (!sourceElement) {
        throw new Error(`Failed to delete relationship. The source element, ${sourceId}, does not exist.`);
    }
    // check whether the relationship exists
    const index = sourceElement.relationships.findIndex(r => {
        return r.targetId === targetId && r.type === type;
    });
    if (index < 0)
        return false;
    sourceElement.relationships.splice(index, 1);
    const targetElement = findElementInModel(model, targetId);
    const targetName = targetElement && "name" in targetElement.attributes
        ? ` (${targetElement.attributes.name})`
        : "";
    sourceElement.changeLog.push({
        time: getTimeStamp(),
        operation: "delete",
        description: `Deleted ${type} relationship with ${targetId}${targetName}.`
    });
    return true;
}
