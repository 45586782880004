import React from "react";
import getPointerPosition from "./getPointerPosition";
import { Position } from "./Position";
import useElementEventListener from "./useElementEventListener";
import useWindowEventListener from "./useWindowEventListener";

export interface Args<T extends HTMLElement | SVGElement> {
  eventListenerRef: React.RefObject<T>;
  startCallback?: () => void;
  pinchCallback?: (deltaPosition: Position) => void;
}

/**
 * This custom hook enables the use of a pointer pinch gesture (2x pointerdown -> pointermove -> pointerup).
 * @param eventListenerRef to the HTML element to which to attach the event handlers.
 * @param startCallback to call when the second pointerdown event fires.
 * @param pinchCallback to call when the pointermove event fires.
 * The move callback is passed the change in distance between the two pointers since the pointerdown event.
 */
export default function usePointerPinch<T extends HTMLElement | SVGElement>({
  eventListenerRef,
  startCallback = () => {},
  pinchCallback = () => {}
}: Args<T>): void {
  const isEnabled = React.useRef(false);
  const pointerIds = React.useRef<number[]>([]);
  const startPositions = React.useRef<Position[]>([]);
  const currentPositions = React.useRef<Position[]>([]);
  const startDistance = React.useRef({ x: 0, y: 0 });

  useElementEventListener(
    eventListenerRef,
    "pointerdown",
    React.useCallback(
      (e: PointerEvent) => {
        e.preventDefault();
        pointerIds.current.push(e.pointerId);
        isEnabled.current = pointerIds.current.length === 2;
        const p = getPointerPosition(e);
        startPositions.current.push(p);
        currentPositions.current.push(p);
        if (isEnabled.current) {
          startDistance.current = {
            x: Math.abs(
              startPositions.current[1].x - startPositions.current[0].x
            ),
            y: Math.abs(
              startPositions.current[1].y - startPositions.current[0].y
            )
          };
          startCallback();
        }
      },
      [startCallback]
    )
  );

  useElementEventListener(
    eventListenerRef,
    "pointermove",
    React.useCallback(
      (e: PointerEvent) => {
        e.preventDefault();
        if (isEnabled.current) {
          if (e.pointerId === pointerIds.current[0]) {
            currentPositions.current[0] = getPointerPosition(e);
          } else if (e.pointerId === pointerIds.current[1]) {
            currentPositions.current[1] = getPointerPosition(e);
          }
          const currentDistance = {
            x: Math.abs(
              currentPositions.current[1].x - currentPositions.current[0].x
            ),
            y: Math.abs(
              currentPositions.current[1].y - currentPositions.current[0].y
            )
          };
          pinchCallback({
            x: currentDistance.x - startDistance.current.x,
            y: currentDistance.y - startDistance.current.y
          });
        }
      },
      [pinchCallback]
    )
  );

  const endGesture = React.useCallback((e: PointerEvent) => {
    e.preventDefault();
    isEnabled.current = false;
    pointerIds.current = [];
    startPositions.current = [];
    currentPositions.current = [];
  }, []);

  useWindowEventListener("pointerup", endGesture);
  useWindowEventListener("pointercancel", endGesture);
  useWindowEventListener("pointerout", endGesture);
  useWindowEventListener("pointerleave", endGesture);
}
