import diffAttributes from "../comparators/diffAttributes";
import generateTextChangeDescription from "../comparators/generateTextChangeDescription";
import { SeedTypes } from "../schema";
import { generateElementId, getAllElements, getElementsArray, parseElementAttributes } from "../schemautils";
import findElement from "../search/findElement";
import formatDate from "../utils/formatDate";
import formatVariableName from "../utils/formatVariableName";
import getTimeStamp from "../utils/getTimeStamp";
import sortElements from "../utils/sortElements";
import sortRelationships from "../utils/sortRelationships";
/**
 * Update an element of the model.
 * @param model The model.
 * @param id The id of the element to be updated.
 * @param attributes The attributes to be updated.
 * @returns true if updated or false if not.
 */
export default function updateElement(model, id, attributes) {
    if (!Object.keys(attributes).length) {
        return false;
    }
    const elements = getAllElements(model);
    const element = findElement(elements, id);
    if (!element) {
        throw new Error(`Failed to update element. An element with id ${id} does not exist.`);
    }
    const currentAttributes = element.attributes;
    const _attributes = {
        ...currentAttributes,
        ...parseElementAttributes(element.metatype, attributes, "partial")
    };
    const attributeDiff = diffAttributes(currentAttributes, _attributes);
    const changeDescriptions = [];
    Object.keys(attributeDiff).forEach(key => {
        const oldValue = currentAttributes[key];
        const newValue = attributeDiff[key];
        if (newValue === oldValue)
            return;
        switch (key) {
            case "closedOn":
                const type = currentAttributes.type.toLowerCase();
                if (newValue && !oldValue) {
                    changeDescriptions.push(`Closed ${type} annotation: ${element.id}.`);
                }
                else if (!newValue && oldValue) {
                    changeDescriptions.push(`Re-opened ${type} annotation: ${element.id}.`);
                }
                else if (oldValue && newValue && newValue !== oldValue) {
                    changeDescriptions.push(`Updated closed on date from ${formatDate(oldValue)} to ${formatDate(newValue)}.`);
                }
                break;
            // Other text attributes
            case "description":
            case "remarks":
            case "text":
                const changeDescription = generateTextChangeDescription(key, oldValue, newValue);
                if (changeDescription) {
                    changeDescriptions.push(changeDescription);
                }
                break;
            case "name":
                changeDescriptions.push(`Updated ${key} from "${oldValue}" to "${newValue}".`);
                break;
            case "type":
                if (!SeedTypes.safeParse(attributes.type).success) {
                    // The type does not determine the UID and therefore the ID does not need to be updated.
                    changeDescriptions.push(`Updated type from ${oldValue} to ${newValue}.`);
                    break;
                }
                const newId = generateElementId(newValue, model.uidSeeds);
                changeDescriptions.push(`Updated type from ${oldValue} (ID: ${element.id}) to ${newValue} (ID: ${newId}).`);
                /*
                 * Update the ID manually.
                 * The checks in updateElementId are not needed here.
                 * The change description is also different from that generated by updateElementId.
                 */
                element.id = newId;
                sortElements(getElementsArray(model, element.metatype));
                elements.forEach(e => {
                    e.relationships.forEach(relationship => {
                        if (relationship.targetId === id) {
                            relationship.targetId = newId;
                        }
                    });
                    sortRelationships(e.relationships);
                });
                break;
            default:
                changeDescriptions.push(`Updated ${formatVariableName(key, false)} from ${oldValue} to ${newValue}.`);
        }
        currentAttributes[key] = newValue;
    });
    changeDescriptions.forEach(description => {
        if (!description)
            return;
        element.changeLog.push({
            time: getTimeStamp(),
            operation: "update",
            description
        });
    });
    return !!changeDescriptions.length;
}
