import { HierarchyNode } from "@se-toolkit/model-js/analysis";
import { findElement } from "@se-toolkit/model-js/search";
import { Connector, LayoutProperties, Orientation, Shape } from "../Layout";

export const createConnectors = (
  shapes: Shape[],
  hierarchy: HierarchyNode[],
  layoutProperties: LayoutProperties
): Connector[] => {
  const xOffset =
    layoutProperties.layoutOrientation === Orientation.horizontal
      ? layoutProperties.nodeWidth / 2
      : 0;

  const yOffset =
    layoutProperties.layoutOrientation === Orientation.vertical
      ? layoutProperties.nodeHeight / 2
      : 0;

  const connectors: Connector[] = [];
  shapes.forEach(shape => {
    const element = findElement(hierarchy, shape.id);
    if (!element) return;

    element.relationships.forEach(relationship => {
      const childShape = findElement(shapes, relationship.targetId);
      if (!childShape) return;
      connectors.push({
        sourceId: shape.id,
        targetId: childShape.id,
        p1: {
          x: shape.position.x + xOffset,
          y: shape.position.y + yOffset
        },
        p2: {
          x: childShape.position.x - xOffset,
          y: childShape.position.y - yOffset
        }
      });
    });
  });
  return connectors;
};
