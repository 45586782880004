import { validateModel } from "../validators";
/**
 * Import the model from a JSON string
 * @param input The string or ArrayBuffer to be imported.
 * @returns The model if the input was successfully loaded, converted and
 * validated; otherwise null.
 */
export default function importModelFromJsonString(input) {
    if (input === "") {
        return null;
    }
    const model = loadJSON(input);
    validateModel(model);
    return model;
}
const loadJSON = (input) => {
    if (typeof input !== "string") {
        throw new Error(`Cannot load file. The format is not json.`);
    }
    try {
        return JSON.parse(input);
    }
    catch {
        throw new Error("Cannot parse file. The contents is not valid json.");
    }
};
