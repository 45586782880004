import React from "react";
import { findElementAtPosition, Layout } from "../../layouts";
import findConnectorAtPosition from "../../layouts/utils/findConnectorAtPosition";
import getPositionInSvgCoordinates from "../diagrams/utils/getPositionInSvgCoordinates";
import getEventTargetData from "./getEventTargetData";
import getPointerPosition from "./getPointerPosition";

export interface Args {
  diagramSvgRef: React.RefObject<SVGSVGElement>;
  hierarchyLayout: Layout;
  linkSourceToTarget: (sourceId: string, targetId: string) => void;
  moveSourceToTarget?: (sourceId: string, targetId: string) => void;
  insertSourceAtConnector?: (
    id: string,
    connector: { sourceId: string; targetId: string },
    mode: string
  ) => void;
}

export default function useLinkSourceToTarget({
  diagramSvgRef,
  hierarchyLayout,
  linkSourceToTarget,
  moveSourceToTarget = () => {},
  insertSourceAtConnector = () => {}
}: Args) {
  const linkSourceId = React.useRef<string>("");
  const [isLinkActive, setIsLinkActive] = React.useState(false);

  const onLinkStart = React.useCallback((e: PointerEvent) => {
    linkSourceId.current = getEventTargetData(e.target, "id") || "";
    setIsLinkActive(true);
  }, []);

  const onLinkFinish = React.useCallback(
    (e: PointerEvent) => {
      setIsLinkActive(false);
      if (!linkSourceId.current || !diagramSvgRef.current) {
        return;
      }
      const pointInDiagram = getPointerPosition(e, diagramSvgRef.current);
      const positionInDiagram = getPositionInSvgCoordinates(
        diagramSvgRef.current,
        pointInDiagram
      );
      const targetElementId = findElementAtPosition(
        hierarchyLayout,
        positionInDiagram
      );
      if (targetElementId && targetElementId !== linkSourceId.current) {
        if (e.altKey && moveSourceToTarget) {
          moveSourceToTarget(linkSourceId.current, targetElementId);
          return;
        }
        linkSourceToTarget(linkSourceId.current, targetElementId);
        return;
      }
      const targetConnector = findConnectorAtPosition(
        hierarchyLayout,
        positionInDiagram
      );
      if (
        targetConnector &&
        targetConnector.sourceId !== linkSourceId.current &&
        targetConnector.targetId !== linkSourceId.current
      ) {
        insertSourceAtConnector(
          linkSourceId.current,
          targetConnector,
          e.altKey ? "alt" : "default"
        );
      }
    },
    [
      diagramSvgRef,
      hierarchyLayout,
      insertSourceAtConnector,
      linkSourceToTarget,
      moveSourceToTarget
    ]
  );

  const onLinkCancel = React.useCallback(() => setIsLinkActive(false), []);

  return { isLinkActive, onLinkStart, onLinkFinish, onLinkCancel };
}
