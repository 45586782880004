/**
 * Find an element in an elements array using a binary search.
 * @param elements An array of alphabetically sorted elements.
 * @param id The id of the element to find.
 * @returns A reference to the element or null if it doesn't exist.
 */
export default function findElement(elements, id) {
    if (elements.length === 0) {
        return null;
    }
    if (elements.length === 1) {
        return elements[0].id === id ? elements[0] : null;
    }
    const binarySearch = (startIndex, endIndex) => {
        const middle = Math.floor((startIndex + endIndex) / 2);
        if (elements[middle].id === id) {
            return elements[middle];
        }
        if (startIndex === endIndex - 1) {
            const endElement = elements[elements.length - 1];
            return endElement && id === endElement.id ? endElement : null;
        }
        const comparison = id.localeCompare(elements[middle].id, undefined, {
            numeric: true
        });
        if (comparison < 0) {
            return binarySearch(startIndex, middle);
        }
        if (comparison > 0) {
            return binarySearch(middle, endIndex);
        }
        return null;
    };
    return binarySearch(0, elements.length - 1);
}
