import { HierarchyNode } from "@se-toolkit/model-js/analysis";
import React from "react";
import {
  createHierarchyLayout,
  Layout,
  LayoutProperties,
  mirrorLayoutHorizontally
} from "../../../layouts";

export interface Args {
  hierarchy: HierarchyNode[];
  layoutProperties: LayoutProperties;
  mirrorHorizontally?: boolean;
}

export default function useHierarchyLayout({
  hierarchy,
  layoutProperties,
  mirrorHorizontally = false
}: Args): Layout {
  const layout = React.useMemo(() => {
    const hierarchyLayout = createHierarchyLayout(hierarchy, layoutProperties);
    if (mirrorHorizontally) {
      mirrorLayoutHorizontally(hierarchyLayout);
    }
    return hierarchyLayout;
  }, [hierarchy, layoutProperties, mirrorHorizontally]);

  return layout;
}
