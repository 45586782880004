import { z } from "zod";
import { XlsxWorksheetType } from "./XlsxWorksheetType";
export const SheetSelection = z.array(z.tuple([z.string(), XlsxWorksheetType]));
export const createDefaultSheetSelection = () => [
    ["Actions", "Action"],
    ["Requirements", "Requirements"],
    ["Structure", "Structure"],
    ["Interfaces", "Interfaces"],
    ["Roadmap", "Roadmap"],
    ["Assumptions", "Assumption"],
    ["Opportunities", "Opportunity"],
    ["Risks", "Risk"],
    ["Lessons", "Lesson"]
];
