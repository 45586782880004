import Typography from "@mui/material/Typography";
import { GridColDef, GridRowId, GridRowsProp } from "@mui/x-data-grid";
import { Change } from "@se-toolkit/model-js/schema";
import { getAllElements } from "@se-toolkit/model-js/schemautils";
import React from "react";
import { useSelector } from "react-redux";
import {
  selectLastSaved,
  selectModel,
  selectModificationCounter
} from "../../../features";
import PaperTable from "../../components/PaperTable";
import PurgeChangeLogsButton from "../controls/PurgeChangeLogsButton";
import useDateTimeFormatter from "../gridutils/useDateTimeFormatter";

interface ChangeRow extends Change {
  id: GridRowId;
  elementId: string;
  elementName: string;
  isHighlighted?: boolean;
}

export default function ChangeLogTable() {
  const model = useSelector(selectModel);
  const lastSaved: number = useSelector(selectLastSaved);
  const modificationCounter: number = useSelector(selectModificationCounter);

  const rows: GridRowsProp = React.useMemo(() => {
    const changes: ChangeRow[] = [];
    let id = 0;
    model.changeLog.forEach((c: Change) => {
      changes.push({
        ...c,
        id,
        elementId: "",
        elementName: "",
        isHighlighted: c.time >= lastSaved
      });
      id++;
    });
    getAllElements(model).forEach(e => {
      e.changeLog.forEach(c => {
        changes.push({
          ...c,
          id,
          elementId: e.id,
          elementName: "name" in e.attributes ? e.attributes.name : "",
          isHighlighted: c.time >= lastSaved
        });
        id++;
      });
    });
    return changes;
  }, [lastSaved, model]);

  const valueFormatterDateTime = useDateTimeFormatter();

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "time",
        type: "dateTime",
        headerName: "Time",
        width: 150,
        valueFormatter: valueFormatterDateTime
      },
      {
        field: "elementId",
        headerName: "Element ID"
      },
      {
        field: "elementName",
        headerName: "Element Name",
        width: 250
      },
      {
        field: "description",
        headerName: "Description",
        flex: 1
      }
    ],
    [valueFormatterDateTime]
  );

  return (
    <PaperTable
      tableName="Dashboard.ChangeLog"
      title="Change Log"
      rows={rows}
      columns={columns}
      initialState={{
        sorting: {
          sortModel: [{ field: "time", sort: "desc" }]
        }
      }}
    >
      <PurgeChangeLogsButton />
      <Typography
        sx={{ alignSelf: "center", marginLeft: 5 }}
        variant="body1"
        color="textSecondary"
        hidden={modificationCounter === 0}
      >
        Unsaved changes are highlighted in the table below.
      </Typography>
    </PaperTable>
  );
}
