import { findElement, findElementParents } from "@se-toolkit/model-js/search";
import React from "react";
import { useSelector } from "react-redux";
import { makeSelectModelElements } from "../../../features";
import { Shape } from "../../../layouts";
import { SETColours } from "../../../theme";
import {
  HierarchyElement,
  LabelProps
} from "../../diagrams/shapes/HierarchyElement";
import { Anchor } from "../../interactions/useAnchor";

export type LabelMode = "ElementType" | "Stakeholders";

export interface Args {
  selectedIds?: string[];
  annotationsAnchor?: Anchor;
  descriptionAnchor?: Anchor;
  specificationAnchor?: Anchor;
  labelMode?: LabelMode;
}

export default function useDrawComponent({
  selectedIds = [],
  annotationsAnchor,
  descriptionAnchor,
  specificationAnchor,
  labelMode
}: Args) {
  const annotations = useSelector(makeSelectModelElements("Annotation"));
  const components = useSelector(makeSelectModelElements("Component"));
  const stakeholders = useSelector(makeSelectModelElements("Stakeholder"));

  return React.useCallback(
    (shape: Shape) => {
      const component = findElement(components, shape.id);
      if (!component) {
        console.warn("Component not found", shape.id);
        return null;
      }

      const labelProps: LabelProps = {
        metatype: "Component",
        id: component.id,
        text: component.attributes.type,
        colour: SETColours.componentType[component.attributes.type!]
      };

      if (labelMode === "Stakeholders") {
        const stakeholder = findElementParents(
          stakeholders,
          shape.id,
          "responsible_for"
        )[0];

        labelProps.metatype = "Stakeholder";
        labelProps.id = stakeholder?.id;
        labelProps.text = stakeholder?.attributes.name || "";
        labelProps.colour = stakeholder?.attributes.colour || undefined;
      }

      const hasAnnotations: boolean = !!findElementParents(
        annotations,
        component.id,
        "annotates"
      ).length;
      const hasDescription: boolean = !!component.attributes.description;
      const hasSpecification: boolean = !!component.relationships.find(
        r => r.type === "specified_by"
      );

      return (
        <HierarchyElement
          shape={shape}
          text={component.attributes.name}
          labelProps={labelProps}
          hasAnnotations={hasAnnotations}
          hasDescription={hasDescription}
          hasSpecification={hasSpecification}
          isSelected={selectedIds.includes(shape.id)}
          areAnnotationsFixed={annotationsAnchor?.isFixed}
          isDescriptionFixed={descriptionAnchor?.isFixed}
          isSpecificationFixed={specificationAnchor?.isFixed}
          setAnnotationsAnchor={annotationsAnchor?.set}
          setDescriptionAnchor={descriptionAnchor?.set}
          setSpecificationAnchor={specificationAnchor?.set}
        />
      );
    },
    [
      components,
      labelMode,
      annotations,
      selectedIds,
      annotationsAnchor?.isFixed,
      annotationsAnchor?.set,
      descriptionAnchor?.isFixed,
      descriptionAnchor?.set,
      specificationAnchor?.isFixed,
      specificationAnchor?.set,
      stakeholders
    ]
  );
}
