import { z } from "zod";
import { Change } from "./Change";
import { RelationshipTarget } from "./Relationship";
export const ElementMetatype = z.enum([
    "Annotation",
    "Component",
    "Milestone",
    "Requirement",
    "Stakeholder"
]);
export const ElementAttributes = z.object({});
export const Element = z.object({
    id: z.string().min(1, { message: "An element may not have an empty ID." }),
    metatype: ElementMetatype,
    changeLog: z.array(Change),
    relationships: z.array(RelationshipTarget),
    attributes: ElementAttributes
});
