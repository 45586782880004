import findElement from "./findElement";
/**
 * Find an element in the model using a binary search.
 * @param model The model.
 * @param id The id of the element to find.
 * @returns A reference to the element or null if it doesn't exist.
 */
export default function findElementInModel(model, id) {
    return (findElement(model.annotations, id) ||
        findElement(model.components, id) ||
        findElement(model.milestones, id) ||
        findElement(model.requirements, id) ||
        findElement(model.stakeholders, id) ||
        null);
}
