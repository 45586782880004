import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { red } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import React from "react";
import useAnchor from "../interactions/useAnchor";
import useContextMenu from "../interactions/useContextMenu";

export interface Props {
  eventListenerRef: React.RefObject<HTMLDivElement | SVGSVGElement>;
  mode?: "singular" | "plural";
  setRootId?: ((value: string) => void) | null;
}

export default function ContextMenu({
  eventListenerRef,
  mode = "singular",
  setRootId
}: Props) {
  const theme = useTheme();

  const plural = mode === "plural" ? "s" : "";

  const anchor = useAnchor(eventListenerRef);
  useContextMenu(eventListenerRef, anchor);

  const handleUpdateElements = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      anchor.set(null, false);
      window.dispatchEvent(new CustomEvent("updateelements"));
    },
    [anchor]
  );

  const handleDeleteElements = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      anchor.set(null, false);
      window.dispatchEvent(new CustomEvent("deleteelements"));
    },
    [anchor]
  );

  const handleSetRootId = React.useCallback(
    (e: React.MouseEvent) => {
      if (!setRootId) return;
      e.preventDefault();
      window.dispatchEvent(new CustomEvent("selectnone"));
      anchor.set(null, false);
      setRootId(anchor.id);
    },
    [anchor, setRootId]
  );

  return (
    <Popper
      open={Boolean(anchor.element)}
      anchorEl={anchor.element}
      placement="right"
    >
      <Paper
        sx={{
          backgroundColor: theme.palette.background.popover,
          display: "flex",
          flexDirection: "column",
          padding: theme.spacing(1)
        }}
      >
        <Button
          sx={{ fontWeight: "normal", textTransform: "none" }}
          color="inherit"
          startIcon={<DeleteIcon sx={{ color: red[400] }} />}
          onClick={handleDeleteElements}
        >
          <Typography>{`Delete element${plural}`}</Typography>
        </Button>
        <Button
          sx={{ fontWeight: "normal", textTransform: "none" }}
          color="inherit"
          startIcon={<EditIcon />}
          onClick={handleUpdateElements}
        >
          <Typography>{`Update element${plural}`}</Typography>
        </Button>
        {setRootId ? (
          <Button
            sx={{ fontWeight: "normal", textTransform: "none" }}
            color="inherit"
            startIcon={<CenterFocusStrongIcon />}
            onClick={handleSetRootId}
          >
            <Typography>Set as root element</Typography>
          </Button>
        ) : null}
      </Paper>
    </Popper>
  );
}
