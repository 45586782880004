import { HierarchyNode } from "@se-toolkit/model-js/analysis";
import { RelationshipType } from "@se-toolkit/model-js/schema";
import { findElement } from "@se-toolkit/model-js/search";
import React from "react";
import { insertRelationship, useAppDispatch } from "../../features";

export default function useInsertRelationship(
  hierarchy: HierarchyNode[],
  type: RelationshipType
) {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (
      id: string,
      connector: { sourceId: string; targetId: string },
      mode: string
    ) => {
      let node: HierarchyNode | null = null;
      if (mode === "alt") {
        node = findElement(hierarchy, id);
      }
      dispatch(
        insertRelationship({
          id,
          relationship: {
            sourceId: connector.sourceId,
            targetId: connector.targetId,
            type
          },
          oldSourceIds: node?.parentIds || []
        })
      );
    },
    [dispatch, hierarchy, type]
  );
}
