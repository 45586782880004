import React from "react";
import getEventTargetData from "./getEventTargetData";
import { Position } from "./Position";
import usePointerMove from "./usePointerMove";

export default function usePan(
  svgRef: React.RefObject<SVGSVGElement>,
  options?: {
    disableX?: boolean;
    disableY?: boolean;
    multiplier?: number;
  }
) {
  const x = React.useRef(0);
  const y = React.useRef(0);

  const disableX = options?.disableX || false;
  const disableY = options?.disableY || false;
  const multiplier = options?.multiplier || 1.0;

  const enableGesture = React.useCallback((e: PointerEvent) => {
    return !Boolean(getEventTargetData(e.target, "id"));
  }, []);

  const startCallback = React.useCallback(
    (position: Position, e: PointerEvent) => {
      x.current = svgRef.current?.viewBox.baseVal.x || 0;
      y.current = svgRef.current?.viewBox.baseVal.y || 0;
    },
    [svgRef]
  );

  const moveCallback = React.useCallback(
    ({ x: dx, y: dy }: Position) => {
      if (!svgRef.current) {
        return;
      }

      const scale =
        svgRef.current.viewBox.baseVal.width /
        svgRef.current.width.baseVal.value;

      const vb = [
        disableX ? x.current : x.current - dx * scale * multiplier,
        disableY ? y.current : y.current - dy * scale * multiplier,
        svgRef.current.viewBox.baseVal.width || 0,
        svgRef.current.viewBox.baseVal.height || 0
      ].toString();

      svgRef.current.setAttribute("viewBox", vb);
    },
    [disableX, disableY, multiplier, svgRef]
  );

  usePointerMove({
    eventListenerRef: svgRef,
    enableGesture,
    startCallback,
    moveCallback
  });
}
