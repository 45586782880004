import Chip from "@mui/material/Chip";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Stakeholder } from "@se-toolkit/model-js/schema";
import { findElement } from "@se-toolkit/model-js/search";
import React from "react";

export default function useRenderStakeholder(stakeholders: Stakeholder[]) {
  return React.useCallback(
    (params: GridRenderCellParams<any, string>) => {
      const stakeholder = params.value
        ? findElement(stakeholders, params.value)
        : null;
      if (!stakeholder)
        return (
          <span
            style={{ height: "100%", width: "100%" }}
            data-id={params.row.id}
            data-labelmetatype={"Stakeholder"}
          />
        );
      return (
        <Chip
          sx={{
            backgroundColor: stakeholder.attributes.colour,
            height: 20,
            color: "white"
          }}
          label={
            <span
              data-id={params.row.id}
              data-labelmetatype={"Stakeholder"}
              data-labelid={stakeholder.id}
            >
              {stakeholder.attributes.name}
            </span>
          }
          size="small"
        />
      );
    },
    [stakeholders]
  );
}
