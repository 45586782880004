import { findElement } from "../search";
/**
 * Find the descendants of a node in a hierarchy
 * @param hierarchy An array of hierarchy nodes in alphabetical order.
 * @param id The id of the element for which to find the descendants.
 * @returns An array of hierarchy nodes in depth first order.
 */
export default function findDescendants(hierarchy, id) {
    const result = [];
    if (!hierarchy.length || !id)
        return result;
    const recursor = (elementId) => {
        const e = findElement(hierarchy, elementId);
        if (!e)
            return;
        e.relationships.forEach(r => recursor(r.targetId));
        result.push(e);
    };
    recursor(id);
    result.pop();
    return result;
}
