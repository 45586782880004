import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { ComponentType } from "@se-toolkit/model-js/schema";
import React from "react";
import { updateElement, useAppDispatch } from "../../features";
import { SETColours } from "../../theme";
import getEventTargetData from "../interactions/getEventTargetData";
import useAnchor from "../interactions/useAnchor";
import useElementEventListener from "../interactions/useElementEventListener";

export interface Props {
  eventListenerRef: React.RefObject<HTMLDivElement | SVGSVGElement>;
}

export default function ComponentTypeMenu({ eventListenerRef }: Props) {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const componentId = React.useRef("");
  const componentType = React.useRef("");

  const anchor = useAnchor(eventListenerRef);

  const handleClickLabel = React.useCallback(
    (e: PointerEvent) => {
      e.preventDefault();
      const id = getEventTargetData(e.target, "id");
      const metatype = getEventTargetData(e.target, "labelmetatype");
      const type = getEventTargetData(e.target, "labeltext");
      if (id && metatype === "Component" && type) {
        anchor.set(e.target as SVGRectElement, true);
        componentId.current = id;
        componentType.current = type;
        return;
      }
      anchor.set(null, false);
      componentId.current = "";
      componentType.current = "";
    },
    [anchor]
  );

  useElementEventListener(eventListenerRef, "click", handleClickLabel);

  const handleClickOption = React.useCallback(
    (option: ComponentType) => (e: React.MouseEvent) => {
      e.preventDefault();
      anchor.set(null, false);
      if (componentId.current && option !== componentType.current) {
        dispatch(
          updateElement({
            id: componentId.current,
            attributes: { type: option }
          })
        );
      }
    },
    [anchor, dispatch]
  );

  const buttons = React.useMemo(() => {
    const validElementTypes = ComponentType.options.filter(
      o => o !== componentType.current
    );
    return validElementTypes.map(type => {
      const isValidColour =
        typeof type === "string" && type in SETColours.componentType;
      const backgroundColor = isValidColour
        ? SETColours.componentType[type]
        : undefined;
      return (
        <Button
          key={type}
          sx={{
            backgroundColor,
            fontWeight: "normal",
            textTransform: "none"
          }}
          onClick={handleClickOption(type)}
        >
          <Typography color={theme.palette.common.white}>{type}</Typography>
        </Button>
      );
    });
  }, [handleClickOption, theme.palette.common.white]);

  return (
    <Popper
      open={Boolean(anchor.element)}
      anchorEl={anchor.element}
      placement="right"
    >
      <Paper
        sx={{
          backgroundColor: theme.palette.background.popover,
          display: "flex",
          flexDirection: "column",
          padding: theme.spacing(1)
        }}
      >
        {buttons}
      </Paper>
    </Popper>
  );
}
