import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { findElement } from "@se-toolkit/model-js/search";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Link as RouterLink, Routes } from "react-router-dom";
import { selectModel } from "../../../features";
import RequirementDialog from "../../composition/RequirementDialog";
import useDeleteElements from "../../featurehooks/useDeleteElements";
import { useViewKeyboardShortcuts } from "../../interactions";
import { useDynamicConfig } from "../../utils/ConfigContextProvider";
import useSubTabRouting, { TabRoute } from "../utils/useSubTabRouting";
import AllocationTables from "./AllocationTables";
import RequirementsOverview from "./RequirementsOverview";
import RequirementsTable from "./RequirementsTable";

export default function RequirementsView() {
  const model = useSelector(selectModel);

  const [selectedIds, setSelectedIds] = useDynamicConfig<string[]>(
    `Requirements`,
    []
  );
  const selectedRequirement = React.useMemo(() => {
    if (!selectedIds.length) return undefined;
    const requirement = findElement(model.requirements, selectedIds[0]);
    return requirement || undefined;
  }, [model.requirements, selectedIds]);

  useDeleteElements(selectedIds);

  useViewKeyboardShortcuts();

  const tabRoutes: TabRoute[] = [
    {
      label: "Overview",
      path: "/overview",
      element: <RequirementsOverview />
    },
    {
      label: "Requirements",
      path: "/requirements",
      element: (
        <RequirementsTable
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
        />
      )
    },
    {
      label: "Allocation",
      path: "/allocation",
      element: (
        <AllocationTables
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
        />
      )
    }
  ];

  const defaultTab = tabRoutes[0].path;
  const { basePath, currentTab, indexPath, setLastActiveTab } =
    useSubTabRouting("requirements", tabRoutes, defaultTab);

  const tabs = Object.values(tabRoutes).map(({ label, path }) => (
    <Tab
      key={path}
      label={label}
      component={RouterLink}
      to={`${basePath}${path}`}
      value={path}
      onClick={() => setLastActiveTab(path)}
    />
  ));

  const routes = Object.values(tabRoutes).map(({ path, element }) => (
    <Route key={path} path={`${path}/*`} element={element} />
  ));

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%"
      }}
    >
      <Tabs
        sx={{ borderRight: 1, borderColor: "divider" }}
        value={currentTab}
        orientation="vertical"
        indicatorColor="secondary"
        textColor="inherit"
      >
        {tabs}
      </Tabs>
      <Routes>
        <Route index={true} element={<Navigate to={indexPath} />} />
        {routes}
      </Routes>
      <RequirementDialog selectedRequirement={selectedRequirement} />
    </Box>
  );
}
