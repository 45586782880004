import React from "react";

export interface CustomEventMap {
  changeview: CustomEvent;
  createelements: CustomEvent;
  deleteelements: CustomEvent;
  importjson: CustomEvent;
  savemodel: CustomEvent;
  selectall: CustomEvent;
  selectnone: CustomEvent;
  setdepth: CustomEvent;
  setparentasroot: CustomEvent;
  setthememode: CustomEvent;
  updateelements: CustomEvent;
}

interface WindowEventMap
  extends HTMLElementEventMap,
    WindowEventHandlersEventMap,
    CustomEventMap {}

export default function useWindowEventListener<K extends keyof WindowEventMap>(
  type: K,
  callback: (e: WindowEventMap[K]) => void
) {
  React.useEffect(() => {
    window.addEventListener(type, callback);
    return () => {
      window.removeEventListener(type, callback);
    };
  });
}
