/**
 * Sort the elements alphabetically.
 * @param elements The array of elements to be sorted.
 * @returns The original elements array sorted alphabetically.
 */
export default function sortElements(elements) {
    elements.sort((a, b) => {
        return a.id.localeCompare(b.id, undefined, { numeric: true });
    });
}
