import { RelationshipType } from "@se-toolkit/model-js/schema";
import React from "react";
import { toggleRelationship, useAppDispatch } from "../../features";

export default function useToggleRelationship(
  type: RelationshipType,
  direction: "toTarget" | "toSource" = "toTarget"
) {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (sourceId: string, targetId: string) => {
      dispatch(
        toggleRelationship({
          sourceId: direction === "toSource" ? targetId : sourceId,
          targetId: direction === "toSource" ? sourceId : targetId,
          type
        })
      );
    },
    [direction, dispatch, type]
  );
}
