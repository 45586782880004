import Help from "@mui/icons-material/Help";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Release } from "../App";
import { fetchModel, useAppDispatch } from "../features";
import ModificationCounter from "./ModificationCounter";
import Notifier from "./Notifier";
import SettingsButton from "./SettingsButton";
import TabBar from "./TabBar";
import Welcome from "./Welcome";
import CreateButton from "./composition/CreateButton";
import DeleteButton from "./composition/DeleteButton";
import ModelName from "./composition/ModelName";
import NewModelButton from "./composition/NewModelButton";
import UndoRedoButtons from "./composition/UndoRedoButtons";
import UpdateButton from "./composition/UpdateButton";
import FileDownloadButton from "./inputoutput/FileDownloadButton";
import FileDropHandler from "./inputoutput/FileDropHandler";
import FileInputButton from "./inputoutput/FileInputButton";
import useFileDropHandler from "./inputoutput/useFileDropHandler";
import { useAppKeyboardShortcuts } from "./interactions";
import logo from "./logo.png";

export interface Props {
  version?: string;
  release?: Release;
}

export default function AppNavBar({ version, release }: Props) {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  // Load the model from the URL if provided
  const urlParams = new URLSearchParams(window.location.search);
  const modelURL = urlParams.get("modelURL");
  if (modelURL) {
    dispatch(fetchModel(modelURL));
  }

  useAppKeyboardShortcuts();

  const { onDragOver, onDrop } = useFileDropHandler();

  const chip = React.useMemo(() => {
    if (!version || !release || typeof release === "number") return null;
    return (
      <Chip
        sx={{
          backgroundColor: release === "beta" ? "orange" : "red",
          color: "white",
          borderRadius: 4,
          marginLeft: 1
        }}
        label={`${version}.${release}`}
        size="small"
      />
    );
  }, [release, version]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(var(--vh, 1vh) * 100)"
      }}
    >
      <Notifier />
      <AppBar position="static">
        <Toolbar sx={{ backgroundColor: "primary.dark" }}>
          <IconButton
            href="https://se-toolkit.app"
            rel="noopener"
            target="_blank"
            sx={{
              marginLeft: -1.5,
              marginRight: 1.5
            }}
            aria-label="se-toolkit.app"
            size="large"
          >
            <img src={logo} alt="SE-Toolkit icon" />
          </IconButton>
          <Box
            sx={{
              flexGrow: 1,
              textAlign: "left"
            }}
          >
            <Typography variant="h6" sx={{ userSelect: "none" }}>
              Systems Engineering Toolkit
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Typography variant="subtitle1" sx={{ userSelect: "none" }}>
                Simple. Effective. Tools.
              </Typography>
              {chip}
            </Box>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              textAlign: "left"
            }}
          >
            <ModelName />
            <ModificationCounter />
          </Box>
          <FileInputButton />
          <FileDownloadButton />
          <NewModelButton />
          <SettingsButton />
          <Tooltip
            title="User Guide"
            aria-label="User Guide"
            placement="bottom"
            disableFocusListener={true}
          >
            <IconButton
              href="https://docs.se-toolkit.app"
              rel="noopener"
              target="_blank"
              color="inherit"
              aria-label="help"
              size="medium"
            >
              <Help />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: theme.palette.primary.dark,
            padding: 1
          }}
          disableGutters={true}
        >
          <CreateButton />
          <UpdateButton />
          <DeleteButton />
          <UndoRedoButtons />
        </Toolbar>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            position: "relative"
          }}
          onDrop={onDrop}
          onDragOver={onDragOver}
        >
          <BrowserRouter>
            <TabBar />
          </BrowserRouter>
        </Box>
        <FileDropHandler />
      </Box>
      <Paper>
        <Typography
          sx={{
            backgroundColor: theme.palette.primary.dark,
            color: "rgba(255, 255, 255, 0.7)",
            padding: 0.5,
            textAlign: "center",
            userSelect: "none",
            whiteSpace: "nowrap"
          }}
          variant="subtitle2"
        >
          Version {version}.{release}. Copyright © 2019-
          {new Date().getFullYear()} Tristan Martindale. Email:{" "}
          <Link href="mailto: hello@se-toolkit.app" color="secondary">
            hello@se-toolkit.app
          </Link>
        </Typography>
      </Paper>
      <Welcome version={version} release={release} />
    </Box>
  );
}
