import findElementInModel from "../search/findElementInModel";
import getTimeStamp from "../utils/getTimeStamp";
import sortRelationships from "../utils/sortRelationships";
import validateRelationship from "../validators/validateRelationship";
/**
 * Insert a relationship by splitting an existing relationship between a source and a target.
 * @param model The model.
 * @param id The id of the element to insert.
 * @param relationship The relationship to split.
 * @param oldSourceIds The ids of the old source elements of the relationship.
 * If provided then the relationship will be removed from these elements.
 * @returns true if the model was modified or false if not.
 */
export default function insertRelationship(model, id, relationship, oldSourceIds) {
    const { sourceId, targetId, type } = relationship;
    if (id === sourceId) {
        throw new Error(`Failed to insert relationship. Element ${id} cannot have a relationship with itself.`);
    }
    const { sourceElement } = validateRelationship(model, {
        ...relationship,
        targetId: id
    });
    // check whether the relationship already exists
    if (sourceElement.relationships.find(r => r.targetId === id && r.type === type))
        return false;
    const relationshipIndex = sourceElement.relationships.findIndex(r => r.targetId === targetId && r.type === type);
    if (relationshipIndex < 0) {
        throw new Error(`Failed to insert relationship. The current source element, ${sourceId}, does not have a ${type} relationship with ${targetId}.`);
    }
    const { sourceElement: element } = validateRelationship(model, {
        ...relationship,
        sourceId: id
    });
    sourceElement.relationships.splice(relationshipIndex, 1, {
        targetId: id,
        type
    });
    sortRelationships(sourceElement.relationships);
    element.relationships.push({ targetId, type });
    sortRelationships(element.relationships);
    let description = `Inserted ${type} relationship with ${id} between ${sourceId} and ${targetId}.`;
    if (oldSourceIds) {
        const oldSourceElements = [];
        oldSourceIds.forEach(id => {
            const element = findElementInModel(model, id);
            if (element)
                oldSourceElements.push(element);
        });
        oldSourceElements.forEach(e => {
            const oldRelationshipIndex = e.relationships.findIndex(r => r.targetId === id && r.type === type);
            if (oldRelationshipIndex >= 0) {
                e.relationships.splice(oldRelationshipIndex, 1);
            }
        });
        const oldSourceIdsString = oldSourceElements.map(e => e.id).join(" and ");
        description = `Moved ${type} relationship with ${id} from ${oldSourceIdsString} and inserted it between ${sourceId} and ${targetId}.`;
    }
    model.changeLog.push({
        time: getTimeStamp(),
        operation: "update",
        description
    });
    return true;
}
