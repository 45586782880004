import { z } from "zod";
import { Element } from "./Element";
export const AnnotationType = z.enum([
    "Action",
    "Assumption",
    "Lesson",
    "Opportunity",
    "Risk"
]);
export const AnnotationPriority = z.enum(["None", "Low", "Medium", "High"]);
export const AnnotationAttributes = z.object({
    text: z
        .string()
        .min(1, { message: "An annotation may not have empty text." }),
    type: AnnotationType,
    priority: AnnotationPriority.default("None"),
    closedOn: z.number().nullable().default(null),
    remarks: z.string().default("")
});
export const Annotation = Element.extend({ attributes: AnnotationAttributes });
