import PaletteIcon from "@mui/icons-material/Palette";
import { ClickAwayListener } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Popper from "@mui/material/Popper";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { Color, ColorResult, CompactPicker } from "react-color";
import { useWindowEventListener } from "../../interactions";

export interface Props {
  color?: Color | undefined;
  handleSubmit: (colour: string) => void;
}

export default function ColourPickerPopover({ color, handleSubmit }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const onClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  const onClickAway = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  useWindowEventListener(
    "keydown",
    React.useCallback((e: KeyboardEvent) => {
      e.preventDefault();
      if (e.key === "Escape") setAnchorEl(null);
    }, [])
  );

  const open = Boolean(anchorEl);

  const submitChange = React.useCallback(
    (color: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => {
      setAnchorEl(null);
      handleSubmit(color.hex);
    },
    [handleSubmit]
  );

  return (
    <>
      <Tooltip
        title="Pick a colour"
        aria-label="Pick a colour"
        placement="left"
        disableFocusListener={true}
      >
        <IconButton
          sx={{
            backgroundColor: color?.toString()
          }}
          color="inherit"
          aria-label="submit"
          size="small"
          onClick={onClick}
        >
          <PaletteIcon />
        </IconButton>
      </Tooltip>
      <Popper
        sx={{
          minWidth: "35%",
          maxHeight: "50%",
          overflow: "auto"
        }}
        id="colour-picker-popover"
        open={open}
        anchorEl={anchorEl}
      >
        <ClickAwayListener touchEvent={false} onClickAway={onClickAway}>
          <CompactPicker color={color} onChangeComplete={submitChange} />
        </ClickAwayListener>
      </Popper>
    </>
  );
}
