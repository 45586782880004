import SearchIcon from "@mui/icons-material/Search";
import Autocomplete, {
  AutocompleteRenderInputParams,
  createFilterOptions
} from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { SxProps, Theme } from "@mui/material/styles";
import React from "react";
import { useStopPropogation } from "../interactions";

export interface AutocompleteOption {
  id: string;
  label: string;
}

export interface Props {
  sx?: SxProps<Theme>;
  options?: AutocompleteOption[];
  onClose?: () => void;
  selectOption?: (value: string) => void;
}

export default function SearchInput({
  sx,
  options = [],
  onClose = () => {},
  selectOption = () => {}
}: Props) {
  const filterOptions = createFilterOptions({
    ignoreCase: true,
    trim: true
  });

  const selectedOption = React.useRef<string | null>(null);
  const onChange = React.useCallback(
    (e: React.ChangeEvent, value: AutocompleteOption) => {
      if (!value || !value.id) return;
      selectedOption.current = value.id;
    },
    []
  );

  const handleClose = React.useCallback(
    (e: React.SyntheticEvent, reason: string) => {
      onClose();
      if (reason === "selectOption") {
        selectOption(selectedOption.current || "");
      }
    },
    [onClose, selectOption]
  );

  const renderInput = React.useCallback(
    (params: AutocompleteRenderInputParams) => {
      return (
        <TextField
          {...params}
          label="Search"
          size="small"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      );
    },
    []
  );

  const stopPropagation = useStopPropogation();

  return (
    <Autocomplete
      sx={sx}
      options={options}
      filterOptions={filterOptions}
      blurOnSelect={true}
      clearOnBlur={true}
      clearOnEscape={true}
      handleHomeEndKeys={false}
      selectOnFocus={true}
      noOptionsText="No elements"
      renderInput={renderInput}
      onChange={onChange}
      onClose={handleClose}
      onKeyDown={stopPropagation}
      onKeyUp={stopPropagation}
    />
  );
}
