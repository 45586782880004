import Input from "@mui/material/Input";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";

export interface Props {
  value: number;
  leadingText?: string;
  trailingText?: string;
  step?: number;
  min?: number;
  max?: number;
  handleChange: (value: number) => void;
}

export default function TextWithInlineNumberInput({
  value,
  leadingText,
  trailingText,
  step,
  min = 0,
  max,
  handleChange
}: Props) {
  const [inputValue, setInputValue] = React.useState<
    number | string | Array<number | string>
  >(value);

  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue =
        event.target.value === "" ? "" : parseInt(event.target.value);
      setInputValue(newValue);
      handleChange(newValue === "" ? min : Math.max(min, newValue));
    },
    [handleChange, min]
  );

  return (
    <Stack direction="row" spacing={1}>
      <Typography alignSelf="center">{leadingText}</Typography>
      <Input
        sx={{ width: 40 }}
        value={inputValue}
        size="small"
        onChange={onChange}
        inputProps={{
          step,
          min,
          max,
          type: "number"
        }}
      />
      <Typography alignSelf="center">{trailingText}</Typography>
    </Stack>
  );
}
