export default function getElementsArray(model, metatype) {
    const getArray = (metatype) => {
        switch (metatype) {
            case "Annotation":
                return model.annotations;
            case "Component":
                return model.components;
            case "Milestone":
                return model.milestones;
            case "Requirement":
                return model.requirements;
            case "Stakeholder":
                return model.stakeholders;
            default:
                return [];
        }
    };
    return getArray(metatype);
}
