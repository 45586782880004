import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import AnnotationsTable from "./AnnotationsTable";

export default function AssumptionsLessons() {
  return (
    <Paper
      sx={{
        display: "flex",
        height: "100%",
        width: "100%"
      }}
    >
      <Stack flexGrow={1} spacing={1}>
        <AnnotationsTable mode="Assumption" />
        <AnnotationsTable mode="Lesson" />
      </Stack>
    </Paper>
  );
}
