import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { saveStringToFile } from "../../../utils/saveToFile";
import StringInputDialog from "../../components/StringInputDialog";
import createSvgString from "../../utils/createSvgString";

export interface Props {
  svgRef: React.RefObject<SVGSVGElement>;
  initialFileName?: string;
  disabled?: boolean;
}

export default function SaveAsSVGButton({
  svgRef,
  initialFileName = "",
  disabled = false
}: Props) {
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitFileName = (fileName: string) => {
    setOpen(false);
    saveAsSVG(svgRef, fileName);
  };

  return (
    <>
      <Tooltip
        title="Save diagram as SVG image"
        aria-label="Save diagram as SVG image"
        placement="bottom"
        disableFocusListener={true}
      >
        <span>
          <Button
            sx={{
              border: `1px solid ${
                theme.palette.mode === "dark"
                  ? "rgba(255, 255, 255, 0.23)"
                  : "rgba(0, 0, 0, 0.23)"
              }`,
              maxHeight: "32px",
              padding: "0px 10px",
              textTransform: "none"
            }}
            color="inherit"
            aria-label="svg"
            variant="outlined"
            disabled={disabled}
            onClick={handleClick}
          >
            <SaveIcon />
            <Typography variant="h6" align="center" color="inherit">
              .svg
            </Typography>
          </Button>
        </span>
      </Tooltip>
      <StringInputDialog
        title="Save diagram as SVG image"
        label="File name"
        initialValue={initialFileName}
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmitFileName}
      />
    </>
  );
}

function saveAsSVG(svgRef: React.RefObject<SVGSVGElement>, fileName: string) {
  if (!svgRef || !svgRef.current) {
    return;
  }

  const svgString = createSvgString(svgRef.current);

  saveStringToFile(svgString, `${fileName}.svg`, "image/svg+xml");
}
