export default function formatDate(value, { andTime = false } = { andTime: false }) {
    if (typeof value === "number" && value.toString().length === 10) {
        value = value * 1000; // convert from seconds to miliseconds
    }
    const date = new Date(value);
    let month = (date.getMonth() + 1).toString();
    if (month.length < 2) {
        month = "0" + month;
    }
    let day = date.getDate().toString();
    if (day.length < 2) {
        day = "0" + day;
    }
    const year = date.getFullYear();
    let hours = date.getHours().toString();
    if (hours.length < 2) {
        hours = "0" + hours;
    }
    let minutes = date.getMinutes().toString();
    if (minutes.length < 2) {
        minutes = "0" + minutes;
    }
    const time = andTime ? ` @ ${hours}:${minutes}` : "";
    return `${year}-${month}-${day}${time}`;
}
