import { utils as xlsx } from "xlsx";
import findElementParents from "../../search/findElementParents";
import formatVariableName from "../../utils/formatVariableName";
import formatWorksheet from "./formatWorksheet";
export default function createRequirementsSheet(model) {
    const rows = model.requirements.map(r => ({
        id: r.id,
        text: r.attributes.text,
        type: formatVariableName(r.attributes.type),
        verificationMethod: r.attributes.verificationMethod,
        owner: findElementParents(model.stakeholders, r.id, "responsible_for")[0]
            ?.attributes.name || "",
        allocation: findElementParents(model.components, r.id, "specified_by")
            .map(e => `${e.id}: ${e.attributes.name}`)
            .join("\n") || ""
    }));
    const ws = xlsx.json_to_sheet(rows);
    const headers = [
        "ID",
        "Requirement",
        "Type",
        "Verification Method",
        "Owner",
        "Allocation"
    ];
    xlsx.sheet_add_aoa(ws, [headers], { origin: "A1" });
    formatWorksheet(ws, [headers, ...rows.map(r => Object.values(r))]);
    return ws;
}
