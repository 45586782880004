import getTimeStamp from "../utils/getTimeStamp";
import sortRelationships from "../utils/sortRelationships";
import validateRelationship from "../validators/validateRelationship";
/**
 * Create a relationship between elements of the model.
 * @param model The model.
 * @param relationship The relationship to create.
 * @returns true if the model was modified or false if not.
 */
export default function createRelationship(model, relationship) {
    const { sourceId, targetId, type } = relationship;
    if (sourceId === targetId) {
        throw new Error(`Failed to create relationship. Element ${sourceId} cannot have a relationship with itself.`);
    }
    const { sourceElement, targetElement } = validateRelationship(model, relationship);
    // check whether the relationship already exists
    if (sourceElement.relationships.find(r => r.targetId === targetId && r.type === type))
        return false;
    sourceElement.relationships.push({ targetId, type });
    sortRelationships(sourceElement.relationships);
    const targetName = "name" in targetElement.attributes
        ? ` (${targetElement.attributes.name})`
        : "";
    sourceElement.changeLog.push({
        time: getTimeStamp(),
        operation: "create",
        description: `Created ${type} relationship with ${targetId}${targetName}.`
    });
    return true;
}
