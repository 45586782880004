export default function getCurrentView(): string | undefined {
  if (window.location.pathname.includes("interfaces")) {
    return "Interfaces";
  }
  if (window.location.pathname.includes("requirements")) {
    return "Requirements";
  }
  if (window.location.pathname.includes("roadmap")) {
    return "Roadmap";
  }
  if (window.location.pathname.includes("structure")) {
    return "Structure";
  }
}
