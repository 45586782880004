import { useTheme } from "@mui/material/styles";
import { Shape } from "../../../layouts";
import { SvgText } from "../../components/SvgText";
import { AnimatedShape } from "./AnimatedShape";
import { Interaction, Type } from "./Interaction";

export interface LabelProps {
  metatype: string;
  id?: string;
  text: string;
  colour?: string;
}

export interface Props {
  shape: Shape;
  text: string;
  labelProps?: LabelProps;
  hasAnnotations?: boolean;
  hasDescription?: boolean;
  hasSpecification?: boolean;
  isSelected?: boolean;
  areAnnotationsFixed?: boolean;
  isDescriptionFixed?: boolean;
  isSpecificationFixed?: boolean;
  setAnnotationsAnchor?: (e: SVGRectElement | null, isFixed: boolean) => void;
  setDescriptionAnchor?: (e: SVGRectElement | null, isFixed: boolean) => void;
  setSpecificationAnchor?: (e: SVGRectElement | null, isFixed: boolean) => void;
}

export const HierarchyElement = ({
  shape,
  text,
  labelProps,
  hasAnnotations = false,
  hasDescription = false,
  hasSpecification = false,
  isSelected = false,
  areAnnotationsFixed = false,
  isDescriptionFixed = false,
  isSpecificationFixed = false,
  setAnnotationsAnchor,
  setDescriptionAnchor,
  setSpecificationAnchor
}: Props) => {
  const theme = useTheme();

  const radius = 0;
  const idWidth = 54;
  const idHeight = 18;
  const idXOffset = 4;
  const idYOffset = -16;
  const iconWidth = 18;
  const iconHeight = 18;
  const padding = 2;

  const labelHeight = iconHeight + 2 * padding;

  return (
    <AnimatedShape shape={shape}>
      {/* the shape is drawn relative to its top left corner */}
      <g transform={`translate(${-shape.width / 2},${-shape.height / 2})`}>
        <rect
          data-id={shape.id}
          className="element-rect"
          width={shape.width}
          height={shape.height}
          rx={radius}
          ry={radius}
          transform={`rotate(${shape.rotation},0,0)`}
          fill={theme.palette.shape.fill}
          stroke="none"
        />
        <SvgText
          className="element-text"
          x={padding}
          y={padding}
          width={shape.width - 2 * padding}
          height={shape.height - labelHeight - 2 * padding}
          rotation={shape.rotation}
        >
          {text}
        </SvgText>
        <g transform={`translate(${0},${shape.height})`}>
          {/* the label is drawn relative to the bottom left corner of the shape */}
          <rect
            data-id={shape.id}
            data-labelmetatype={labelProps?.metatype}
            data-labelid={labelProps?.id}
            data-labeltext={labelProps?.text}
            width={shape.width}
            height={labelHeight}
            x={0}
            y={-iconHeight - 2 * padding}
            rx={radius}
            ry={radius}
            transform={`rotate(${shape.rotation},0,0)`}
            fill={labelProps?.colour || theme.palette.grey[700]}
            stroke="none"
          />
          <SvgText
            className="element-label"
            color={theme.palette.common.white}
            x={padding}
            y={-iconHeight - padding}
            width={shape.width - 3 * (iconWidth + padding)}
            height={iconHeight}
            rotation={shape.rotation}
          >
            {labelProps?.text}
          </SvgText>
        </g>
        <rect
          className="exclude-from-export"
          width={shape.width}
          height={shape.height}
          rx={radius}
          ry={radius}
          transform={`rotate(${shape.rotation},0,0)`}
          fill="none"
          stroke={isSelected ? theme.palette.shape.selected : "none"}
          strokeWidth={isSelected ? 4 : ""}
          strokeOpacity={isSelected ? 1 : ""}
        />
        <g transform={`translate(${idXOffset},${idYOffset})`}>
          {/* the id is drawn relative to the top left corner of the shape */}
          <rect
            data-id={shape.id}
            className="id-rect"
            width={idWidth + 2 * padding}
            height={idHeight + 2 * padding}
            rx={radius}
            ry={radius}
            transform={`rotate(${shape.rotation},0,0)`}
            fill={theme.palette.shape.fill}
            stroke={theme.palette.grey[900]}
          />
          <SvgText
            className="id-text"
            color={theme.palette.text.secondary}
            x={padding}
            y={padding}
            width={idWidth}
            height={idHeight}
            rotation={shape.rotation}
          >
            {shape.id}
          </SvgText>
        </g>
      </g>
      {/* interaction icons are positioned relative to the bottom right corner of the shape */}
      <g transform={`translate(${shape.width / 2},${shape.height / 2})`}>
        <Interaction
          type={Type.Annotations}
          id={shape.id}
          x={-iconWidth - padding}
          y={-iconHeight - padding}
          width={iconWidth}
          height={iconHeight}
          color={theme.palette.common.white}
          isOn={hasAnnotations}
          isFixed={areAnnotationsFixed}
          setAnchor={setAnnotationsAnchor}
        />
        <Interaction
          type={Type.Description}
          id={shape.id}
          x={-3 * iconWidth - padding}
          y={-iconHeight - padding}
          width={iconWidth}
          height={iconHeight}
          color={theme.palette.common.white}
          isOn={hasDescription}
          isFixed={isDescriptionFixed}
          setAnchor={setDescriptionAnchor}
        />
        <Interaction
          type={Type.Specification}
          id={shape.id}
          x={-2 * iconWidth - padding}
          y={-iconHeight - padding}
          width={iconWidth}
          height={iconHeight}
          color={theme.palette.common.white}
          isOn={hasSpecification}
          isFixed={isSpecificationFixed}
          isEditable={false}
          setAnchor={setSpecificationAnchor}
        />
      </g>
    </AnimatedShape>
  );
};
