export default function formatVariableName(value, capilitizeFirstLetter = true) {
    if (value === "CDRL")
        return value;
    const match = value.match(/([A-Z]?[^A-Z]*)/g);
    const result = match
        ?.slice(0, -1)
        .join(" ")
        .replace(/[0-9]+$/g, " $&")
        .replace(/\//g, " /")
        .replace(/^non /gi, "non-");
    return capilitizeFirstLetter
        ? `${result?.charAt(0).toUpperCase()}${result?.slice(1)}`
        : result?.toLowerCase() ?? "";
}
