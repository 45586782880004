import SaveIcon from "@mui/icons-material/Save";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import React from "react";
import { useSelector } from "react-redux";
import { selectModificationCounter } from "../features";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      transition: "opacity 0.5s ease"
    },
    button: {
      "&:hover": {
        backgroundColor: "transparent"
      }
    }
  });

export interface Props extends WithStyles<typeof styles> {}

const ModificationCounter = ({ classes }: Props) => {
  const counter: number = useSelector(selectModificationCounter);

  const style = React.useMemo(
    () => ({
      opacity: counter === 0 ? 0 : 1,
      pointerEvents: counter === 0 ? "none" : "auto"
    }),
    [counter]
  );

  const onClick = React.useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    window.dispatchEvent(new CustomEvent("savemodel"));
  }, []);

  return (
    <Tooltip
      className={classes.root}
      title="The model contains unsaved changes. Click to download."
      aria-label="The model contains unsaved changes. Click to download."
      placement="right"
      disableFocusListener={true}
      style={style as React.CSSProperties}
      enterTouchDelay={0}
    >
      <IconButton
        className={classes.button}
        color="inherit"
        disableRipple={true}
        onClick={onClick}
        size="large"
      >
        <SaveIcon />
      </IconButton>
    </Tooltip>
  );
};

export default withStyles(styles, { withTheme: true })(ModificationCounter);
