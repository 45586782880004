import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowModel,
  GridRowParams,
  GridRowsProp
} from "@mui/x-data-grid";
import React from "react";
import { useSelector } from "react-redux";
import {
  deleteElements,
  makeSelectModelElements,
  updateElement,
  updateElementId,
  useAppDispatch
} from "../../../features";
import PaperTable from "../../components/PaperTable";
import ColourPickerPopover from "../controls/ColourPickerPopover";
import useNumberStringComparator from "../gridutils/useNumberStringComparator";
import usePreProcessEditID from "../gridutils/usePreProcessEditID";

export default function StakeholdersTable() {
  const dispatch = useAppDispatch();

  const stakeholders = useSelector(makeSelectModelElements("Stakeholder"));

  const rows: GridRowsProp = React.useMemo(
    () =>
      stakeholders.map(s => ({
        id: s.id,
        ...s.attributes
      })),
    [stakeholders]
  );

  const preProcessEditID = usePreProcessEditID(stakeholders);

  const processRowUpdate = React.useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel): GridRowModel => {
      let isUpdated = false;
      if (newRow.id && newRow.id !== oldRow.id) {
        dispatch(
          updateElementId({
            oldId: oldRow.id,
            newId: newRow.id
          })
        );
        isUpdated = true;
      }
      if (newRow.name && newRow.name !== oldRow.name) {
        dispatch(
          updateElement({
            id: oldRow.id,
            attributes: { name: newRow.name }
          })
        );
        isUpdated = true;
      }
      if (newRow.description !== oldRow.description) {
        dispatch(
          updateElement({
            id: oldRow.id,
            attributes: { description: newRow.description }
          })
        );
        isUpdated = true;
      }
      return isUpdated ? newRow : oldRow;
    },
    [dispatch]
  );

  const renderActions = React.useCallback(
    (params: GridRowParams) => {
      return [
        <ColourPickerPopover
          color={params.row.colour}
          handleSubmit={(colour: string) => {
            if (colour) {
              dispatch(
                updateElement({ id: params.row.id, attributes: { colour } })
              );
            }
          }}
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title={`Delete ${params.row.id}`} placement="left">
              <DeleteIcon />
            </Tooltip>
          }
          label="Delete"
          onClick={() => dispatch(deleteElements([params.row.id]))}
        />
      ];
    },
    [dispatch]
  );

  const numberStringComparator = useNumberStringComparator();

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        editable: true,
        sortComparator: numberStringComparator,
        preProcessEditCellProps: preProcessEditID
      },
      {
        field: "name",
        headerName: "Stakeholder",
        width: 150,
        editable: true
      },
      {
        field: "description",
        headerName: "Description",
        flex: 1,
        editable: true
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 120,
        getActions: renderActions
      }
    ],
    [numberStringComparator, preProcessEditID, renderActions]
  );

  return (
    <PaperTable
      tableName="Dashboard.Stakeholders"
      title="Stakeholders"
      rows={rows}
      columns={columns}
      processRowUpdate={processRowUpdate}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 5
          }
        },
        sorting: {
          sortModel: [{ field: "id", sort: "asc" }]
        }
      }}
    />
  );
}
