import RedoIcon from "@mui/icons-material/Redo";
import UndoIcon from "@mui/icons-material/Undo";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { ActionCreators } from "redux-undo";
import { useAppDispatch } from "../../features";

export default function UndoRedoButtons() {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const handleUndo = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(ActionCreators.undo());
  };

  const handleRedo = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(ActionCreators.redo());
  };

  return (
    <ButtonGroup
      sx={{ marginTop: theme.spacing(1) }}
      size="medium"
      orientation="vertical"
      variant="outlined"
    >
      <Tooltip
        title="Undo (ctrl+z, cmd+z)"
        aria-label="Undo (ctrl+z, cmd+z)"
        placement="right"
        disableFocusListener={true}
      >
        <Button
          sx={{ borderColor: theme.palette.secondary.dark }}
          aria-label="Undo"
          onClick={handleUndo}
        >
          <UndoIcon sx={{ color: theme.palette.common.white }} />
        </Button>
      </Tooltip>
      <Tooltip
        title="Redo (ctrl+y, cmd+shift+z)"
        aria-label="Redo (ctrl+y, cmd+shift+z)"
        placement="right"
        disableFocusListener={true}
      >
        <Button
          sx={{ borderColor: theme.palette.secondary.dark }}
          aria-label="Redo"
          onClick={handleRedo}
        >
          <RedoIcon sx={{ color: theme.palette.common.white }} />
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
}
