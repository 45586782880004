import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Switch } from "@mui/material";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import {
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridRowParams,
  GridRowsProp
} from "@mui/x-data-grid";
import { findElementParents } from "@se-toolkit/model-js/search";
import React from "react";
import { useSelector } from "react-redux";
import {
  createRelationship,
  deleteRelationship,
  makeSelectModelElements,
  useAppDispatch
} from "../../../features";
import { SETColours } from "../../../theme";
import { Type } from "../../components/InteractionIcon";
import PaperTable from "../../components/PaperTable";
import AnnotationsPopover from "../../composition/AnnotationsPopover";
import SpecificationPopover from "../../composition/SpecificationPopover";
import { useAnchor } from "../../interactions";
import { useDynamicConfig } from "../../utils/ConfigContextProvider";
import useNumberStringComparator from "../gridutils/useNumberStringComparator";
import useRenderComponentType from "../gridutils/useRenderComponentType";
import useRenderInteractionIcon from "../gridutils/useRenderInteractionIcon";
import RequirementsTable from "./RequirementsTable";

export interface Props {
  selectedIds?: string[];
  setSelectedIds?: (ids: string[]) => void;
}

export default function AllocationTables({
  selectedIds = [],
  setSelectedIds = () => {}
}: Props) {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const viewName = "Requirements";

  const annotations = useSelector(makeSelectModelElements("Annotation"));
  const components = useSelector(makeSelectModelElements("Component"));

  const [showSpecifiedOnly, setShowSpecifiedOnly] = useDynamicConfig<boolean>(
    `${viewName}.showSpecifiedOnly`,
    true
  );

  const componentRows: GridRowsProp = React.useMemo(() => {
    const rows = components.map(c => {
      const hasAnnotations: boolean = !!findElementParents(
        annotations,
        c.id,
        "annotates"
      ).length;
      const hasSpecification: boolean = !!c.relationships.find(
        r => r.type === "specified_by"
      );
      const isSpecifiedBySelected: boolean = !!c.relationships.find(
        r => r.type === "specified_by" && selectedIds.includes(r.targetId)
      );
      return {
        id: c.id,
        name: c.attributes.name,
        type: c.attributes.type,
        hasAnnotations,
        hasSpecification,
        isSpecifiedBySelected
      };
    });
    return showSpecifiedOnly ? rows.filter(r => r.isSpecifiedBySelected) : rows;
  }, [annotations, components, selectedIds, showSpecifiedOnly]);

  const renderComponentActions = React.useCallback(
    (params: GridRowParams) =>
      params.row.isSpecifiedBySelected
        ? [
            <GridActionsCellItem
              icon={
                <Tooltip title={`Deallocate ${params.row.id}`} placement="left">
                  <RemoveCircleOutlineIcon />
                </Tooltip>
              }
              label="Deallocate"
              onClick={() =>
                dispatch(
                  deleteRelationship({
                    sourceId: params.row.id,
                    targetId: selectedIds[0],
                    type: "specified_by"
                  })
                )
              }
              disabled={selectedIds.length !== 1}
            />
          ]
        : [
            <GridActionsCellItem
              icon={
                <Tooltip title={`Allocate ${params.row.id}`} placement="left">
                  <AddCircleOutlineIcon />
                </Tooltip>
              }
              label="Allocate"
              onClick={() =>
                dispatch(
                  createRelationship({
                    sourceId: params.row.id,
                    targetId: selectedIds[0],
                    type: "specified_by"
                  })
                )
              }
              disabled={selectedIds.length !== 1}
            />
          ],
    [dispatch, selectedIds]
  );

  const renderComponentType = useRenderComponentType();
  const numberStringComparator = useNumberStringComparator();

  const stackRef = React.useRef<HTMLDivElement>(null);
  const specificationAnchor = useAnchor(stackRef);
  const annotationsAnchor = useAnchor(stackRef);
  const renderSpecification = useRenderInteractionIcon(
    Type.Specification,
    specificationAnchor,
    "hasSpecification",
    false
  );
  const renderAnnotations = useRenderInteractionIcon(
    Type.Annotations,
    annotationsAnchor,
    "hasAnnotations"
  );

  const componentColumns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        getActions: renderComponentActions,
        cellClassName: (params: GridCellParams<any, number>) =>
          params.row.isSpecifiedBySelected ? `component--specified` : ""
      },
      {
        field: "id",
        headerName: "ID",
        sortComparator: numberStringComparator
      },
      {
        field: "name",
        headerName: "Name",
        flex: 1
      },
      {
        field: "type",
        headerName: "Type",
        width: 120,
        renderCell: renderComponentType
      },
      {
        field: "specification",
        type: "actions",
        width: 110,
        headerName: "Specification",
        getActions: renderSpecification
      },
      {
        field: "annotations",
        type: "actions",
        headerName: "Annotations",
        getActions: renderAnnotations
      }
    ],
    [
      numberStringComparator,
      renderAnnotations,
      renderComponentActions,
      renderSpecification,
      renderComponentType
    ]
  );

  return (
    <>
      <Stack
        ref={stackRef}
        sx={{ height: "100%", width: "100%" }}
        direction="row"
      >
        <Box
          sx={{
            width: "60%"
          }}
        >
          <RequirementsTable
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            columnVisibilityModel={{
              type: false,
              verificationMethod: false,
              stakeholderId: false,
              allocation: false,
              actions: false
            }}
            hideExportToXlsxButton={true}
          />
        </Box>
        <Box
          sx={{
            width: "40%",
            "& .component--specified": {
              backgroundColor: SETColours.component.Specified,
              color: theme.palette.common.white
            }
          }}
        >
          <PaperTable
            tableName={`${viewName}.Components`}
            title="Components"
            rows={componentRows}
            columns={componentColumns}
            columnVisibilityModel={{
              specification: false
            }}
          >
            <FormGroup row={true}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showSpecifiedOnly}
                    onClick={() => setShowSpecifiedOnly(!showSpecifiedOnly)}
                  />
                }
                label="Show only specified components"
                labelPlacement="start"
              />
            </FormGroup>
          </PaperTable>
        </Box>
      </Stack>
      <SpecificationPopover anchor={specificationAnchor} />
      <AnnotationsPopover anchor={annotationsAnchor} />
    </>
  );
}
