import { findElement } from "../search";
/**
 * Count stakeholders
 * @param requirements - The requirements to count stakeholders for.
 * @param stakeholders - The stakeholders to count.
 * @returns A count of the following items:
 * - system: A map of stakeholders to the number of system requirements that they are responsible for
 * - cdrl: A map of stakeholders to the number of CDRL requirements that they are responsible for
 * - nonSystem: A map of stakeholders to the number of non-system requirements that they are responsible for
 */
export default function countStakeholders(requirements, stakeholders) {
    const system = new Map();
    const cdrl = new Map();
    const nonSystem = new Map();
    let ownedSystem = 0;
    let ownedCDRL = 0;
    let ownedNonSystem = 0;
    for (const s of stakeholders) {
        for (const r of s.relationships) {
            if (r.type === "responsible_for") {
                const requirement = findElement(requirements, r.targetId);
                if (!requirement)
                    continue;
                switch (requirement.attributes.type) {
                    case "CDRL":
                        cdrl.set(s.id, (cdrl.get(s.id) || 0) + 1);
                        ownedCDRL++;
                        break;
                    case "NonSystem":
                        nonSystem.set(s.id, (nonSystem.get(s.id) || 0) + 1);
                        ownedNonSystem++;
                        break;
                    default:
                        system.set(s.id, (system.get(s.id) || 0) + 1);
                        ownedSystem++;
                        break;
                }
            }
        }
    }
    const systemTotal = requirements.filter(r => !["CDRL", "NonSystem"].includes(r.attributes.type)).length;
    system.set("NotOwned", systemTotal - ownedSystem);
    const cdrlTotal = requirements.filter(r => r.attributes.type === "CDRL").length;
    cdrl.set("NotOwned", cdrlTotal - ownedCDRL);
    const nonSystemTotal = requirements.filter(r => r.attributes.type === "NonSystem").length;
    nonSystem.set("NotOwned", nonSystemTotal - ownedNonSystem);
    return {
        systemCount: {
            items: system,
            total: systemTotal
        },
        cdrlCount: {
            items: cdrl,
            total: cdrlTotal
        },
        nonSystemCount: {
            items: nonSystem,
            total: nonSystemTotal
        }
    };
}
