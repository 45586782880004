/**
 * Determine the root elements of a hierarchy based on a specified relationship type
 * @param model The model.
 * @param relationshipType The type of relationship to look for.
 * @returns A list of root element ids.
 */
export default function determineRootElements(elements, relationshipType) {
    /*
     * The root elements of a hierarchy based on any particular relationship type
     * are those elements which are never the target of a relationship with the
     * corresponding type. As of version 0.9.0, they do not need to be the source
     * of a relationship with the corresponding type either. In other words: they
     * do not need to have any children of their own.
     */
    const sourceIds = new Set();
    const targetIds = new Set();
    elements.forEach(e => {
        sourceIds.add(e.id);
        const relationships = e.relationships.filter(r => r.type === relationshipType);
        if (relationships.length) {
            relationships.forEach((r) => targetIds.add(r.targetId));
        }
    });
    return Array.from(difference(sourceIds, targetIds));
}
function difference(setA, setB) {
    const difference = new Set(setA);
    for (const element of setB) {
        difference.delete(element);
    }
    return difference;
}
