import { ElementMetatype } from "../schema";
import { getElementsArray } from "../schemautils";
/**
 * Count element metatypes
 * @returns A count of element metatypes to the number of elements of that metatype
 */
export default function countMetatypes(model) {
    const items = new Map();
    let total = 0;
    ElementMetatype.options.forEach(key => {
        const elementsArray = getElementsArray(model, key);
        items.set(key, elementsArray.length);
        total += elementsArray.length;
    });
    return {
        items,
        total
    };
}
