import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  AnnotationPriority,
  AnnotationType
} from "@se-toolkit/model-js/schema";
import { formatDate } from "@se-toolkit/model-js/utils";
import React from "react";

export interface Props {
  id: string;
  text: string;
  type: AnnotationType;
  priority: AnnotationPriority;
  closedOn: number | null;
  isEditable?: boolean;
  submitChange: (
    id: string,
    text: string,
    type: AnnotationType,
    priority: AnnotationPriority
  ) => void;
}

export default function Annotation({
  id,
  text,
  type,
  priority,
  closedOn,
  isEditable = false,
  submitChange
}: Props) {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [selectedType, setSelectedType] = React.useState<AnnotationType>(type);
  const [selectedPriority, setSelectedPriority] =
    React.useState<AnnotationPriority>(priority);

  const newType = React.useRef<AnnotationType>(type);
  const newText = React.useRef<string>(text);
  const newPriority = React.useRef<AnnotationPriority>(priority);

  const onClick = React.useCallback((e: React.MouseEvent<HTMLInputElement>) => {
    if (inputRef.current && inputRef.current !== document.activeElement) {
      e.preventDefault();
      const input = inputRef.current;
      input.focus();
      input.setSelectionRange(input.value.length, input.value.length);
    }
  }, []);

  const onChangeType = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setSelectedType(e.currentTarget.value as AnnotationType);
      newType.current = e.currentTarget.value as AnnotationType;
    },
    []
  );

  const onChangeText = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      newText.current = e.currentTarget.value;
    },
    []
  );

  const onChangePriority = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setSelectedPriority(e.currentTarget.value as AnnotationPriority);
      newPriority.current = e.currentTarget.value as AnnotationPriority;
    },
    []
  );

  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter" && inputRef.current) e.stopPropagation();
      if (e.key === "Escape" && inputRef.current) {
        newText.current = text;
        inputRef.current.value = text;
      }
    },
    [text]
  );

  React.useEffect(() => {
    return () => {
      if (
        newType.current !== type ||
        newText.current.trim() !== text ||
        newPriority.current !== priority
      ) {
        submitChange(
          id,
          newText.current.trim(),
          newType.current,
          newPriority.current
        );
      }
    };
  }, [newType, submitChange, type, text, priority, id]);

  let placeholder =
    type && text
      ? "Existing annotation will be deleted. Press escape to cancel."
      : "Enter a new annotation...";

  return (
    <Grid
      container={true}
      spacing={1}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item={true} xs={1.5}>
        <TextField
          id="id-input"
          type="text"
          label="ID"
          color="secondary"
          margin="dense"
          defaultValue={id || "-"}
          disabled={true}
        />
      </Grid>
      <Grid item={true} xs={2}>
        <TextField
          id="annotation-type"
          label="Type"
          select={!closedOn && isEditable}
          color="secondary"
          fullWidth={true}
          value={selectedType}
          SelectProps={{
            native: true
          }}
          disabled={!!closedOn}
          onChange={onChangeType}
        >
          {AnnotationType.options.map(item => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </TextField>
      </Grid>
      <Grid item={true} xs={6.5}>
        <TextField
          inputRef={inputRef}
          id="text-input"
          type="text"
          label="Text"
          color="secondary"
          multiline={true}
          fullWidth={true}
          margin="dense"
          variant="outlined"
          defaultValue={text}
          placeholder={placeholder}
          disabled={!!closedOn}
          onClick={onClick}
          onChange={onChangeText}
          onKeyDown={onKeyDown}
        />
      </Grid>
      <Grid item={true} xs={2}>
        <TextField
          id="annotation-priority"
          label={closedOn ? "Closed On" : "Priority"}
          select={!closedOn && isEditable}
          color="secondary"
          fullWidth={true}
          value={closedOn ? formatDate(closedOn) : selectedPriority}
          SelectProps={{
            native: true
          }}
          disabled={!!closedOn}
          onChange={onChangePriority}
        >
          {AnnotationPriority.options.map(item => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
}
