/**
 * Find all elements related by the specified relationship type.
 * @param relationshipType The relationship type to search for.
 * @returns An array of element IDs related by the specified relationship type.
 */
export default function findElementsRelatedBy(elements, relationshipType) {
    const result = [];
    for (const element of elements) {
        for (const relationship of element.relationships) {
            if (relationship.type === relationshipType) {
                if (!result.includes(element.id)) {
                    result.push(element.id);
                }
                if (!result.includes(relationship.targetId)) {
                    result.push(relationship.targetId);
                }
            }
        }
    }
    return result;
}
