import { utils as xlsx } from "xlsx";
import { AnnotationType } from "../schema";
import copyModel from "../utils/copyModel";
import { createDefaultSheetSelection } from "./xlsxutils/SheetSelection";
import createAnnotationsSheet from "./xlsxutils/createAnnotationsSheet";
import createInterfacesSheet from "./xlsxutils/createInterfacesSheet";
import createRequirementsSheet from "./xlsxutils/createRequirementsSheet";
import createRoadmapSheet from "./xlsxutils/createRoadmapSheet";
import createStructureSheet from "./xlsxutils/createStructureSheet";
/**
 * Export to xlsx format
 * @param model The model to be exported.
 * @param sheetSelection The sheets to be exported. If omitted or empty, all sheets are exported.
 * @param title The title of the workbook. If omitted or empty, the model name is used instead.
 * @returns An xlsx workbook and an array of error strings.
 */
export default function exportToXlsx(model, sheetSelection = [], title = "") {
    const workbook = xlsx.book_new();
    if (!workbook.Props)
        workbook.Props = {};
    workbook.Props.Title = title || model.name;
    const copy = copyModel(model);
    const errors = [];
    if (sheetSelection.length === 0)
        sheetSelection = createDefaultSheetSelection();
    for (const [sheetName, sheetType] of sheetSelection) {
        let worksheetName = sheetName || sheetType;
        let worksheet;
        switch (sheetType) {
            case "Interfaces":
                worksheet = createInterfacesSheet(copy);
                break;
            case "Requirements":
                worksheet = createRequirementsSheet(copy);
                break;
            case "Roadmap":
                worksheet = createRoadmapSheet(copy);
                break;
            case "Structure":
                worksheet = createStructureSheet(copy);
                break;
            default:
                if (AnnotationType.options.includes(sheetType)) {
                    if (!sheetName)
                        worksheetName =
                            sheetType === "Opportunity" ? "Opportunities" : `${sheetType}s`; // plural sheet name for annotation sheets
                    worksheet = createAnnotationsSheet(copy, sheetType);
                    break;
                }
                errors.push(`Failed to create sheet ${sheetName || sheetType} in workbook. Sheet type ${sheetType} does not exist.`);
        }
        if (worksheet)
            xlsx.book_append_sheet(workbook, worksheet, worksheetName);
    }
    return [workbook, errors];
}
