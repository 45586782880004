import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { MilestoneStatus } from "@se-toolkit/model-js/schema";
import { formatVariableName } from "@se-toolkit/model-js/utils";
import React from "react";
import calculateMilestoneStatusColours from "../utils/calculateMilestoneStatusColours";

export default function useRenderMilestoneStatus() {
  const theme = useTheme();

  const borderRadius = 4;
  const height = 20;

  return React.useCallback(
    (params: GridRenderCellParams<any, string>) => {
      if (!MilestoneStatus.safeParse(params.value).success) return null;
      const status: MilestoneStatus = params.value as MilestoneStatus;
      const { leftFill, rightFill } = calculateMilestoneStatusColours(status);
      const isInProgress = status === "InProgress";

      const normalStyle = {
        backgroundColor: rightFill,
        borderRadius,
        height,
        color: "white"
      };

      const inProgressStyle = {
        backgroundColor: "none",
        borderColor: leftFill,
        borderRadius,
        borderWidth: 2,
        height,
        color: theme.palette.mode === "dark" ? "white" : "black"
      };

      const style = isInProgress ? inProgressStyle : normalStyle;

      return (
        <Chip
          sx={style}
          label={formatVariableName(status)}
          size="small"
          variant={isInProgress ? "outlined" : "filled"}
        />
      );
    },
    [theme.palette.mode]
  );
}
