import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import { TreeView } from "@mui/x-tree-view/TreeView";
import {
  determineElementHierarchy,
  HierarchyNode
} from "@se-toolkit/model-js/analysis";
import {
  AnyElement,
  RelationshipTarget,
  RelationshipType
} from "@se-toolkit/model-js/schema";
import {
  findAncestors,
  findDescendants,
  findElement
} from "@se-toolkit/model-js/search";
import React from "react";
import SearchInput from "../../components/SearchInput";
import { getEventTargetData } from "../../interactions";
import { useDynamicConfig } from "../../utils/ConfigContextProvider";
import { StyledTreeItem } from "./StyledTreeItem";
import { LeafIcon, MinusSquare, PlusSquare } from "./TreeViewIcons";

export interface Props {
  viewName: string;
  elements: AnyElement[];
  relationshipType: RelationshipType;
  selectedId?: string;
  setSelectedId?: (id: string) => void;
}

export default function ElementTreeView({
  viewName,
  elements,
  relationshipType,
  selectedId = "",
  setSelectedId = () => {}
}: Props) {
  const theme = useTheme();

  const hierarchy = React.useMemo(
    () =>
      determineElementHierarchy({
        elements,
        relationshipType
      }),
    [elements, relationshipType]
  );

  const rootNodes = React.useMemo(
    () => hierarchy.filter(n => n.parentIds.length === 0),
    [hierarchy]
  );

  const [expandedIds, setExpandedIds] = useDynamicConfig<string[]>(
    `${viewName}.tree.expandedIds`,
    rootNodes.map(n => n.id)
  );

  const searchOptions = React.useMemo(
    () =>
      hierarchy.map(n => ({
        id: n.id,
        label: `${
          "name" in n.element.attributes ? n.element.attributes.name : ""
        } (${n.id})`
      })),
    [hierarchy]
  );

  const calculateExpanded = React.useCallback(
    (ids: string[], mode: "expand" | "collapse"): string[] => {
      if (!ids.length) return expandedIds;
      switch (mode) {
        case "collapse":
          return expandedIds.filter(n => !ids.includes(n));
        case "expand":
          let newExpanded: string[] = [...expandedIds];
          ids.forEach(id => {
            if (!expandedIds.includes(id)) {
              newExpanded.push(id);
            }
          });
          return newExpanded;
      }
    },
    [expandedIds]
  );

  React.useEffect(() => {
    if (expandedIds.includes(selectedId)) return;
    const ancestors = findAncestors(hierarchy, selectedId).map(n => n.id);
    const newExpanded = calculateExpanded(ancestors, "expand");
    setExpandedIds([...newExpanded, selectedId]);
  }, [calculateExpanded, expandedIds, hierarchy, selectedId, setExpandedIds]);

  const toggleMode = React.useRef<"expand" | "collapse" | null>(null);
  const clickTimeout = React.useRef<number | undefined>();
  const timeoutCallback = React.useCallback(() => {
    toggleMode.current = null;
    window.clearTimeout(clickTimeout.current);
    clickTimeout.current = undefined;
  }, []);

  const onNodeToggle = React.useCallback(
    (e: React.MouseEvent, nodeIds: string[]) => {
      if (!clickTimeout.current) {
        // normal case of single click
        toggleMode.current = nodeIds.length
          ? expandedIds.includes(nodeIds[0])
            ? "collapse"
            : "expand"
          : "collapse";
        clickTimeout.current = window.setTimeout(timeoutCallback, 250);
        setExpandedIds(nodeIds);
      } else {
        // special case of double click
        const toggledNode = getEventTargetData(e.currentTarget, "id");
        if (!toggledNode || !toggleMode.current) return;
        const descendants = findDescendants(hierarchy, toggledNode).map(
          n => n.id
        );
        const newExpanded = calculateExpanded(descendants, toggleMode.current);
        setExpandedIds([...newExpanded]);
      }
    },
    [calculateExpanded, expandedIds, hierarchy, setExpandedIds, timeoutCallback]
  );

  const onNodeSelect = React.useCallback(
    (e: React.MouseEvent, nodeId: string) => {
      setSelectedId(nodeId);
    },
    [setSelectedId]
  );

  React.useLayoutEffect(() => {
    if (selectedId) {
      const el = document.querySelector(`li[id$=":-${selectedId}"]`);
      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  }, [selectedId]);

  const handleExpandAll = React.useCallback(() => {
    setExpandedIds(hierarchy.map(n => n.id));
  }, [hierarchy, setExpandedIds]);

  const treeItemChildren = React.useCallback(
    (relationships: RelationshipTarget[]) => {
      const nodes: HierarchyNode[] = [];
      relationships.forEach(r => {
        const node = findElement(hierarchy, r.targetId);
        if (node) {
          nodes.push(node);
        }
      });
      return nodes.map(node => (
        <StyledTreeItem
          key={node.id}
          nodeId={node.id}
          label={
            "name" in node.element.attributes
              ? node.element.attributes.name
              : ""
          }
          sx={{
            color: node.relationships?.length
              ? theme.palette.text.primary
              : theme.palette.text.secondary
          }}
        >
          {treeItemChildren(node.relationships)}
        </StyledTreeItem>
      ));
    },
    [hierarchy, theme.palette.text.primary, theme.palette.text.secondary]
  );

  const treeItems = React.useMemo(() => {
    return rootNodes.map(node => (
      <StyledTreeItem
        key={node.id}
        nodeId={node.id}
        label={
          "name" in node.element.attributes ? node.element.attributes.name : ""
        }
      >
        {treeItemChildren(node.relationships)}
      </StyledTreeItem>
    ));
  }, [rootNodes, treeItemChildren]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minWidth: "10%",
        maxWidth: "25%"
      }}
    >
      <Toolbar
        sx={{
          padding: theme.spacing(0.5)
        }}
        disableGutters
        variant="dense"
      >
        <Tooltip title="Clear root" disableFocusListener={true}>
          <IconButton
            sx={{ color: theme.palette.text.secondary }}
            aria-label="clear-root"
            onClick={() => setSelectedId("")}
          >
            <HighlightOffIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Expand all" disableFocusListener={true}>
          <IconButton
            sx={{ color: theme.palette.text.secondary }}
            aria-label="Expand all"
            onClick={handleExpandAll}
          >
            <OpenInFullIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Collapse all" disableFocusListener={true}>
          <IconButton
            sx={{ color: theme.palette.text.secondary }}
            aria-label="Collapse all"
            onClick={() => setExpandedIds([])}
          >
            <CloseFullscreenIcon />
          </IconButton>
        </Tooltip>
        <SearchInput
          sx={{
            marginLeft: 1,
            width: 200
          }}
          options={searchOptions}
          selectOption={setSelectedId}
        />
      </Toolbar>
      <Divider />
      <TreeView
        sx={{
          flexGrow: 1,
          overflowX: "hidden",
          overflowY: "auto",
          paddingTop: 0.5
        }}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        defaultEndIcon={<LeafIcon />}
        selected={selectedId}
        expanded={expandedIds}
        onNodeSelect={onNodeSelect}
        onNodeToggle={onNodeToggle}
      >
        {treeItems}
      </TreeView>
    </Box>
  );
}
