import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { saveBlobToFile } from "../../../utils/saveToFile";
import StringInputDialog from "../../components/StringInputDialog";
import createSvgString from "../../utils/createSvgString";

const BORDER = 8;

export interface Props {
  svgRef: React.RefObject<SVGSVGElement>;
  initialFileName?: string;
  disabled?: boolean;
}

export default function SaveAsImageButton({
  svgRef,
  initialFileName = "",
  disabled = false
}: Props) {
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitFileName = (fileName: string) => {
    setOpen(false);
    saveAsImage(svgRef, fileName);
  };

  return (
    <>
      <Tooltip
        title="Save diagram as PNG image"
        aria-label="Save diagram as PNG image"
        placement="bottom"
        disableFocusListener={true}
      >
        <span>
          <Button
            sx={{
              border: `1px solid ${
                theme.palette.mode === "dark"
                  ? "rgba(255, 255, 255, 0.23)"
                  : "rgba(0, 0, 0, 0.23)"
              }`,
              maxHeight: "32px",
              padding: "0px 10px",
              textTransform: "none"
            }}
            color="inherit"
            aria-label="png"
            size="medium"
            variant="outlined"
            disabled={disabled}
            onClick={handleClick}
          >
            <SaveIcon />
            <Typography variant="h6" align="center" color="inherit">
              .png
            </Typography>
          </Button>
        </span>
      </Tooltip>
      <StringInputDialog
        title="Save diagram as PNG image"
        label="File name"
        initialValue={initialFileName}
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmitFileName}
      />
    </>
  );
}

function saveAsImage(svgRef: React.RefObject<SVGSVGElement>, fileName: string) {
  if (!svgRef || !svgRef.current) {
    return;
  }

  const boundingBox = svgRef.current.getBBox();
  const width = boundingBox.width + 2 * BORDER;
  const height = boundingBox.height + 2 * BORDER;

  const svgString = createSvgString(svgRef.current, BORDER);

  // Convert SVG string to data URL
  const imgsrc =
    "data:image/svg+xml;base64," +
    btoa(unescape(encodeURIComponent(svgString)));

  let canvas = document.createElement("canvas");
  let context = canvas.getContext("2d");

  canvas.width = width;
  canvas.height = height;

  if (context) {
    context.fillStyle = "white";
    context.fillRect(0, 0, canvas.width, canvas.height);
  }

  let image = new Image();
  image.onload = function () {
    if (!context) {
      return;
    }

    context.drawImage(image, 0, 0, width, height);

    canvas.toBlob((blob: Blob) => {
      saveBlobToFile(blob, `${fileName}.png`);
    }, "image/png");
  };

  image.src = imgsrc;
}
