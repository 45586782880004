import { Position } from "./Position";

export default function getPointerPosition(
  e: PointerEvent,
  relativeTo?: HTMLElement | SVGElement
): Position {
  if (relativeTo) {
    const r = relativeTo.getBoundingClientRect();
    return {
      x: e.clientX - r.left,
      y: e.clientY - r.top
    };
  }
  if (
    e.currentTarget instanceof HTMLElement ||
    e.currentTarget instanceof SVGElement
  ) {
    const r = e.currentTarget.getBoundingClientRect();
    return {
      x: e.clientX - r.left,
      y: e.clientY - r.top
    };
  }
  return {
    x: e.clientX,
    y: e.clientY
  };
}
