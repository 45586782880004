import React from "react";
import { Position } from "./Position";
import useElementEventListener from "./useElementEventListener";
import usePointerPinch from "./usePointerPinch";

export default function useZoom(
  svgRef: React.RefObject<SVGSVGElement>,
  options?: {
    disableX?: boolean;
    disableY?: boolean;
    multiplier?: number;
  }
) {
  const disableX = options?.disableX || false;
  const disableY = options?.disableY || false;
  const multiplier = options?.multiplier || 1.0;

  let x = React.useRef(0);
  let y = React.useRef(0);
  let w = React.useRef(0);
  let h = React.useRef(0);

  const getCurrentViewBox = React.useCallback(() => {
    x.current = svgRef.current?.viewBox.baseVal.x || 0;
    y.current = svgRef.current?.viewBox.baseVal.y || 0;
    w.current = svgRef.current?.viewBox.baseVal.width || 0;
    h.current = svgRef.current?.viewBox.baseVal.height || 0;
  }, [svgRef]);

  const handleZoom = React.useCallback(
    (dx: number, dy: number) => {
      if (!svgRef.current) {
        return;
      }

      const scale =
        svgRef.current.viewBox.baseVal.width /
        svgRef.current.width.baseVal.value;

      const zoomWidth = w.current - dx * scale * multiplier;
      const zoomHeight = h.current - dy * scale * multiplier;

      const vb = [
        disableX
          ? x.current
          : zoomWidth > 0
          ? x.current - (zoomWidth - w.current) / 2
          : x.current,
        disableY
          ? y.current
          : zoomHeight > 0
          ? y.current - (zoomHeight - h.current) / 2
          : y.current,
        disableX ? w.current : zoomWidth > 0 ? zoomWidth : w.current,
        disableY ? h.current : zoomHeight > 0 ? zoomHeight : h.current
      ].toString();

      svgRef.current.setAttribute("viewBox", vb);
    },
    [disableX, disableY, multiplier, svgRef]
  );

  const wheelCallback = React.useCallback(
    (e: WheelEvent) => {
      e.preventDefault();
      getCurrentViewBox();
      handleZoom(-e.deltaY, -e.deltaY);
    },
    [getCurrentViewBox, handleZoom]
  );

  useElementEventListener(svgRef, "wheel", wheelCallback);

  const pinchCallback = React.useCallback(
    ({ x: dx, y: dy }: Position) => {
      handleZoom(dx, dy);
    },
    [handleZoom]
  );

  usePointerPinch({
    eventListenerRef: svgRef,
    startCallback: getCurrentViewBox,
    pinchCallback
  });
}
