export interface Position {
  x: number;
  y: number;
}

export const addPositions = (p1: Position, p2: Position): Position => {
  return {
    x: p1.x + p2.x,
    y: p1.y + p2.y
  };
};

export const subtractPositions = (p1: Position, p2: Position): Position => {
  return {
    x: p1.x - p2.x,
    y: p1.y - p2.y
  };
};
