import { isBidirectionalRelationshipType } from "../schema";
import findElementInModel from "../search/findElementInModel";
/**
 * Determine whether a relationship is circular or not
 * @param model The model.
 * @param relationship The relationship to be checked.
 * @returns true if the relationship is circular or false if it is not
 */
export default function isCircularRelationship(model, relationship) {
    const { sourceId, targetId, type } = relationship;
    if (isBidirectionalRelationshipType(type)) {
        return false;
    }
    const followChain = (id) => {
        const element = findElementInModel(model, id);
        if (!element)
            return false;
        for (const r of element.relationships) {
            if (r.type === type) {
                if (r.targetId === sourceId || followChain(r.targetId))
                    return true;
            }
        }
        return false;
    };
    return followChain(targetId);
}
