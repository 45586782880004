/**
 * Find an element's parents for the specified relationship type.
 * @param elements An array of alphabetically sorted elements.
 * @param id The id of the element for which to find the parents.
 * @param relationshipType The relationship type defining the parents.
 * @returns An array of element references.
 */
export default function findElementParents(elements, id, relationshipType) {
    if (!id)
        return [];
    return elements.filter(element => !!element.relationships.find(relationship => relationship.targetId === id && relationship.type === relationshipType));
}
