import getAllElements from "../schemautils/getAllElements";
import formatDate from "../utils/formatDate";
import getTimeStamp from "../utils/getTimeStamp";
/**
 * Purge the change logs.
 * @param model The model.
 * @param options An options object.
 */
export default function purgeChangeLogs(model, options) {
    const { preserveLastEntries, preserveAfterDate } = options || {};
    const elements = getAllElements(model);
    if (!preserveLastEntries && !preserveAfterDate) {
        model.changeLog = [
            {
                time: getTimeStamp(),
                operation: "update",
                description: "Purged the change logs of all entries."
            }
        ];
        elements.forEach(e => (e.changeLog = []));
    }
    if (preserveLastEntries) {
        model.changeLog = model.changeLog.slice(-preserveLastEntries);
        elements.forEach(e => (e.changeLog = e.changeLog.slice(-preserveLastEntries)));
        model.changeLog.push({
            time: getTimeStamp(),
            operation: "update",
            description: `Purged the change logs of all but the last ${preserveLastEntries} ${preserveLastEntries > 1 ? "entries" : "entry"}.`
        });
    }
    if (preserveAfterDate) {
        model.changeLog = model.changeLog.filter(c => c.time >= preserveAfterDate);
        elements.forEach(e => (e.changeLog = e.changeLog.filter(c => c.time >= preserveAfterDate)));
        model.changeLog.push({
            time: getTimeStamp(),
            operation: "update",
            description: `Purged change log entries older than ${formatDate(preserveAfterDate, { andTime: true })}.`
        });
    }
}
