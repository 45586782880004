import { Layout } from "../Layout";

export default function mirrorLayoutHorizontally(layout: Layout) {
  layout.shapes.forEach(shape => {
    shape.position.x = layout.width - shape.position.x;
  });

  /*
   * The connectors are not mirrored geometrically.
   * Instead, only the direction of the connection is mirrored
   * so that the arrow can be placed at the correct end.
   */
  layout.connectors.forEach(connector => {
    const p1 = { ...connector.p1 };
    const p2 = { ...connector.p2 };
    connector.p1.x = layout.width - p2.x;
    connector.p2.x = layout.width - p1.x;
    connector.p1.y = p2.y;
    connector.p2.y = p1.y;
  });
}
