import {
  importFromXlsx,
  importModelFromJsonString
} from "@se-toolkit/model-js/import-export";
import { Model } from "@se-toolkit/model-js/schema";
import { handleError } from "@se-toolkit/model-js/utils";
import * as React from "react";
import { useSelector } from "react-redux";
import { read } from "xlsx";
import {
  applyModification,
  enqueueSnackbar,
  selectModel,
  useAppDispatch
} from "../../features";

export default function useFileDropHandler() {
  const dispatch = useAppDispatch();

  const model: Model = useSelector(selectModel);

  const fileRef = React.useRef<File | null>(null);

  const handleFileInput = React.useCallback(
    (file: File) => {
      const reader = new FileReader();
      const isXlsx = file.name.endsWith(".xlsx");
      reader.onload = isXlsx
        ? () => {
            try {
              const workbook = read(reader.result as ArrayBuffer);
              const [mIs, errors] = importFromXlsx(model, workbook);
              for (const mI of mIs) {
                dispatch(applyModification(mI));
              }
              if (mIs.length > 0) {
                dispatch(
                  enqueueSnackbar(
                    `Succesfully imported ${mIs.length} worksheet${
                      mIs.length > 1 ? "s" : ""
                    } from ${file.name}`,
                    { variant: "success" }
                  )
                );
              } else {
                dispatch(
                  enqueueSnackbar(
                    `No worksheets were imported from ${file.name}. No differences were detected.`,
                    { variant: "info" }
                  )
                );
              }
              for (const error of errors) {
                dispatch(enqueueSnackbar(error, { variant: "error" }));
              }
            } catch (e: any) {
              dispatch(
                enqueueSnackbar(handleError(e, "parse"), { variant: "error" })
              );
            }
          }
        : () => {
            try {
              const model = importModelFromJsonString(reader.result);
              window.dispatchEvent(
                new CustomEvent("importjson", { detail: model })
              );
            } catch (e: any) {
              dispatch(
                enqueueSnackbar(handleError(e, "parse"), { variant: "error" })
              );
            }
          };
      isXlsx ? reader.readAsArrayBuffer(file) : reader.readAsText(file);
    },
    [dispatch, model]
  );

  const onDragOver = React.useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
    },
    []
  );

  const onDrop = React.useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();

      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        // Use DataTransferItemList interface to access the file(s)
        // If dropped items aren't files, reject them
        // Only handle the first file
        const item = e.dataTransfer.items[0];
        if (item.kind === "file") {
          fileRef.current = item.getAsFile();
        }
      } else {
        // Use DataTransfer interface to access the file(s)
        fileRef.current = e.dataTransfer.files[0];
      }

      if (fileRef.current) {
        handleFileInput(fileRef.current);
      }
    },
    [handleFileInput]
  );

  return { onDragOver, onDrop };
}
