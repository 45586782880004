import { createAsyncThunk } from "@reduxjs/toolkit";
import { Model } from "@se-toolkit/model-js/schema";
import { handleError } from "@se-toolkit/model-js/utils";
import { validateModel } from "@se-toolkit/model-js/validators";
import { enqueueSnackbar } from "../notifications/notificationsSlice";

export const fetchModel = createAsyncThunk<Model, string>(
  "model/fetch",
  async (url: string, { dispatch, rejectWithValue }) => {
    const response = await fetch(url);
    const data = await response.json();
    try {
      validateModel(data);
    } catch (e: any) {
      dispatch(
        enqueueSnackbar(handleError(e, "parse"), { variant: "warning" })
      );
      return rejectWithValue(e);
    }
    return data as Model;
  }
);
