import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { diffAttributes } from "@se-toolkit/model-js/comparators";
import {
  Component,
  ComponentType,
  RelationshipType
} from "@se-toolkit/model-js/schema";
import React from "react";
import {
  createElement,
  enqueueSnackbar,
  updateElement,
  useAppDispatch
} from "../../features";
import StringInputDialog from "../components/StringInputDialog";
import { useWindowEventListener } from "../interactions";

type Mode = "create" | "update";

export interface Props {
  selectedComponents?: Component[];
  relationshipType?: RelationshipType;
}

export default function ComponentsDialog({
  selectedComponents = [],
  relationshipType
}: Props) {
  const dispatch = useAppDispatch();

  const [mode, setMode] = React.useState<Mode>("create");

  const hasSelection = selectedComponents.length > 0;
  const initialValue = selectedComponents
    .map(c => c.attributes.name)
    ?.join(", ");
  const initialType: ComponentType | undefined = hasSelection
    ? selectedComponents.length === 1 || mode === "create"
      ? selectedComponents[0].attributes.type
      : undefined
    : "System";

  const [type, setType] = React.useState(initialType);
  const [isChecked, setIsChecked] = React.useState(hasSelection);

  const [open, setOpen] = React.useState(false);
  const handleOpen = React.useCallback(
    (mode: Mode) => {
      setMode(mode);
      setType(initialType);
      setIsChecked(hasSelection);
      setOpen(true);
    },
    [hasSelection, initialType]
  );
  useWindowEventListener(
    `createelements`,
    React.useCallback(() => handleOpen("create"), [handleOpen])
  );
  useWindowEventListener(
    `updateelements`,
    React.useCallback(() => {
      if (!hasSelection) {
        dispatch(
          enqueueSnackbar(`Please select one or more components to update`, {
            variant: "info"
          })
        );
        return;
      }
      handleOpen("update");
    }, [dispatch, handleOpen, hasSelection])
  );
  const handleClose = React.useCallback(() => setOpen(false), []);

  const onChangeType = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setType(e.currentTarget.value as ComponentType);
    },
    []
  );

  const onChangeCheckbox = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsChecked(e.currentTarget.checked);
    },
    []
  );

  const handleSubmit = React.useCallback(
    (value: string) => {
      setOpen(false);
      const names = value.split(",").map(n => n.trim());
      switch (mode) {
        case "create":
          names.forEach(name => {
            dispatch(
              createElement({
                metatype: "Component",
                attributes: {
                  name,
                  type: type || "System"
                },
                relateToSourceId: isChecked
                  ? selectedComponents[0].id
                  : undefined,
                relationshipType
              })
            );
          });
          break;
        case "update": {
          if (names.length < selectedComponents.length) {
            dispatch(
              enqueueSnackbar(
                `Please provide a name for each selected component`,
                {
                  variant: "error"
                }
              )
            );
            return;
          }
          selectedComponents.forEach((c, index) => {
            const diff = diffAttributes(c.attributes, {
              ...c.attributes,
              name: names[index],
              type: type || c.attributes.type
            });
            if (!diff) return;
            dispatch(
              updateElement({
                id: c.id,
                attributes: diff
              })
            );
          });
        }
      }
    },
    [dispatch, isChecked, mode, relationshipType, selectedComponents, type]
  );

  const typeOptions = React.useMemo(() => {
    const options: React.ReactNode[] = [];
    if (mode === "update" && selectedComponents.length > 1) {
      options.push(
        <option key="undefined" value="undefined">
          ...
        </option>
      );
    }
    ComponentType.options.forEach(item =>
      options.push(
        <option key={item} value={item}>
          {item}
        </option>
      )
    );
    return options;
  }, [mode, selectedComponents.length]);

  const isRelatedCheckBox = React.useMemo(() => {
    if (mode === "update" || !hasSelection || !relationshipType) {
      return null;
    }

    return (
      <FormControlLabel
        control={<Checkbox checked={isChecked} onChange={onChangeCheckbox} />}
        label={`Relate to ${selectedComponents[0].attributes.name}`}
      />
    );
  }, [
    hasSelection,
    isChecked,
    mode,
    onChangeCheckbox,
    relationshipType,
    selectedComponents
  ]);

  return (
    <StringInputDialog
      title={`${mode.charAt(0).toUpperCase() + mode.slice(1)} Components`}
      label="Component names"
      submitLabel={mode}
      initialValue={mode === "create" ? "" : initialValue}
      open={open}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
    >
      <Box sx={{ paddingTop: 2 }}>
        <TextField
          id="type-input"
          select={true}
          label={"Type"}
          fullWidth={true}
          value={type}
          SelectProps={{
            native: true
          }}
          onChange={onChangeType}
        >
          {typeOptions}
        </TextField>
      </Box>
      {isRelatedCheckBox}
    </StringInputDialog>
  );
}
