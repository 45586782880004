import { AnnotationAttributes, ComponentAttributes, MilestoneAttributes, RequirementAttributes, StakeholderAttributes } from "../schema";
export default function parseElementAttributes(metatype, attributes, partial) {
    const isPartial = partial === "partial";
    let schema;
    switch (metatype) {
        case "Annotation":
            schema = isPartial
                ? AnnotationAttributes.partial()
                : AnnotationAttributes;
            break;
        case "Component":
            schema = isPartial ? ComponentAttributes.partial() : ComponentAttributes;
            break;
        case "Milestone":
            schema = isPartial ? MilestoneAttributes.partial() : MilestoneAttributes;
            break;
        case "Requirement":
            schema = isPartial
                ? RequirementAttributes.partial()
                : RequirementAttributes;
            break;
        case "Stakeholder":
            schema = isPartial
                ? StakeholderAttributes.partial()
                : StakeholderAttributes;
            break;
        default:
            throw new Error(`Cannot validate an element with metatype: ${metatype}.`);
    }
    return schema.parse(attributes);
}
