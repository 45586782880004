import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import React from "react";

export interface Props {
  value: number;
  leadingText?: string;
  trailingText?: string;
  handleChange: (value: number) => void;
}

export default function TextWithInlineDateInput({
  value,
  leadingText,
  trailingText,
  handleChange
}: Props) {
  const [dateValue, setDateValue] = React.useState<Dayjs | null>(
    dayjs(value * 1000)
  );

  const onChange = React.useCallback(
    (newValue: Dayjs | null) => {
      if (newValue) {
        setDateValue(newValue);
        handleChange(newValue.valueOf() / 1000);
      }
    },
    [handleChange]
  );

  return (
    <Stack direction="row" spacing={1}>
      <Typography alignSelf="center">{leadingText}</Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          value={dateValue}
          onChange={onChange}
          ampm={false}
          format="YYYY-MM-DD HH:mm"
        />
      </LocalizationProvider>
      <Typography alignSelf="center">{trailingText}</Typography>
    </Stack>
  );
}
