import { PaletteMode } from "@mui/material";
import {
  blue,
  blueGrey,
  brown,
  cyan,
  deepPurple,
  green,
  grey,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow
} from "@mui/material/colors";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const SEToolkitColours = {
  Black: blueGrey[900] as string,
  Blue: blue[900] as string,
  LightBrown: brown[500] as string,
  Brown: brown[900] as string,
  Cyan: cyan[900] as string,
  Green: green[900] as string,
  Grey: grey[700] as string,
  DarkGrey: grey[800] as string,
  Lime: lime[900] as string,
  Orange: orange[700] as string,
  Pink: pink[900] as string,
  Purple: purple[900] as string,
  DeepPurple: deepPurple[900] as string,
  Red: red[900] as string,
  White: "#F9F9F9",
  Yellow: yellow[500] as string
};

export const getColourAtIndex = (index: number): string => {
  if (index < 0) return SEToolkitColours.Blue;

  const colourKeys = Object.keys(SEToolkitColours);
  if (index > colourKeys.length - 1) {
    index = index % colourKeys.length;
  }

  return SEToolkitColours[colourKeys[index] as keyof typeof SEToolkitColours];
};

export const SETColours = {
  annotationPriority: {
    Low: SEToolkitColours.Lime,
    Medium: SEToolkitColours.Orange,
    High: SEToolkitColours.Red
  },
  component: {
    Specified: SEToolkitColours.Green,
    UnspecifiedLeaf: SEToolkitColours.Red,
    UnspecifiedNonLeaf: SEToolkitColours.Orange
  },
  componentType: {
    Data: SEToolkitColours.Lime,
    Facility: SEToolkitColours.Brown,
    Hardware: SEToolkitColours.Red,
    Organization: SEToolkitColours.DeepPurple,
    Person: SEToolkitColours.Orange,
    Product: SEToolkitColours.Cyan,
    Project: SEToolkitColours.Purple,
    Service: SEToolkitColours.Pink,
    Software: SEToolkitColours.Green,
    System: SEToolkitColours.Blue
  },
  metatype: {
    Annotation: brown[500] as string,
    Component: blueGrey[800] as string,
    Milestone: grey.A700 as string,
    Requirement: brown[900] as string,
    Stakeholder: blueGrey[600] as string
  },
  milestoneStatus: {
    Black: SEToolkitColours.Black,
    Blue: SEToolkitColours.Blue,
    Grey: SEToolkitColours.Grey,
    Green: SEToolkitColours.Green,
    Red: SEToolkitColours.Red,
    Orange: SEToolkitColours.Orange
  },
  requirement: {
    Allocated: SEToolkitColours.Green,
    NotAllocated: SEToolkitColours.Red,
    NotOwned: SEToolkitColours.DarkGrey
  },
  requirementType: {
    CDRL: SEToolkitColours.LightBrown,
    Design: SEToolkitColours.Lime,
    Environmental: SEToolkitColours.Brown,
    ExternalInterface: SEToolkitColours.Red,
    Functional: SEToolkitColours.DeepPurple,
    NonSystem: SEToolkitColours.DarkGrey,
    OtherQualities: SEToolkitColours.Orange,
    Performance: SEToolkitColours.Cyan,
    Physical: SEToolkitColours.Purple,
    Resource: SEToolkitColours.Pink,
    "States/Modes": SEToolkitColours.Green
  },
  requirementVerificationMethod: {
    Analysis: SEToolkitColours.Lime,
    Demonstration: SEToolkitColours.DeepPurple,
    Inspection: SEToolkitColours.Purple,
    Test: SEToolkitColours.Cyan
  }
};

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    connector: {
      hover: string;
      stroke: string;
    };
    shape: {
      fill: string;
      selected: string;
      stroke: string;
    };
  }
  interface PaletteOptions {
    connector: {
      hover: string;
      stroke: string;
    };
    shape: {
      fill: string;
      selected: string;
      stroke: string;
    };
  }
  interface TypeBackground {
    card: string;
    inner: string;
    popover: string;
  }
}

export default function createSETTheme(mode?: PaletteMode) {
  return responsiveFontSizes(
    createTheme({
      components: {
        MuiTab: {
          styleOverrides: {
            root: {
              flexDirection: "row"
            }
          }
        }
      },
      palette: {
        action: {
          focus: "#14a37f", // secondary.dark
          selected: "#3d6bb3" // primary.light
        },
        background: {
          card: mode === "dark" ? grey[900] : grey[100],
          inner: mode === "dark" ? grey[200] : grey[800],
          popover: mode === "dark" ? blueGrey[900] : blueGrey[100]
        },
        connector: {
          hover: mode === "dark" ? grey[300] : grey[700],
          stroke: mode === "dark" ? grey[300] : grey[700]
        },
        primary: {
          main: blue[900]
        },
        secondary: {
          main: teal.A400
        },
        shape: {
          fill: mode === "dark" ? grey[800] : grey[200],
          selected: mode === "dark" ? teal.A400 : blue[900],
          stroke: mode === "dark" ? grey[300] : grey[700]
        },
        mode
      }
    })
  );
}
