import { findElement, findElementParents } from "../search";
import determineElementNumber from "./determineElementNumber";
import determineRootElements from "./determineRootElements";
/**
 * Determine the element hierarchy defined by a specific relationship type.
 * @param elements An array of alphabetically sorted elements.
 * @param relationshipType The relationship type defining the hierarchy.
 * @param rootId The id of the root element of the hierarchy (default is elements with no parents).
 * @param maxDepth The maximum depth to which to traverse the hierarchy (default is infinity).
 * @returns An array of hierarchy nodes in alphabetical order.
 */
export default function determineElementHierarchy({ elements, relationshipType, rootId, maxDepth = -1 }) {
    const elementNodes = elements.map(e => ({
        id: e.id,
        relationships: e.relationships
            .filter(r => r.type === relationshipType)
            .map(r => ({ ...r })),
        parentIds: [],
        depth: 0,
        width: 0,
        number: "",
        element: e
    }));
    const rootNodes = [];
    let rootNumber = 1;
    let rootNumberString = "";
    const rootNode = rootId && findElement(elementNodes, rootId);
    if (rootNode) {
        rootNodes.push(rootNode);
        rootNode.parentIds = findElementParents(elements, rootNode.id, relationshipType).map(e => e.id);
        rootNumberString = determineElementNumber({
            elements,
            relationshipType,
            id: rootId
        });
        rootNumber = parseInt(rootNumberString.charAt(rootNumberString.length - 1));
        rootNumberString = rootNumberString.slice(0, -1);
    }
    else {
        determineRootElements(elements, relationshipType).forEach(id => {
            const node = findElement(elementNodes, id);
            if (node)
                rootNodes.push(node);
        });
    }
    let countLeaves = 0;
    const nodeNumber = [rootNumber];
    const recursor = (nodes, parentId, depth, minWidth) => {
        if (depth >= nodeNumber.length) {
            for (let i = nodeNumber.length; i < depth + 1; i++) {
                nodeNumber.push(1);
            }
        }
        nodes.forEach((node, index) => {
            if (parentId && !node.parentIds.includes(parentId)) {
                node.parentIds.push(parentId);
            }
            /*
             * Check to see whether we've already been to this node.
             * If we have and we are not coming from a branch which would increase the
             * depth of this node, then we don't need to do anything and can return.
             */
            if (node.number && node.depth >= depth) {
                nodeNumber[depth]++;
                return;
            }
            node.depth = depth;
            const childNodes = [];
            if (maxDepth && (maxDepth < 0 || depth < maxDepth)) {
                node.relationships.forEach(relationship => {
                    const child = findElement(elementNodes, relationship.targetId);
                    if (child) {
                        childNodes.push(child);
                    }
                });
            }
            if (childNodes.length) {
                recursor(childNodes, node.id, depth + 1, countLeaves);
                if (!node.number) {
                    const widthFirstChild = childNodes[0].width || 0;
                    const widthLastChild = childNodes[childNodes.length - 1].width || 0;
                    node.width = (widthFirstChild + widthLastChild) / 2;
                }
            }
            else if (!node.number) {
                node.width = countLeaves;
                countLeaves++;
            }
            minWidth = Math.max(minWidth, index > 0 ? nodes[index - 1].width + 1 : index);
            if (node.number)
                return;
            if (node.width < minWidth) {
                node.width = minWidth;
                countLeaves = Math.max(countLeaves, minWidth + 1);
            }
            node.number = rootNumberString + nodeNumber.join(".");
            nodeNumber[depth]++;
        });
        nodeNumber.splice(depth);
    };
    recursor(rootNodes, null, 0, 0);
    return elementNodes.filter(node => node.number);
}
