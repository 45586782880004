import { HierarchyNode } from "@se-toolkit/model-js/analysis";
import { RelationshipType } from "@se-toolkit/model-js/schema";
import { findElement } from "@se-toolkit/model-js/search";
import React from "react";
import { moveRelationships, useAppDispatch } from "../../features";

export default function useMoveRelationships(
  hierarchy: HierarchyNode[],
  type: RelationshipType,
  direction: "toTarget" | "toSource" = "toTarget"
) {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (sourceId: string, targetId: string) => {
      const _sourceId = direction === "toSource" ? targetId : sourceId;
      const _targetId = direction === "toSource" ? sourceId : targetId;

      const node = findElement(hierarchy, _targetId);

      const relationshipTarget = { targetId: _targetId, type };

      const relationships = node?.parentIds.length
        ? node.parentIds.map(id => ({ sourceId: id, ...relationshipTarget }))
        : [{ sourceId: "", ...relationshipTarget }];

      dispatch(
        moveRelationships({
          relationships,
          newSourceId: _sourceId
        })
      );
    },
    [direction, dispatch, hierarchy, type]
  );
}
