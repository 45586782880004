import { useLocation, useResolvedPath } from "react-router-dom";
import { useStaticConfig } from "../../utils/ConfigContextProvider";

export type TabRoute = {
  icon?: JSX.Element;
  label: string;
  path: string;
  element: JSX.Element;
};

export default function useSubTabRouting(
  viewName: string,
  tabRoutes: TabRoute[],
  defaultTab: string
) {
  const [lastActiveTab, setLastActiveTab] = useStaticConfig<string>(
    `${viewName}.lastActiveTab`,
    defaultTab
  );

  const location = useLocation();
  const resolvedPath = useResolvedPath(".").pathname;
  const basePath = resolvedPath === "/" ? "" : resolvedPath;
  const currentPath = location.pathname.replace(basePath, "");

  const currentTab =
    tabRoutes.find(tabRoute => tabRoute.path === currentPath)?.path ||
    lastActiveTab ||
    defaultTab;

  if (lastActiveTab !== currentTab) setLastActiveTab(currentTab);

  const indexPath = `${basePath}${lastActiveTab || defaultTab}`;

  return {
    basePath,
    currentTab,
    indexPath,
    setLastActiveTab
  };
}
