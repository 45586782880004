import { useTheme } from "@mui/material/styles";
import { Shape } from "../../../layouts";
import { SvgText } from "../../components/SvgText";
import { AnimatedShape } from "./AnimatedShape";
import { Interaction, Type } from "./Interaction";

export interface Props {
  shape: Shape;
  text: string;
  leftFill: string;
  rightFill: string;
  hasAnnotations?: boolean;
  hasDescription?: boolean;
  isSelected?: boolean;
  areAnnotationsFixed?: boolean;
  isDescriptionFixed?: boolean;
  handleClickLeft?: () => void;
  handleClickRight?: () => void;
  setAnnotationsAnchor?: (e: SVGRectElement | null, isFixed: boolean) => void;
  setDescriptionAnchor?: (e: SVGRectElement | null, isFixed: boolean) => void;
}

export const MapElement = ({
  shape,
  text,
  leftFill,
  rightFill,
  hasAnnotations = false,
  hasDescription = false,
  isSelected = false,
  areAnnotationsFixed = false,
  isDescriptionFixed = false,
  handleClickLeft = () => {},
  handleClickRight = () => {},
  setAnnotationsAnchor,
  setDescriptionAnchor
}: Props) => {
  const theme = useTheme();

  const radius = 4;
  const idWidth = 54;
  const idHeight = 18;
  const idXOffset = 12;
  const idYOffset = -16;
  const iconWidth = 18;
  const iconHeight = 18;
  const statusWidth = shape.width / 10;
  const padding = 2;

  return (
    <AnimatedShape shape={shape}>
      <g transform={`translate(${-shape.width / 2},${-shape.height / 2})`}>
        <rect
          data-id={shape.id}
          className="element-rect"
          width={shape.width}
          height={shape.height}
          rx={radius}
          ry={radius}
          transform={`rotate(${shape.rotation},0,0)`}
          fill={theme.palette.shape.fill}
          stroke="none"
        />
        <SvgText
          className="element-text"
          x={statusWidth / 2 + padding}
          y={padding}
          width={shape.width - 2 * padding - statusWidth}
          height={shape.height - 2 * padding}
          rotation={shape.rotation}
        >
          {text}
        </SvgText>
        <rect
          className="element-status-left"
          width={statusWidth}
          height={shape.height}
          x={-statusWidth / 2}
          y={0}
          rx={radius}
          ry={radius}
          transform={`rotate(${shape.rotation},0,0)`}
          fill={leftFill}
          stroke="none"
          onClick={handleClickLeft}
        />
        <rect
          className="element-status-right"
          width={statusWidth}
          height={shape.height}
          x={shape.width - statusWidth / 2}
          y={0}
          rx={radius}
          ry={radius}
          transform={`rotate(${shape.rotation},0,0)`}
          fill={rightFill}
          stroke="none"
          onClick={handleClickRight}
        />
        <rect
          className="exclude-from-export"
          width={shape.width}
          height={shape.height}
          rx={radius}
          ry={radius}
          transform={`rotate(${shape.rotation},0,0)`}
          fill="none"
          stroke={isSelected ? theme.palette.shape.selected : "none"}
          strokeWidth={isSelected ? 4 : ""}
          strokeOpacity={isSelected ? 1 : ""}
        />
        <g transform={`translate(${idXOffset},${idYOffset})`}>
          {/* the id is drawn relative to the top left corner of the shape */}
          <rect
            data-id={shape.id}
            className="id-rect"
            width={idWidth + 2 * padding}
            height={idHeight + 2 * padding}
            rx={radius}
            ry={radius}
            transform={`rotate(${shape.rotation},0,0)`}
            fill={theme.palette.shape.fill}
            stroke={theme.palette.grey[900]}
          />
          <SvgText
            className="id-text"
            color={theme.palette.text.secondary}
            x={padding}
            y={padding}
            width={idWidth}
            height={idHeight}
            rotation={shape.rotation}
          >
            {shape.id}
          </SvgText>
        </g>
      </g>
      {/* interaction icons are positioned relative to the bottom right corner of the shape */}
      <g
        transform={`translate(${shape.width / 2 - statusWidth / 2},${
          shape.height / 2
        })`}
      >
        <Interaction
          type={Type.Description}
          id={shape.id}
          x={-2 * iconWidth - padding}
          y={-iconHeight - padding}
          width={iconWidth}
          height={iconHeight}
          isOn={hasDescription}
          isFixed={isDescriptionFixed}
          setAnchor={setDescriptionAnchor}
        />
        <Interaction
          type={Type.Annotations}
          id={shape.id}
          x={-iconWidth - padding}
          y={-iconHeight - padding}
          width={iconWidth}
          height={iconHeight}
          isOn={hasAnnotations}
          isFixed={areAnnotationsFixed}
          setAnchor={setAnnotationsAnchor}
        />
      </g>
    </AnimatedShape>
  );
};
