import { z } from "zod";
import { AnnotationType } from "./Annotation";
import { ComponentType } from "./Component";
export const SeedTypes = z.union([
    AnnotationType,
    ComponentType,
    z.enum(["Milestone", "Requirement", "Stakeholder"])
]);
export const UidSeeds = z.object({
    // Annotation types
    Action: z.number().int().nonnegative(),
    Assumption: z.number().int().nonnegative(),
    Lesson: z.number().int().nonnegative(),
    Opportunity: z.number().int().nonnegative(),
    Risk: z.number().int().nonnegative(),
    // Component types
    Data: z.number().int().nonnegative(),
    Facility: z.number().int().nonnegative(),
    Hardware: z.number().int().nonnegative(),
    Organization: z.number().int().nonnegative(),
    Person: z.number().int().nonnegative(),
    Product: z.number().int().nonnegative(),
    Project: z.number().int().nonnegative(),
    Service: z.number().int().nonnegative(),
    Software: z.number().int().nonnegative(),
    System: z.number().int().nonnegative(),
    // Other types
    Milestone: z.number().int().nonnegative(),
    Requirement: z.number().int().nonnegative(),
    Stakeholder: z.number().int().nonnegative()
});
