import { findElementParents } from "../search";
import determineRootElements from "./determineRootElements";
/**
 * Determine an element's number defined by a specific relationship type.
 * @param elements An array of alphabetically sorted elements.
 * @param relationshipType The relationship type defining the hierarchy.
 * @param id The id of the element for which to determine the number.
 * @returns The number of the element within the hierarchy.
 */
export default function determineElementNumber({ elements, relationshipType, id }) {
    const rootElements = determineRootElements(elements, relationshipType);
    let elementNumber = 1;
    let elementNumberString = "";
    /*
     * Calculate the number using first parent because, due to the alphabetical
     * ordering of the elements array by ID, it corresponds to the first path to
     * reach the given root node in a depth-first search.
     */
    const recursor = (_id) => {
        const parents = findElementParents(elements, _id, relationshipType);
        if (!parents.length) {
            elementNumberString = `${rootElements.indexOf(_id) + 1}`;
            return;
        }
        else {
            recursor(parents[0].id);
        }
        elementNumber =
            parents[0].relationships
                .filter(r => r.type === relationshipType)
                .findIndex(r => r.targetId === _id) + 1;
        elementNumberString += `.${elementNumber}`;
    };
    recursor(id);
    return elementNumberString;
}
