import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutline from "@mui/icons-material/RemoveCircleOutline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { RelationshipType } from "@se-toolkit/model-js/schema";
import React from "react";
import { useSelector } from "react-redux";
import {
  createElement,
  deleteRelationship,
  makeSelectModelElements,
  moveRelationships,
  useAppDispatch
} from "../../features";
import { useStopPropogation } from "../interactions";
import getEventTargetData from "../interactions/getEventTargetData";
import useAnchor from "../interactions/useAnchor";
import useElementEventListener from "../interactions/useElementEventListener";
import ColourPickerPopover from "../views/controls/ColourPickerPopover";

export interface Props {
  eventListenerRef: React.RefObject<HTMLDivElement | SVGSVGElement>;
  relationshipType: RelationshipType;
}

export default function StakeholderMenu({
  eventListenerRef,
  relationshipType
}: Props) {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const stakeholders = useSelector(makeSelectModelElements("Stakeholder"));

  const elementId = React.useRef("");
  const elementStakeholderId = React.useRef("");

  const hasStakeholder = !!elementStakeholderId.current;

  const anchor = useAnchor(eventListenerRef);

  const handleClickLabel = React.useCallback(
    (e: PointerEvent) => {
      e.preventDefault();
      const id = getEventTargetData(e.target, "id");
      const metatype = getEventTargetData(e.target, "labelmetatype");
      const stakeholderId = getEventTargetData(e.target, "labelid");
      if (id && metatype === "Stakeholder") {
        anchor.set(e.target as SVGRectElement, true);
        elementId.current = id;
        elementStakeholderId.current = stakeholderId || "";
        return;
      }
      anchor.set(null, false);
      elementId.current = "";
      elementStakeholderId.current = "";
    },
    [anchor]
  );

  useElementEventListener(eventListenerRef, "click", handleClickLabel);

  const handleClickOption = React.useCallback(
    (newStakeholderId: string) => (e: React.MouseEvent) => {
      e.preventDefault();
      anchor.set(null, false);
      if (newStakeholderId === elementStakeholderId.current) return;
      dispatch(
        moveRelationships({
          relationships: [
            {
              sourceId: elementStakeholderId.current,
              targetId: elementId.current,
              type: "responsible_for"
            }
          ],
          newSourceId: newStakeholderId
        })
      );
    },
    [anchor, dispatch]
  );

  const [newStakeholder, setNewStakeholder] = React.useState("");
  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setNewStakeholder(e.target.value);
    },
    []
  );

  const [newStakeholderColour, setNewStakeholderColour] = React.useState("");
  const handleSetColour = React.useCallback((colour: string) => {
    setNewStakeholderColour(colour);
  }, []);

  const handleSubmitStakeholder = React.useCallback(() => {
    if (!newStakeholder) return;
    if (hasStakeholder) {
      dispatch(
        deleteRelationship({
          sourceId: elementStakeholderId.current,
          targetId: elementId.current,
          type: relationshipType
        })
      );
    }
    dispatch(
      createElement({
        metatype: "Stakeholder",
        attributes: {
          name: newStakeholder,
          colour: newStakeholderColour
        },
        relateToTargetId: elementId.current,
        relationshipType: "responsible_for"
      })
    );
    setNewStakeholder("");
    setNewStakeholderColour("");
  }, [
    dispatch,
    hasStakeholder,
    newStakeholder,
    newStakeholderColour,
    relationshipType
  ]);

  const handleRemoveStakeholder = React.useCallback(() => {
    if (!hasStakeholder) return;
    dispatch(
      deleteRelationship({
        sourceId: elementStakeholderId.current,
        targetId: elementId.current,
        type: relationshipType
      })
    );
    setNewStakeholder("");
    setNewStakeholderColour("");
  }, [dispatch, hasStakeholder, relationshipType]);

  const stopPropagation = useStopPropogation();

  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      stopPropagation(e);
      if (e.key === "Enter") {
        anchor.set(null, false);
        handleSubmitStakeholder();
      }
    },
    [anchor, handleSubmitStakeholder, stopPropagation]
  );

  const onClickAdd = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      anchor.set(null, false);
      handleSubmitStakeholder();
    },
    [anchor, handleSubmitStakeholder]
  );

  const onClickRemove = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      anchor.set(null, false);
      handleRemoveStakeholder();
    },
    [anchor, handleRemoveStakeholder]
  );

  const buttons = React.useMemo(() => {
    return stakeholders
      .filter(s => s.id !== elementStakeholderId.current)
      .map(stakeholder => (
        <Button
          key={stakeholder.id}
          sx={{
            backgroundColor: stakeholder.attributes.colour,
            fontWeight: "normal",
            textTransform: "none"
          }}
          onClick={handleClickOption(stakeholder.id)}
        >
          <Typography color={theme.palette.common.white}>
            {stakeholder.attributes.name}
          </Typography>
        </Button>
      ));
  }, [handleClickOption, stakeholders, theme.palette.common.white]);

  return (
    <Popper
      open={Boolean(anchor.element)}
      anchorEl={anchor.element}
      placement="right"
      onKeyDown={onKeyDown}
      onKeyUp={stopPropagation}
    >
      <Paper
        sx={{
          backgroundColor: theme.palette.background.popover,
          display: "flex",
          flexDirection: "column",
          padding: theme.spacing(1)
        }}
      >
        {buttons}
        <Divider sx={{ marginTop: theme.spacing(0.5) }} />
        <Box sx={{ display: "flex" }}>
          <TextField
            id="new-stakeholder-input"
            label="New stakeholder"
            variant="standard"
            autoComplete="off"
            value={newStakeholder}
            onChange={onChange}
          />
          <Divider
            sx={{ height: theme.spacing(5), m: 0.5 }}
            orientation="vertical"
          />
          <ColourPickerPopover
            color={newStakeholderColour}
            handleSubmit={handleSetColour}
          />
          <Divider
            sx={{ height: theme.spacing(5), m: 0.5 }}
            orientation="vertical"
          />
          <ButtonGroup sx={{ marginTop: theme.spacing(0.5) }}>
            <Tooltip
              title="Add stakeholder and make them the element owner"
              aria-label="Add stakeholder and make them the element owner"
              placement="bottom"
              disableFocusListener={true}
            >
              <span>
                <IconButton
                  color="success"
                  aria-label="add"
                  onClick={onClickAdd}
                  disabled={!newStakeholder}
                >
                  <AddCircleOutline />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              title="Remove the element owner"
              aria-label="Remove the element owner"
              placement="bottom"
              disableFocusListener={true}
            >
              <span>
                <IconButton
                  color="warning"
                  aria-label="remove"
                  onClick={onClickRemove}
                  disabled={!hasStakeholder}
                >
                  <RemoveCircleOutline />
                </IconButton>
              </span>
            </Tooltip>
          </ButtonGroup>
        </Box>
      </Paper>
    </Popper>
  );
}
