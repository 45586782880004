import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";
import { useSelector } from "react-redux";
import {
  RootState,
  selectModel,
  updateModelName,
  useAppDispatch
} from "../../features";
import StringInputDialog from "../components/StringInputDialog";

export default function ModelName() {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { name: modelName, id: modelId } = useSelector((state: RootState) =>
    selectModel(state)
  );

  React.useEffect(() => {
    document.title = `${modelName ? modelName : "SE-Toolkit"}`;
  }, [modelName]);

  const [open, setOpen] = React.useState(false);

  const [isChecked, setIsChecked] = React.useState(true);
  const onChangeCheckbox = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsChecked(e.currentTarget.checked);
    },
    []
  );

  const onClick = React.useCallback(() => {
    setIsChecked(true);
    setOpen(true);
  }, []);

  const handleClose = React.useCallback(() => setOpen(false), []);

  const handleSubmit = React.useCallback(
    (value: string) => {
      setOpen(false);
      if (value === modelName && !isChecked) return;
      dispatch(updateModelName({ newName: value, generateNewId: isChecked }));
    },
    [dispatch, isChecked, modelName]
  );

  return (
    <>
      <Button
        sx={{
          textTransform: "none"
        }}
        color="inherit"
      >
        <Typography variant="h4" onClick={onClick}>
          {modelName}
        </Typography>
      </Button>
      <StringInputDialog
        title="Update Model Name"
        label="New model name"
        initialValue={modelName}
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      >
        <Typography
          sx={{ color: theme.palette.text.secondary }}
          variant="subtitle1"
          onClick={onClick}
        >
          {`ID: ${modelId}`}
        </Typography>
        <FormControlLabel
          control={<Checkbox checked={isChecked} onChange={onChangeCheckbox} />}
          label="Generate new model ID"
        />
      </StringInputDialog>
    </>
  );
}
