import parseSheet from "./parseSheet";
export default function parseRequirementsSheet(worksheet) {
    const columnHeaders = ["ID", "Requirement", "Type", "Verification Method"];
    const allocateAttributes = (attributes, key, value) => {
        switch (key) {
            case "Requirement":
                attributes["text"] = value;
                break;
            case "Type":
                attributes["type"] = value.replace(/\s/g, "").replace(/^Non-/g, "Non");
                break;
            case "Verification Method":
                attributes["verificationMethod"] = value;
                break;
            default:
                attributes[key.toLowerCase()] = value;
                break;
        }
    };
    try {
        return parseSheet("Requirement", worksheet, columnHeaders, allocateAttributes);
    }
    catch (error) {
        throw new Error(`Failed to parse requirements sheet. ${error.message}`);
    }
}
