import { SCHEMA_VERSION } from "../schema";
import { generateElementId, generateModelId } from "../schemautils";
import getTimeStamp from "../utils/getTimeStamp";
import sortRelationships from "../utils/sortRelationships";
/**
 * Upgrade an older model into a model matching the current schema.
 * @param model The model to be upgraded.
 * @returns The original model upgraded to match the current schema.
 */
export default function upgradeModel(obj) {
    let isUpdated = false;
    let hasAnnotationTimeStamp = true;
    // Model versions prior to 0.10.0
    if (obj.SCHEMA_VERSION.localeCompare("0.10.0", undefined, {
        numeric: true
    }) < 0) {
        hasAnnotationTimeStamp = false;
        const removedAnnotationNames = [];
        const removedAnnotations = [];
        const removedDescriptionNames = [];
        const removedDescriptions = [];
        for (let e = obj.elements.length - 1; e >= 0; e--) {
            const element = obj.elements[e];
            switch (element.type) {
                case "Annotation":
                    removedAnnotationNames.push(element.name);
                    removedAnnotations.push({
                        time: Date.now(),
                        text: element.description,
                        type: element.subtype === "Concern" ? "Risk" : element.subtype
                    });
                    obj.elements.splice(e, 1);
                    break;
                case "Description":
                    removedDescriptionNames.push(element.name);
                    removedDescriptions.push(element.description);
                    obj.elements.splice(e, 1);
                    break;
                default:
                    break;
            }
        }
        for (const element of obj.elements) {
            for (let r = element.relationships.length - 1; r >= 0; r--) {
                const relationship = element.relationships[r];
                switch (relationship.type) {
                    case "annotated_by":
                        const a = removedAnnotationNames.indexOf(relationship.target);
                        if (a >= 0) {
                            element.relationships.splice(r, 1);
                            if (element.annotations) {
                                element.annotations.push(removedAnnotations[a]);
                            }
                            else {
                                element.annotations = [removedAnnotations[a]];
                            }
                        }
                        break;
                    case "described_by":
                        const d = removedDescriptionNames.indexOf(relationship.target);
                        if (d >= 0) {
                            element.relationships.splice(r, 1);
                            if (element.description) {
                                element.description =
                                    element.description + " " + removedDescriptions[d];
                            }
                            else {
                                element.description = removedDescriptions[d];
                            }
                        }
                        break;
                    default:
                        break;
                }
            }
        }
        if (removedAnnotationNames.length + removedDescriptionNames.length > 0) {
            isUpdated = true;
        }
    }
    // Model versions prior to 0.12.0
    if (obj.SCHEMA_VERSION.localeCompare("0.12.0", undefined, {
        numeric: true
    }) < 0) {
        const uidSeeds = {
            ...{
                Action: 1,
                Assumption: 1,
                Lesson: 1,
                Opportunity: 1,
                Risk: 1
            },
            ...{
                Data: 1,
                Hardware: 1,
                Person: 1,
                Product: 1,
                Project: 1,
                Service: 1,
                Software: 1,
                System: 1
            },
            ...{
                Milestone: 1,
                Requirement: 1,
                Stakeholder: 1
            }
        };
        obj.elements.forEach((e) => e.annotations?.forEach((a) => {
            a.id = generateElementId(a.type, uidSeeds);
            isUpdated = true;
        }));
        obj.uidSeeds = {
            annotations: {
                Action: uidSeeds.Action,
                Assumption: uidSeeds.Assumption,
                Lesson: uidSeeds.Lesson,
                Opportunity: uidSeeds.Opportunity,
                Risk: uidSeeds.Risk
            }
        };
    }
    // Model versions prior to 0.12.1
    // Convert timestamps in milliseconds to seconds
    if (obj.SCHEMA_VERSION.localeCompare("0.12.1", undefined, {
        numeric: true
    }) < 0) {
        obj.changeLog.forEach((c) => {
            c.time = Math.round(c.time / 1000);
        });
        obj.elements.forEach((e) => {
            e.changeLog.forEach((c) => {
                c.time = Math.round(c.time / 1000);
            });
            e.annotations?.forEach((a) => {
                a.time = Math.round(a.time / 1000);
            });
        });
        // Add "Lesson" attribute to UidSeeds
        obj.uidSeeds.annotations.Lesson = 1;
    }
    // Model versions prior to 0.13.0
    if (obj.SCHEMA_VERSION.localeCompare("0.13.0", undefined, {
        numeric: true
    }) < 0) {
        // Add the model id
        obj.id = generateModelId();
        // Restructure uid seeds and add seeds for components, milestones and stakeholders
        obj.uidSeeds = {
            Lesson: 1, // add if missing for some reason
            ...obj.uidSeeds.annotations,
            ...{
                Data: 1,
                Hardware: 1,
                Person: 1,
                Product: 1,
                Project: 1,
                Service: 1,
                Software: 1,
                System: 1
            },
            ...{
                Milestone: 1,
                Stakeholder: 1
            }
        };
        obj.elements.forEach((e) => {
            // Populate null descriptions in change log
            e.changeLog.forEach((c) => {
                if (c.operation === "create" && !c.description) {
                    c.description = `Created element: '${e.name}' of type: ${e.type}`;
                }
            });
            // Make sure that all elements have the annotations attibute
            if (!e.hasOwnProperty("annotations")) {
                e.annotations = [];
            }
            else {
                // Add priority attribute to annotations
                e.annotations.forEach((a) => {
                    a.priority = "None";
                });
            }
            // Change the status of milestones from "Undefined" to "NotStarted"
            if (e.type === "Milestone" && (!e.status || e.status === "Undefined")) {
                e.status = "NotStarted";
            }
            // Change the type of undefined elements to "System"
            if (e.type === "Undefined") {
                e.type = "System";
                e.changeLog.push({
                    time: getTimeStamp(),
                    operation: "update",
                    description: `Changed type from Undefined to System`
                });
            }
            // Add IDs
            e.id = generateElementId(e.type, obj.uidSeeds);
            // Replace relationship targets with IDs instead of names
            obj.elements.forEach((e2) => {
                e2.relationships.forEach((r) => {
                    if (r.target === e.name) {
                        r.targetId = e.id;
                        delete r.target;
                    }
                });
            });
            // Replace relationship type "defined_by" with "preceded_by"
            e.relationships.forEach((r) => {
                if (r.type === "defined_by") {
                    r.type = "preceded_by";
                }
            });
        });
        // Extract annotations
        const annotations = [];
        for (let e = obj.elements.length - 1; e >= 0; e--) {
            const element = obj.elements[e];
            const changes = [];
            for (let c = element.changeLog.length - 1; c >= 0; c--) {
                if (element.changeLog[c].description.toLowerCase().includes("annotation")) {
                    changes.push(element.changeLog[c]);
                    element.changeLog.splice(c, 1);
                }
            }
            changes.sort((a, b) => {
                return a.time - b.time;
            });
            for (let a = element.annotations.length - 1; a >= 0; a--) {
                const annotation = element.annotations[a];
                const relevantChanges = changes.filter(c => (c.time === annotation.time &&
                    c.description.includes(annotation.text)) ||
                    c.description.includes(annotation.id));
                const changeLog = relevantChanges.length
                    ? relevantChanges
                    : hasAnnotationTimeStamp
                        ? [
                            {
                                time: annotation.time,
                                operation: "create",
                                description: `Created ${annotation.type.toLowerCase()} annotation: ${annotation.id}.`
                            }
                        ]
                        : [];
                annotations.push({
                    id: annotation.id,
                    metatype: "Annotation",
                    changeLog,
                    relationships: [{ targetId: element.id, type: "annotates" }],
                    text: annotation.text,
                    type: annotation.type,
                    priority: "None"
                });
            }
            delete element.annotations;
        }
        obj.annotations = annotations;
        // Rename elements attribute to components
        obj.components = obj.elements;
        // Extract milestone elements
        const milestones = [];
        for (let e = obj.elements.length - 1; e >= 0; e--) {
            const element = obj.elements[e];
            if (element.type === "Milestone") {
                milestones.unshift({
                    id: element.id,
                    metatype: "Milestone",
                    changeLog: element.changeLog,
                    relationships: element.relationships,
                    attributes: {},
                    name: element.name,
                    status: element.status ? element.status : "Not Started",
                    description: element.description
                });
                obj.elements.splice(e, 1);
            }
        }
        obj.milestones = milestones;
        // Extract Stakeholder elements
        const stakeholders = [];
        for (let e = obj.elements.length - 1; e >= 0; e--) {
            const element = obj.elements[e];
            if (element.type === "Stakeholder") {
                stakeholders.unshift({
                    id: element.id,
                    metatype: "Stakeholder",
                    changeLog: element.changeLog,
                    relationships: element.relationships,
                    attributes: {},
                    name: element.name,
                    colour: element.colour,
                    description: element.description
                });
                obj.elements.splice(e, 1);
            }
        }
        obj.stakeholders = stakeholders;
        // Rename elements attribute to components
        obj.components = obj.elements;
        // Remove elements attribute
        delete obj.elements;
        // Sort annotations, components, milestones and stakeholders by id
        obj.annotations.sort((a, b) => {
            return a.id.localeCompare(b.id, undefined, { numeric: true });
        });
        obj.components.sort((a, b) => {
            return a.id.localeCompare(b.id, undefined, { numeric: true });
        });
        obj.milestones.sort((a, b) => {
            return a.id.localeCompare(b.id, undefined, { numeric: true });
        });
        obj.stakeholders.sort((a, b) => {
            return a.id.localeCompare(b.id, undefined, { numeric: true });
        });
        // Sort relationships (and set component metatype)
        obj.components.forEach((c) => {
            c.metatype = "Component";
            sortRelationships(c.relationships);
        });
        obj.milestones.forEach((c) => {
            sortRelationships(c.relationships);
        });
        obj.stakeholders.forEach((c) => {
            sortRelationships(c.relationships);
        });
        isUpdated = true;
    }
    // Model versions prior to 0.13.2
    if (obj.SCHEMA_VERSION.localeCompare("0.13.2", undefined, {
        numeric: true
    }) < 0) {
        // Add UID seeds for component types: Facility and Organisation
        obj.uidSeeds["Facility"] = 1;
        obj.uidSeeds["Organization"] = 1;
        // Do not set isUpdated to true for minor version updates
    }
    // Model versions prior to 0.14.0
    if (obj.SCHEMA_VERSION.localeCompare("0.14.0", undefined, {
        numeric: true
    }) < 0) {
        // Move element string attributes into an attributes object.
        obj.annotations.forEach((a) => {
            a.attributes = {
                text: a.text,
                type: a.type,
                priority: a.priority || "None",
                closedOn: a.closedOn || null,
                remarks: a.remarks || ""
            };
            delete a.text;
            delete a.type;
            delete a.priority;
            delete a.closedOn;
            delete a.remarks;
        });
        obj.components.forEach((c) => {
            c.attributes = {
                name: c.name,
                type: c.type,
                description: c.description || ""
            };
            delete c.name;
            delete c.type;
            delete c.description;
        });
        obj.milestones.forEach((m) => {
            m.attributes = {
                name: m.name,
                status: m.status,
                description: m.description || ""
            };
            delete m.name;
            delete m.status;
            delete m.description;
        });
        obj.stakeholders.forEach((s) => {
            s.attributes = {
                name: s.name,
                colour: s.colour,
                description: s.description || ""
            };
            delete s.name;
            delete s.colour || null;
            delete s.description || "";
        });
        // Add "Requirement" attribute to UidSeeds
        obj.uidSeeds.Requirement = 1;
        // Add "requirements" attribute to model
        obj.requirements = [];
        isUpdated = true;
    }
    if (isUpdated) {
        const originalVersion = obj.SCHEMA_VERSION.localeCompare("0.10.0", undefined, {
            numeric: true
        }) < 0
            ? obj.SCHEMA_VERSION.substring(0, 3)
            : obj.SCHEMA_VERSION.substring(0, 4);
        const newVersion = SCHEMA_VERSION.substring(0, 4);
        obj.changeLog.push({
            time: getTimeStamp(),
            operation: "update",
            description: `Upgraded model schema from version ${originalVersion} to ${newVersion}`
        });
    }
    obj.SCHEMA_VERSION = SCHEMA_VERSION;
}
