import { Model } from "@se-toolkit/model-js/schema";
import React from "react";
import { loadModel, useAppDispatch } from "../../features";
import AlertDialog from "../AlertDialog";
import { useWindowEventListener } from "../interactions";

export default function FileDropHandler() {
  const dispatch = useAppDispatch();

  const [open, setOpen] = React.useState(false);
  const modelRef = React.useRef<Model | null>(null);

  const onClose = () => {
    setOpen(false);
  };

  const onSumbit = () => {
    setOpen(false);
    if (modelRef.current) {
      dispatch(loadModel(modelRef.current));
    }
  };

  useWindowEventListener(
    "importjson",
    React.useCallback((e: CustomEvent) => {
      if (e.detail) {
        modelRef.current = e.detail;
        setOpen(true);
      }
    }, [])
  );

  return (
    <AlertDialog
      question="Discard the current model and import another one?"
      explanation="Imports a model.
        The data is handled locally by the browser and is not sent to a remote server.
        The current model will be lost so be sure that you have exported it before importing another one."
      open={open}
      handleClose={onClose}
      handleSubmit={onSumbit}
    />
  );
}
