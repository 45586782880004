import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import {
  GridActionsCellItem,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowModel,
  GridRowParams,
  GridRowsProp
} from "@mui/x-data-grid";
import {
  RequirementType,
  VerificationMethod
} from "@se-toolkit/model-js/schema";
import { findElementParents } from "@se-toolkit/model-js/search";
import { formatVariableName } from "@se-toolkit/model-js/utils";
import React from "react";
import { useSelector } from "react-redux";
import {
  createRelationship,
  deleteElements,
  makeSelectModelElements,
  selectModel,
  updateElement,
  updateElementId,
  useAppDispatch
} from "../../../features";
import { Type } from "../../components/InteractionIcon";
import PaperTable from "../../components/PaperTable";
import AllocationPopover from "../../composition/AllocationPopover";
import AnnotationsPopover from "../../composition/AnnotationsPopover";
import StakeholderMenu from "../../composition/StakeholderMenu";
import { useAnchor } from "../../interactions";
import { useDynamicConfig } from "../../utils/ConfigContextProvider";
import ElementPickerPopover from "../controls/ElementPickerPopover";
import ExportToXlsxButton from "../controls/ExportToXlsxButton";
import useNumberStringComparator from "../gridutils/useNumberStringComparator";
import usePreProcessEditID from "../gridutils/usePreProcessEditID";
import useRenderInteractionIcon from "../gridutils/useRenderInteractionIcon";
import useRenderStakeholder from "../gridutils/useRenderStakeholder";

export interface Props {
  selectedIds?: string[];
  setSelectedIds?: (ids: string[]) => void;
  columnVisibilityModel?: GridColumnVisibilityModel;
  hideExportToXlsxButton?: boolean;
}

export default function RequirementsTable({
  selectedIds = [],
  setSelectedIds = () => {},
  columnVisibilityModel,
  hideExportToXlsxButton = false
}: Props) {
  const dispatch = useAppDispatch();

  const viewName = "Requirements";

  const model = useSelector(selectModel);
  const annotations = useSelector(makeSelectModelElements("Annotation"));
  const components = useSelector(makeSelectModelElements("Component"));
  const requirements = useSelector(makeSelectModelElements("Requirement"));
  const stakeholders = useSelector(makeSelectModelElements("Stakeholder"));

  const [showSystemOnly, setShowSystemOnly] = useDynamicConfig<boolean>(
    `${viewName}.showSystemOnly`,
    false
  );
  const [showUnallocatedOnly, setShowUnallocatedOnly] =
    useDynamicConfig<boolean>(`${viewName}.showUnallocatedOnly`, false);

  const rows: GridRowsProp = React.useMemo(() => {
    const rows = requirements.map(r => {
      const stakeholder = findElementParents(
        stakeholders,
        r.id,
        "responsible_for"
      )[0];
      const hasAllocation: boolean = !!findElementParents(
        components,
        r.id,
        "specified_by"
      ).length;
      const hasAnnotations: boolean = !!findElementParents(
        annotations,
        r.id,
        "annotates"
      ).length;
      return {
        id: r.id,
        requirement: r.attributes.text,
        type: r.attributes.type,
        verificationMethod: r.attributes.verificationMethod,
        stakeholderId: stakeholder?.id,
        hasAllocation,
        hasAnnotations
      };
    });
    return showSystemOnly || showUnallocatedOnly
      ? rows.filter(
          r =>
            (showSystemOnly ? r.type !== "NonSystem" : true) &&
            (showUnallocatedOnly ? !r.hasAllocation : true)
        )
      : rows;
  }, [
    annotations,
    components,
    requirements,
    showSystemOnly,
    showUnallocatedOnly,
    stakeholders
  ]);

  const processRowUpdate = React.useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel): GridRowModel => {
      let isUpdated = false;
      if (newRow.id && newRow.id !== oldRow.id) {
        dispatch(
          updateElementId({
            oldId: oldRow.id,
            newId: newRow.id
          })
        );
        isUpdated = true;
      }
      const oldText = oldRow.requirement.trim();
      const newText = newRow.requirement.trim();
      if (newText && newText !== oldText) {
        dispatch(
          updateElement({
            id: oldRow.id,
            attributes: { text: newText }
          })
        );
        isUpdated = true;
      }
      if (newRow.type && newRow.type !== oldRow.type) {
        dispatch(
          updateElement({
            id: oldRow.id,
            attributes: { type: newRow.type }
          })
        );
        isUpdated = true;
      }
      if (
        newRow.verificationMethod &&
        newRow.verificationMethod !== oldRow.verificationMethod
      ) {
        dispatch(
          updateElement({
            id: oldRow.id,
            attributes: { verificationMethod: newRow.verificationMethod }
          })
        );
        isUpdated = true;
      }
      return isUpdated ? newRow : oldRow;
    },
    [dispatch]
  );

  const renderActions = React.useCallback(
    (params: GridRowParams) => [
      <ElementPickerPopover
        elements={components}
        tooltipText={`Allocate ${params.row.id} to a component`}
        handleSubmit={(sourceId: string) => {
          dispatch(
            createRelationship({
              sourceId,
              targetId: params.row.id,
              type: "specified_by"
            })
          );
        }}
      />,
      <GridActionsCellItem
        icon={
          <Tooltip title={`Delete ${params.row.id}`} placement="left">
            <DeleteIcon />
          </Tooltip>
        }
        label="Delete"
        onClick={() => dispatch(deleteElements([params.row.id]))}
      />
    ],
    [components, dispatch]
  );

  const preProcessEditID = usePreProcessEditID(requirements);
  const renderOwner = useRenderStakeholder(stakeholders);
  const numberStringComparator = useNumberStringComparator();

  const gridRef = React.useRef<HTMLDivElement>(null);
  const allocationAnchor = useAnchor(gridRef);
  const renderAllocation = useRenderInteractionIcon(
    Type.Allocation,
    allocationAnchor,
    "hasAllocation",
    false
  );
  const annotationsAnchor = useAnchor(gridRef);
  const renderAnnotations = useRenderInteractionIcon(
    Type.Annotations,
    annotationsAnchor,
    "hasAnnotations"
  );

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        editable: true,
        sortComparator: numberStringComparator,
        preProcessEditCellProps: preProcessEditID
      },
      {
        field: "requirement",
        headerName: "Requirement",
        flex: 1,
        editable: true
      },
      {
        field: "type",
        type: "singleSelect",
        headerName: "Type",
        width: 150,
        valueOptions: RequirementType.options,
        getOptionLabel: value => formatVariableName(value as string),
        valueFormatter: params => formatVariableName(params.value),
        editable: true
      },
      {
        field: "verificationMethod",
        type: "singleSelect",
        headerName: "Verification Method",
        width: 150,
        valueOptions: VerificationMethod.options,
        editable: true
      },
      {
        field: "stakeholderId",
        headerName: "Owner",
        width: 150,
        renderCell: renderOwner,
        filterable: false
      },
      {
        field: "allocation",
        type: "actions",
        headerName: "Allocation",
        getActions: renderAllocation
      },
      {
        field: "annotations",
        type: "actions",
        headerName: "Annotations",
        getActions: renderAnnotations
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        getActions: renderActions
      }
    ],
    [
      numberStringComparator,
      preProcessEditID,
      renderActions,
      renderAllocation,
      renderAnnotations,
      renderOwner
    ]
  );

  return (
    <>
      <PaperTable
        tableName="Requirements"
        gridRef={gridRef}
        title="Requirements"
        rows={rows}
        columns={columns}
        processRowUpdate={processRowUpdate}
        rowSelectionModel={selectedIds}
        onRowSelectionModelChange={setSelectedIds}
        initialState={{
          columns: {
            columnVisibilityModel
          }
        }}
      >
        <FormGroup row={true}>
          <FormControlLabel
            control={
              <Switch
                checked={showSystemOnly}
                onClick={() => setShowSystemOnly(!showSystemOnly)}
              />
            }
            label="Show only system requirements"
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Switch
                checked={showUnallocatedOnly}
                onClick={() => setShowUnallocatedOnly(!showUnallocatedOnly)}
              />
            }
            label="Show only unallocated requirements"
            labelPlacement="start"
          />
        </FormGroup>
      </PaperTable>
      <AllocationPopover anchor={allocationAnchor} />
      <AnnotationsPopover anchor={annotationsAnchor} />
      <StakeholderMenu
        eventListenerRef={gridRef}
        relationshipType={"responsible_for"}
      />
      <Box
        sx={{ position: "absolute", top: 16, right: 16 }}
        hidden={hideExportToXlsxButton}
      >
        <ExportToXlsxButton
          model={model}
          sheetSelection={[["Requirements", "Requirements"]]}
          initialFileName={`${model.name} Requirements`}
        />
      </Box>
    </>
  );
}
