import { WorkBook, write } from "xlsx";

export function saveStringToFile(
  input: string,
  filename: string,
  type: string
) {
  saveBlobToFile(new Blob([input], { type }), filename);
}

export function saveWorkbookToFile(input: WorkBook, filename: string) {
  const workbookOutput = write(input, {
    bookType: "xlsx",
    type: "array"
  });
  saveBlobToFile(
    new Blob([workbookOutput], { type: "application/octet-stream" }),
    `${filename}.xlsx`
  );
}

export function saveBlobToFile(blob: Blob, filename: string) {
  const blobURL = URL.createObjectURL(blob);
  const tempLink = document.createElement("a");
  tempLink.style.display = "none";
  tempLink.href = blobURL;
  tempLink.setAttribute("download", filename);
  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === "undefined") {
    tempLink.setAttribute("target", "_blank");
  }
  tempLink.click();
  tempLink.remove();
  URL.revokeObjectURL(blobURL);
}
