import SaveIcon from "@mui/icons-material/SaveAlt";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import {
  exportModelAsJsonString,
  exportToXlsx
} from "@se-toolkit/model-js/import-export";
import { Model } from "@se-toolkit/model-js/schema";
import dayjs from "dayjs";
import React from "react";
import { useSelector } from "react-redux";
import {
  enqueueSnackbar,
  resetModificationCounter,
  selectModel,
  setLastSaved,
  useAppDispatch
} from "../../features";
import { saveStringToFile, saveWorkbookToFile } from "../../utils/saveToFile";
import StringInputDialog from "../components/StringInputDialog";
import { useWindowEventListener } from "../interactions";

export default function FileDownloadButton() {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const model: Model = useSelector(selectModel);

  const [anchorEl, setAnchor] = React.useState<HTMLElement | null>(null);
  const menuOpen = Boolean(anchorEl);
  const selectedOption = React.useRef("JSON");
  const [dialogOpen, setDialogOpen] = React.useState(false);

  useWindowEventListener("savemodel", () => {
    saveStringToFile(
      exportModelAsJsonString(model),
      `${model.name} ${dayjs().format("YYYY-MM-DD @ HHmm")}.json`,
      "application/json"
    );
    dispatch(resetModificationCounter());
    dispatch(setLastSaved());
  });

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchor(null);
  };

  const handleSelectJSON = () => {
    selectedOption.current = "JSON";
    handleCloseMenu();
    handleOpenDialog();
  };

  const handleSelectXLSX = () => {
    selectedOption.current = "XLSX";
    handleCloseMenu();
    handleOpenDialog();
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const onSubmit = (fileName: string) => {
    setDialogOpen(false);
    switch (selectedOption.current) {
      case "XLSX":
        const [workbook, errors] = exportToXlsx(model);
        saveWorkbookToFile(workbook, fileName);
        for (const error of errors) {
          dispatch(enqueueSnackbar(error, { variant: "error" }));
        }
        break;
      default:
        saveStringToFile(
          exportModelAsJsonString(model),
          `${fileName}.json`,
          "application/json"
        );
        dispatch(resetModificationCounter());
        dispatch(setLastSaved());
        break;
    }
  };

  return (
    <Box>
      <Tooltip
        title="Download (ctrl+s)"
        aria-label="Download (ctrl+s)"
        placement="bottom"
        disableFocusListener={true}
      >
        <IconButton
          sx={{
            margin: theme.spacing(1)
          }}
          aria-label="Download"
          onClick={onClick}
          size="large"
        >
          <SaveIcon
            sx={{
              color: theme.palette.secondary.main
            }}
          />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-download"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        keepMounted={true}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={menuOpen}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleSelectJSON}>JSON</MenuItem>
        <MenuItem onClick={handleSelectXLSX}>XLSX</MenuItem>
      </Menu>
      <StringInputDialog
        title={`Download model in ${selectedOption.current} format`}
        label="File name"
        initialValue={model.name}
        open={dialogOpen}
        handleClose={handleCloseDialog}
        handleSubmit={onSubmit}
      />
    </Box>
  );
}
