import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React from "react";
import { useStopPropogation } from "../interactions";

export interface Props {
  title: string;
  label: string;
  submitLabel?: string;
  initialValue?: string;
  open: boolean;
  handleClose: () => void;
  handleSubmit: (value: string) => void;
  children?: React.ReactNode;
}

export default function StringInputDialog({
  title,
  label,
  submitLabel = "Submit",
  initialValue = "",
  open,
  handleClose,
  handleSubmit,
  children
}: Props) {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const onSubmit = React.useCallback(() => {
    if (inputRef.current?.value) handleSubmit(inputRef.current.value);
  }, [handleSubmit]);

  const stopPropagation = useStopPropogation();

  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      stopPropagation(e);
      if (e.key === "Enter") onSubmit();
    },
    [onSubmit, stopPropagation]
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      onKeyDown={onKeyDown}
      onKeyUp={stopPropagation}
      aria-labelledby="dialog-title"
    >
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>
        <Stack spacing={2}>
          <TextField
            inputRef={inputRef}
            id="text-input"
            type="text"
            label={label}
            fullWidth={true}
            defaultValue={initialValue}
            variant="standard"
          />
          {children}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="success">
          {submitLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
