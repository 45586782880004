import AppsIcon from "@mui/icons-material/Apps";
import ViewListIcon from "@mui/icons-material/ViewList";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from "@mui/material/styles";
import { determineElementHierarchy } from "@se-toolkit/model-js/analysis";
import { findElementsRelatedBy } from "@se-toolkit/model-js/search";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Link as RouterLink, Routes } from "react-router-dom";
import { makeSelectModelElements } from "../../../features";
import { useViewKeyboardShortcuts } from "../../interactions";
import { useDynamicConfig } from "../../utils/ConfigContextProvider";
import ElementTreeView from "../treeview/ElementTreeView";
import useSubTabRouting, { TabRoute } from "../utils/useSubTabRouting";
import InterfaceDesignDiagram from "./InterfaceDesignDiagram";
import InterfaceDesignTable from "./InterfaceDesignTable";

export default function InterfaceDesignView() {
  const theme = useTheme();

  const viewName = "Interfaces";
  const relationshipType = "input_from";

  const components = useSelector(makeSelectModelElements("Component"));

  const [rootId, setRootId] = useDynamicConfig<string>(
    `${viewName}.rootId`,
    ""
  );

  const componentList = React.useMemo(() => {
    let hierarchy = determineElementHierarchy({
      elements: components,
      relationshipType: "built_from",
      rootId,
      maxDepth: 1
    });
    if (rootId) {
      const index = hierarchy.findIndex(n => n.id === rootId);
      if (index > -1) hierarchy.splice(index, 1);
      return hierarchy.map(e => e.element);
    }
    const relatedElements = findElementsRelatedBy(components, relationshipType);
    return components.filter(c => relatedElements.includes(c.id));
  }, [components, rootId]);

  useViewKeyboardShortcuts();

  const tabRoutes: TabRoute[] = [
    {
      icon: <AppsIcon />,
      label: "Diagram",
      path: "/diagram",
      element: <InterfaceDesignDiagram elements={componentList} />
    },
    {
      icon: <ViewListIcon />,
      label: "Table",
      path: "/table",
      element: (
        <InterfaceDesignTable
          elements={componentList}
          relationshipType={relationshipType}
        />
      )
    }
  ];

  const defaultTab = tabRoutes[0].path;
  const { basePath, currentTab, indexPath, setLastActiveTab } =
    useSubTabRouting(viewName, tabRoutes, defaultTab);

  const tabs = Object.values(tabRoutes).map(({ icon, label, path }) => (
    <Tab
      key={path}
      icon={icon}
      aria-label={label}
      component={RouterLink}
      to={`${basePath}${path}`}
      value={path}
      onClick={() => setLastActiveTab(path)}
    />
  ));

  const routes = Object.values(tabRoutes).map(({ path, element }) => (
    <Route key={path} path={`${path}/*`} element={element} />
  ));

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%"
      }}
    >
      <ElementTreeView
        viewName={viewName}
        elements={components}
        relationshipType={"built_from"}
        selectedId={rootId}
        setSelectedId={setRootId}
      />
      <Divider orientation="vertical" variant="fullWidth" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          paddingLeft: 0.5
        }}
      >
        <Toolbar
          sx={{
            padding: theme.spacing(0.5)
          }}
          disableGutters
          variant="dense"
        >
          <Box sx={{ flexGrow: 1, marginLeft: theme.spacing(1) }}></Box>
          <Box sx={{ flexGrow: 1 }}>
            <Tabs
              value={currentTab}
              indicatorColor="secondary"
              textColor="inherit"
            >
              {tabs}
            </Tabs>
          </Box>
        </Toolbar>
        <Divider />
        <Routes>
          <Route index={true} element={<Navigate to={indexPath} />} />
          {routes}
        </Routes>
      </Box>
    </Box>
  );
}
