import { createSelector } from "@reduxjs/toolkit";
import { Requirement as _Requirement } from "@se-toolkit/model-js/schema";
import { getAllElements } from "@se-toolkit/model-js/schemautils";
import { findElement, findElements } from "@se-toolkit/model-js/search";
import React from "react";
import { useSelector } from "react-redux";
import { makeSelectModelElements, selectModel } from "../../features";
import ElementData from "../components/ElementData";
import ElementsPopover from "../components/ElementsPopover";
import { Anchor } from "../interactions/useAnchor";

export interface Props {
  anchor: Anchor;
}

export default function SpecificationPopover({ anchor }: Props) {
  const { id: elementId } = anchor;

  const model = useSelector(selectModel);
  const element = React.useMemo(() => {
    return findElement(getAllElements(model), elementId);
  }, [elementId, model]);
  const requirements = useSelector(
    createSelector(
      makeSelectModelElements("Requirement"),
      (requirements: _Requirement[]) => {
        if (!element) return [];
        return findElements(
          requirements,
          element.relationships
            .filter(r => r.type === "specified_by")
            .map(r => r.targetId)
        );
      }
    )
  );

  const items = React.useMemo(
    () =>
      requirements.map(e => (
        <ElementData
          key={e.id}
          id={e.id}
          label="Requirement"
          text={e.attributes.text}
        />
      )),
    [requirements]
  );

  return (
    <ElementsPopover anchor={anchor} isEnabled={items.length > 0}>
      {items}
    </ElementsPopover>
  );
}
