import { sortElements } from "../utils";
import { getElementPrefix } from "./generateElementId";
import getAllElements from "./getAllElements";
export default function resetUidSeeds(model) {
    let areUidSeedsReset = false;
    const allElements = getAllElements(model);
    const uidSeeds = { ...model.uidSeeds };
    for (const key in uidSeeds) {
        const prefix = getElementPrefix(key);
        const elements = allElements.filter(e => e.id.startsWith(`${prefix}.`));
        if (elements.length === 0) {
            if (uidSeeds[key] !== 1) {
                uidSeeds[key] = 1;
                areUidSeedsReset = true;
            }
            continue;
        }
        sortElements(elements);
        let nextElementNumber = 1;
        for (let i = elements.length - 1; i >= 0; i--) {
            const lastElementNumber = parseInt(elements[i].id.split(".")[1], 10);
            if (!isNaN(lastElementNumber)) {
                nextElementNumber = lastElementNumber + 1;
                break;
            }
        }
        if (uidSeeds[key] !== nextElementNumber) {
            uidSeeds[key] = nextElementNumber;
            areUidSeedsReset = true;
        }
    }
    if (!areUidSeedsReset)
        return false;
    model.uidSeeds = uidSeeds;
    return true;
}
