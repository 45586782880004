import diffElementArrays from "../comparators/diffElementArrays";
import { handleError } from "../utils";
import parseRequirementsSheet from "./xlsxutils/parseRequirementsSheet";
import parseStructureSheet from "./xlsxutils/parseStructureSheet";
import refineSheetSelection from "./xlsxutils/refineSheetSelection";
/**
 * Import from xlsx format
 * @param model The model.
 * @param workbook The xlsx workbook to be imported.
 * @param sheetSelection The sheets to be imported. If omitted or empty, all sheets are imported.
 * @returns an array of modification instructions and an array of error strings.
 */
export default function importFromXlsx(model, workbook, sheetSelection = []) {
    const [, errors] = refineSheetSelection(workbook, sheetSelection);
    let areAllParsersImplemented = true;
    // iterate over all selected sheets
    const modificationInstructions = [];
    for (const [sheetName, sheetType] of sheetSelection) {
        try {
            let mI = null;
            const worksheet = workbook.Sheets[sheetName];
            switch (sheetType) {
                case "Requirements":
                    const requirements = parseRequirementsSheet(worksheet);
                    mI = diffElementArrays(model.requirements, requirements, "incomplete");
                    break;
                case "Structure":
                    const components = parseStructureSheet(worksheet);
                    mI = diffElementArrays(model.components, components, "incomplete");
                    break;
                default:
                    areAllParsersImplemented = false;
            }
            if (mI)
                modificationInstructions.push(mI);
        }
        catch (e) {
            errors.push(handleError(e, "parse"));
        }
    }
    if (!areAllParsersImplemented) {
        errors.push(`Could not import all sheets from workbook. Some parsers are not implemented yet.`);
    }
    return [modificationInstructions, errors];
}
