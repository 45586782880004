import { SCHEMA_VERSION } from "../schema";
import generateModelId from "./generateModelId";
export default function createEmptyModel({ generateUniqueId = false } = {}) {
    return {
        id: generateUniqueId ? generateModelId() : "emptymodel",
        name: "New Model",
        SCHEMA_VERSION,
        changeLog: [],
        uidSeeds: {
            Action: 1,
            Assumption: 1,
            Lesson: 1,
            Opportunity: 1,
            Risk: 1,
            Data: 1,
            Facility: 1,
            Hardware: 1,
            Organization: 1,
            Person: 1,
            Product: 1,
            Project: 1,
            Service: 1,
            Software: 1,
            System: 1,
            Milestone: 1,
            Requirement: 1,
            Stakeholder: 1
        },
        annotations: [],
        components: [],
        milestones: [],
        requirements: [],
        stakeholders: []
    };
}
