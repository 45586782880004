import { ComponentType } from "../schema";
/**
 * Count component types
 * @returns A count of component types to the number of components of that type
 */
export default function countComponents(components) {
    const items = new Map();
    ComponentType.options.forEach(key => {
        items.set(key, 0);
    });
    for (const c of components) {
        const type = c.attributes.type;
        if (ComponentType.options.includes(type)) {
            items.set(type, items.get(type) + 1);
        }
    }
    return {
        items,
        total: components.length
    };
}
