import { Shape } from "../Layout";

export const calculateMaxWidth = (
  shapes: Shape[],
  nodeWidth: number
): number => {
  const X = shapes.map(shape => shape.position.x || 0);
  return Math.max(...X, nodeWidth / 2) + nodeWidth / 2;
};

export const calculateMaxHeight = (
  shapes: Shape[],
  nodeHeight: number
): number => {
  const Y = shapes.map(shape => shape.position.y || 0);
  return Math.max(...Y, nodeHeight / 2) + nodeHeight / 2;
};
