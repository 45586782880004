import { utils as xlsx } from "xlsx";
import formatWorksheet from "./formatWorksheet";
export default function createRoadmapSheet(model) {
    const rows = model.milestones.map(m => {
        const { name, status, description } = m.attributes;
        return {
            id: m.id,
            name,
            status,
            description: description || ""
        };
    });
    const ws = xlsx.json_to_sheet(rows);
    const headers = ["ID", "Milestone Name", "Status", "Description"];
    xlsx.sheet_add_aoa(ws, [headers], { origin: "A1" });
    formatWorksheet(ws, [headers, ...rows.map(r => Object.values(r))]);
    return ws;
}
