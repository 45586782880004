import { Layout } from "../Layout";
import { Position } from "../Position";

export default function findElementAtPosition(
  layout: Layout,
  position: Position
): string | null {
  const target = layout.shapes.find(shape => {
    return (
      position.x >= shape.position.x - shape.width / 2 &&
      position.x <= shape.position.x + shape.width / 2 &&
      position.y >= shape.position.y - shape.height / 2 &&
      position.y <= shape.position.y + shape.height / 2
    );
  });
  return target?.id || null;
}
