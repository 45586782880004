import { getAllElements } from "@se-toolkit/model-js/schemautils";
import { findElement } from "@se-toolkit/model-js/search";
import React from "react";
import { useSelector } from "react-redux";
import { selectModel, updateElement, useAppDispatch } from "../../features";
import ElementsPopover from "../components/ElementsPopover";
import { Anchor } from "../interactions/useAnchor";
import Description from "./Description";

export interface Props {
  anchor: Anchor;
}

export default function DescriptionPopover({ anchor }: Props) {
  const { id, isFixed: isEditable } = anchor;

  const dispatch = useAppDispatch();
  const model = useSelector(selectModel);
  const description: string | null = React.useMemo(() => {
    const element = findElement(getAllElements(model), id);
    if (!element) return null;
    if (!("description" in element.attributes)) return null;
    return element.attributes.description || null;
  }, [id, model]);

  const submitChange = React.useCallback(
    (description: string) => {
      dispatch(updateElement({ id, attributes: { description } }));
    },
    [dispatch, id]
  );

  return (
    <ElementsPopover anchor={anchor} isEnabled={!!description || isEditable}>
      <Description value={description || ""} submitChange={submitChange} />
    </ElementsPopover>
  );
}
