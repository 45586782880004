import { z } from "zod";
import { Element } from "./Element";
/*
 * Requirement parts are useful for creating requirements using a parsing
 * template. The parts are defined here for use in user interfaces. Once
 * defined, the parts must be combined into a single string and assigned to the
 * text attribute of the requirement.
 */
export const RequirementParts = z.object({
    actor: z.string().trim().min(1, {
        message: "A requirement may not have an empty actor (subject, noun)."
    }),
    conditionsOfAction: z.string().trim().default(""),
    action: z.string().trim().min(1, {
        message: "A requirement may not have an empty action (verb)."
    }),
    constraintsOfAction1: z.string().trim().default(""),
    objectOfAction: z.string().trim().min(1, {
        message: "A requirement may not have an empty object of action (noun)."
    }),
    constraintsOfAction2: z.string().trim().default(""),
    refinementOfObject: z.string().trim().default(""),
    constraintsOfAction3: z.string().trim().default(""),
    exceptionsToAction: z.string().trim().default("")
});
export const RequirementType = z.enum([
    "CDRL", // Contract Data Requirements List
    "Design",
    "Environmental",
    "ExternalInterface",
    "Functional",
    "NonSystem",
    "OtherQualities",
    "Performance",
    "Physical",
    "Resource",
    "States/Modes"
]);
export const VerificationMethod = z.enum([
    "Analysis",
    "Demonstration",
    "Inspection",
    "Test"
]);
export const RequirementAttributes = z.object({
    text: z
        .string()
        .trim()
        .min(1, { message: "A requirement may not have empty text." }),
    type: RequirementType,
    verificationMethod: VerificationMethod
});
export const Requirement = Element.extend({
    attributes: RequirementAttributes
});
