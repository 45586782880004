import React from "react";
import { useWindowEventListener } from ".";
import { useDynamicConfig } from "../utils/ConfigContextProvider";
import getEventTargetData from "./getEventTargetData";

export default function useSelection(viewName: string, all: string[] = []) {
  const [selection, setSelection] = useDynamicConfig<string[]>(
    `${viewName}.selection`,
    []
  );

  const handlePointer = React.useCallback(
    (e: React.PointerEvent) => {
      e.preventDefault();
      if (e.pointerType === "touch") return;
      const id = getEventTargetData(e.target, "id");
      if (!id) {
        if (selection.length > 0) {
          setSelection([]);
        }
        return;
      }
      if (e.ctrlKey || e.metaKey || e.shiftKey) {
        const index = selection.indexOf(id);
        if (index < 0) {
          setSelection([...selection, id]);
          return;
        }
        if (e.button === 0) {
          // only remove the element from the selection on left click
          selection.splice(index, 1);
          setSelection([...selection]);
        }
        return;
      }
      setSelection([id]);
    },
    [selection, setSelection]
  );

  const handleTouch = React.useCallback(
    (e: React.TouchEvent) => {
      const id = getEventTargetData(e.target, "id");
      if (!id) {
        if (selection.length > 0) {
          setSelection([]);
        }
        return;
      }
      const index = selection.indexOf(id);
      if (index < 0) {
        setSelection([...selection, id]);
        return;
      }
      if (e.changedTouches.length === 1) {
        // only remove the element from the selection on single tap
        selection.splice(index, 1);
        setSelection([...selection]);
      }
    },
    [selection, setSelection]
  );

  useWindowEventListener(
    "selectall",
    React.useCallback(() => setSelection(all), [all, setSelection])
  );
  useWindowEventListener(
    "selectnone",
    React.useCallback(() => setSelection([]), [setSelection])
  );

  return { selection, setSelection, handlePointer, handleTouch };
}
