import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ViewListIcon from "@mui/icons-material/ViewList";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from "@mui/material/styles";
import { determineElementHierarchy } from "@se-toolkit/model-js/analysis";
import { findElements } from "@se-toolkit/model-js/search";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Link as RouterLink, Routes } from "react-router-dom";
import { makeSelectModelElements, selectModel } from "../../../features";
import ContextMenu from "../../components/ContextMenu";
import MilestonesDialog from "../../composition/MilestonesDialog";
import useDeleteElements from "../../featurehooks/useDeleteElements";
import { useSelection, useViewKeyboardShortcuts } from "../../interactions";
import useSubTabRouting, { TabRoute } from "../utils/useSubTabRouting";
import RoadmapDiagram from "./RoadmapDiagram";
import RoadmapTable from "./RoadmapTable";

export default function RoadmapView() {
  const theme = useTheme();

  const viewName = "Roadmap";
  const relationshipType = "preceded_by";

  const diagramSvgRef = React.useRef<SVGSVGElement>(null);

  const model = useSelector(selectModel);
  const milestones = useSelector(makeSelectModelElements("Milestone"));

  const hierarchy = React.useMemo(
    () =>
      determineElementHierarchy({
        elements: milestones,
        relationshipType
      }),
    [milestones, relationshipType]
  );

  const {
    selection: selectedIds,
    setSelection: setSelectedIds,
    handlePointer,
    handleTouch
  } = useSelection(
    viewName,
    hierarchy.map(n => n.id)
  );
  const selectedMilestones = React.useMemo(
    () => findElements(milestones, selectedIds),
    [selectedIds, milestones]
  );

  useDeleteElements(selectedIds);

  useViewKeyboardShortcuts();

  const tabRoutes: TabRoute[] = [
    {
      icon: (
        <AccountTreeIcon
          sx={{
            transform: "rotate(90deg)"
          }}
        />
      ),
      label: "Diagram",
      path: "/diagram",
      element: (
        <RoadmapDiagram
          hierarchy={hierarchy}
          selectedIds={selectedIds}
          svgRef={diagramSvgRef}
          initialFileName={`${model.name} ${viewName}`}
          onPointerUp={handlePointer}
          onTouchEnd={handleTouch}
        />
      )
    },
    {
      icon: <ViewListIcon />,
      label: "Table",
      path: "/table",
      element: (
        <RoadmapTable
          hierarchy={hierarchy}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
        />
      )
    }
  ];

  const defaultTab = tabRoutes[0].path;
  const { basePath, currentTab, indexPath, setLastActiveTab } =
    useSubTabRouting(viewName, tabRoutes, defaultTab);

  const tabs = Object.values(tabRoutes).map(({ icon, label, path }) => (
    <Tab
      key={path}
      icon={icon}
      aria-label={label}
      component={RouterLink}
      to={`${basePath}${path}`}
      value={path}
      onClick={() => setLastActiveTab(path)}
    />
  ));

  const routes = Object.values(tabRoutes).map(({ path, element }) => (
    <Route key={path} path={`${path}/*`} element={element} />
  ));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%"
      }}
    >
      <Toolbar
        sx={{
          padding: theme.spacing(0.5)
        }}
        disableGutters
        variant="dense"
      >
        <Box sx={{ flexGrow: 1, marginLeft: theme.spacing(1) }}></Box>
        <Box sx={{ flexGrow: 1 }}>
          <Tabs
            value={currentTab}
            indicatorColor="secondary"
            textColor="inherit"
          >
            {tabs}
          </Tabs>
        </Box>
      </Toolbar>
      <Routes>
        <Route index={true} element={<Navigate to={indexPath} />} />
        {routes}
      </Routes>
      <MilestonesDialog
        selectedMilestones={selectedMilestones}
        relationshipType={relationshipType}
      />
      <ContextMenu
        eventListenerRef={diagramSvgRef}
        mode={selectedIds.length === 1 ? "singular" : "plural"}
      />
    </Box>
  );
}
