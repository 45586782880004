import Collapse from "@mui/material/Collapse";
import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SnackbarProvider } from "notistack";
import React from "react";
import { Provider } from "react-redux";
import store from "./features/store";
import createSETTheme from "./theme";
import AppNavBar from "./ui/AppNavBar";
import { useWindowEventListener } from "./ui/interactions";

export type Release = "alpha" | "beta" | number;

const App = () => {
  // Use the actual viewport height
  const handleResize = React.useCallback(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);
  handleResize();
  useWindowEventListener("resize", handleResize);

  // Prevent non-standard WebKit GestureEvents
  /*
   * This measure is necessary in order to prevent trackpad pinch gestures from
   * zooming the entire viewport.
   */
  React.useEffect(() => {
    const callback = (e: Event) => e.preventDefault();
    window.addEventListener("gesturestart", callback);
    window.addEventListener("gesturemove", callback);
    window.addEventListener("gestureend", callback);
    return () => {
      window.removeEventListener("gesturestart", callback);
      window.removeEventListener("gesturemove", callback);
      window.removeEventListener("gestureend", callback);
    };
  });

  // Prevent default context menu
  useWindowEventListener(
    "contextmenu",
    React.useCallback((e: MouseEvent) => {
      e.preventDefault();
    }, [])
  );

  const storedThemeMode = localStorage.getItem("themeMode") as
    | "light"
    | "dark"
    | undefined;
  const preferredThemeMode = useMediaQuery("(prefers-color-scheme: dark)")
    ? "dark"
    : "light";
  const [themeMode, setThemeMode] = React.useState<"light" | "dark">(
    storedThemeMode || preferredThemeMode
  );
  useWindowEventListener(
    "setthememode",
    React.useCallback(
      (e: CustomEvent) => setThemeMode(e.detail === "dark" ? "dark" : "light"),
      []
    )
  );

  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={createSETTheme(themeMode)}>
          <CssBaseline />
          <SnackbarProvider
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            maxSnack={5}
            TransitionComponent={Collapse}
          >
            <AppNavBar version="0.14" release={2} />
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
};

export default App;
