import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { Theme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { useFormFields, useMailChimpAPI } from "./utils/useMailChimpAPI";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1)
    },
    header: {
      margin: theme.spacing(1),
      userSelect: "none"
    },
    form: {
      display: "flex",
      margin: theme.spacing(1)
    },
    messages: {
      margin: theme.spacing(1),
      fontStyle: "italic"
    },
    paddingRight: {
      paddingRight: theme.spacing(1)
    }
  });

export interface Props extends WithStyles<typeof styles> {}

const MailChimpForm = ({ classes }: Props) => {
  const url =
    "https://app.us10.list-manage.com/subscribe/post?u=be3069cbb7dfc24db1aef1d4f&amp;id=4baf45a7de";

  const { state, message, handleSubmit } = useMailChimpAPI(url);

  const [params, handleFieldChange] = useFormFields({
    EMAIL: "",
    NAME: ""
  });

  return (
    <Paper className={classes.root}>
      <Typography className={classes.header} variant="subtitle2">
        Subscribe to the SE-Toolkit newsletter to be notified about future
        changes.
      </Typography>
      <form
        className={classes.form}
        onSubmit={event => handleSubmit(event, params)}
      >
        <TextField
          className={classes.paddingRight}
          id="EMAIL"
          label="Email"
          type="email"
          required={true}
          color="secondary"
          fullWidth={true}
          margin="dense"
          variant="outlined"
          size="small"
          value={params.EMAIL}
          onChange={handleFieldChange}
        />
        <TextField
          className={classes.paddingRight}
          id="NAME"
          label="Name"
          type="text"
          color="secondary"
          fullWidth={true}
          margin="dense"
          variant="outlined"
          size="small"
          value={params.NAME}
          onChange={handleFieldChange}
        />
        {/*real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
        <div
          style={{ position: "absolute", left: "-5000px" }}
          aria-hidden="true"
        >
          <input
            type="text"
            name="b_be3069cbb7dfc24db1aef1d4f_4baf45a7de"
            tab-index="-1"
            defaultValue=""
          />
        </div>
        <Button type="submit">Submit</Button>
      </form>
      <Typography className={classes.messages} variant="body2">
        {state && message}
      </Typography>
    </Paper>
  );
};

export default withStyles(styles, { withTheme: true })(MailChimpForm);
