import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

export interface Props {
  id: string;
  label: string;
  text: string;
}

export default function ElementData({ id, label, text }: Props) {
  return (
    <Grid
      container={true}
      spacing={1}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item={true} xs={2}>
        <TextField
          id="id-input"
          type="text"
          label="ID"
          color="secondary"
          margin="dense"
          defaultValue={id || "-"}
          disabled={true}
        />
      </Grid>
      <Grid item={true} xs={10}>
        <TextField
          id="text"
          type="text"
          label={label}
          color="secondary"
          multiline={true}
          fullWidth={true}
          margin="dense"
          variant="outlined"
          defaultValue={text}
          disabled={true}
        />
      </Grid>
    </Grid>
  );
}
