import { useTheme } from "@mui/material/styles";
import React from "react";
import { Connector } from "../../../layouts";
import Interface from "./Interface";

export interface Props {
  connector: Connector;
  width?: number;
  height?: number;
  arrowSize?: number;
  squareSize?: number;
  isConnected?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  setAnchor?: (e: SVGRectElement | null, isConnected: boolean) => void;
}

export const LConnector = ({
  connector,
  width = 0,
  height = 0,
  arrowSize = 0,
  squareSize = 0,
  isConnected = false,
  onClick,
  setAnchor
}: Props) => {
  const theme = useTheme();

  const [isHighlighted, setIsHighlighted] = React.useState(false);
  const activateHighlight = React.useCallback(() => setIsHighlighted(true), []);
  const deactivateHighlight = React.useCallback(
    () => setIsHighlighted(false),
    []
  );

  const { p1, p2 } = connector;
  const x = p2.x;
  const y = p1.y;
  const sign = p2.x - p1.x > 0 ? -1 : 1;

  const xSquare = -squareSize / 2;
  const ySquare = -squareSize / 2;

  const createPoints = (dx: number, dy: number, width: number) => {
    const halfWidth = width / 2;
    const dyArrow = dy + width;
    const arrow = `${-halfWidth},${dyArrow}, ${-width},${dyArrow}, 0,${dy}, ${width},${dyArrow}, ${halfWidth},${dyArrow}`;
    const points = `${arrow}, ${halfWidth},${dyArrow}, ${halfWidth},${-halfWidth}, ${-dx},${-halfWidth}, ${-dx},${halfWidth}, ${-halfWidth},${halfWidth}, ${-halfWidth},${dyArrow}`;
    return points;
  };

  const hoverPoints = createPoints(p2.x - p1.x, p2.y - p1.y, sign * arrowSize);
  const connectorPoints = createPoints(
    -sign * width,
    -sign * height,
    sign * squareSize
  );

  const iconWidth = 18;
  const iconHeight = 18;

  return (
    <g transform={`translate(${x},${y})`}>
      <g transform={`translate(${xSquare},${ySquare})`}>
        <rect
          className="intersection-rect"
          width={squareSize}
          height={squareSize}
          rx="4"
          ry="4"
          fill={isConnected ? theme.palette.shape.fill : "none"}
          stroke={isConnected ? "none" : theme.palette.shape.stroke}
          opacity={isHighlighted ? 0.75 : 0.5}
        />
      </g>
      <polyline
        className="connector-underlay exclude-from-export"
        style={{ pointerEvents: "none" }}
        points={hoverPoints}
        fill={
          isConnected
            ? theme.palette.action.selected
            : theme.palette.action.focus
        }
        stroke="none"
        opacity={isHighlighted ? 1 : 0}
      />
      <g opacity={isConnected ? 1 : 0}>
        <polyline
          className="connector"
          style={{ pointerEvents: "none" }}
          points={connectorPoints}
          fill="none"
          stroke={theme.palette.shape.stroke}
        />
      </g>
      <g transform={`translate(${xSquare},${ySquare})`}>
        <Interface
          sourceId={connector.sourceId}
          targetId={connector.targetId}
          x={squareSize / 2 - iconWidth / 2}
          y={squareSize / 2 - iconHeight / 2}
          width={iconWidth}
          height={iconHeight}
          color={theme.palette.common.white}
          isOn={isConnected}
          setAnchor={setAnchor}
          onClick={onClick}
          onMouseEnter={activateHighlight}
          onMouseLeave={deactivateHighlight}
        />
      </g>
    </g>
  );
};
