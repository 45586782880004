export default function createModificationArray(elArray, mode) {
    switch (mode) {
        case "create":
        case "delete":
            return elArray.map(el => ({
                id: el.id,
                metatype: el.metatype,
                attributes: el.attributes
            }));
        case "update":
            return elArray.map(el => ({
                id: el.id,
                attributes: el.attributes
            }));
    }
    return [];
}
