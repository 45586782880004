import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { useStopPropogation } from "../interactions";
import { Anchor } from "../interactions/useAnchor";

export interface Props {
  anchor: Anchor;
  isEnabled: boolean;
  children: React.ReactNode;
}

export default function ElementsPopover({
  anchor,
  isEnabled,
  children
}: Props) {
  const theme = useTheme();

  const { element: anchorElement, isFixed: isEditable } = anchor;

  const stopPropagation = useStopPropogation();

  return (
    <Popper
      sx={{ minWidth: "35%", maxHeight: "50%", overflow: "auto" }}
      open={Boolean(anchorElement) && isEnabled}
      anchorEl={anchorElement}
      placement="bottom-start"
      onKeyDown={stopPropagation}
      onKeyUp={stopPropagation}
    >
      <Paper
        sx={{
          backgroundColor: theme.palette.background.popover,
          border: `${theme.spacing(0.1)} solid ${
            isEditable
              ? theme.palette.secondary.main
              : theme.palette.text.secondary
          }`,
          padding: `${theme.spacing(0.5)} ${theme.spacing(1)} ${theme.spacing(
            1
          )} ${theme.spacing(1)}`
        }}
      >
        {children}
      </Paper>
    </Popper>
  );
}
