export default function createSvgString(
  input: SVGSVGElement,
  border = 0
): string {
  const output = document.importNode(input, true);
  const metadata = output.getElementsByClassName("exclude-from-export");
  for (let i = metadata.length - 1; i >= 0; --i) {
    metadata[i].remove();
  }

  const boundingBox = input.getBBox();
  const width = boundingBox.width + 2 * border;
  const height = boundingBox.height + 2 * border;

  output.setAttribute("width", `${width}`);
  output.setAttribute("height", `${height}`);
  output.setAttribute(
    "viewBox",
    [boundingBox.x - border, boundingBox.y - border, width, height].toString()
  );
  appendCSS(output);

  const serializer = new XMLSerializer();
  const svgString = serializer.serializeToString(output);

  return svgString;
}

function appendCSS(input: SVGSVGElement) {
  const styleElement = document.createElement("style");
  styleElement.setAttribute("type", "text/css");
  styleElement.innerHTML = createCSString();
  const refNode = input.hasChildNodes() ? input.children[0] : null;
  input.insertBefore(styleElement, refNode);
}

function createCSString(): string {
  const css = `
  @import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
  .connector, .arrow, .indicator {
    fill: none;
    stroke: black;
    stroke-width: 1px;
  }
  .indicator-inner {
    fill: #424242;
  }
  .element-rect, .id-rect {
    fill: #EEEEEE;
  }
  .element-text, .element-label, .id-text {
    stroke: none;
    text-anchor: middle;
    dominantBaseline: central;
    transform: translate(0px,4px);
  }
  .element-text, .id-text {
    fill: black;
  }
  .element-label {
    fill: white;
  }`;

  return css;
}
