import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import React from "react";
import {
  Position,
  getEventTargetData,
  usePointerMove
} from "../../interactions";
import useRefDimensions from "../../utils/useRefDimensions";

/**
 * @param elementRef ref to the HTML element on which to attach the event handlers
 */
export interface Props {
  eventListenerRef: React.RefObject<HTMLElement>;
  onLinkStart: (e: PointerEvent) => void;
  onLinkFinish: (e: PointerEvent) => void;
  onLinkCancel: () => void;
}

export default function DynamicLink({
  eventListenerRef,
  onLinkStart,
  onLinkFinish,
  onLinkCancel
}: Props) {
  const theme = useTheme();

  const containerRef = React.useRef<HTMLDivElement>(null);
  const { width: containerWidth, height: containerHeight } =
    useRefDimensions(containerRef);

  const p1 = React.useRef({ x: 0, y: 0 });
  const [p2, setP2] = React.useState({ x: 0, y: 0 });

  const enableGesture = React.useCallback((e: PointerEvent) => {
    return Boolean(getEventTargetData(e.target, "id"));
  }, []);

  const isEnabled = React.useRef(false);
  const startCallback = React.useCallback(
    (position: Position, e: PointerEvent) => {
      isEnabled.current = true;
      p1.current = position;
      setP2(position);
      onLinkStart(e);
    },
    [onLinkStart]
  );

  const moveCallback = React.useCallback(({ x, y }: Position) => {
    setP2({
      x: p1.current.x + x,
      y: p1.current.y + y
    });
  }, []);

  const endCallback = React.useCallback(
    (position: Position, e: PointerEvent) => {
      if (isEnabled.current) {
        isEnabled.current = false;
        setP2(p1.current);
        onLinkFinish(e);
      }
    },
    [isEnabled, onLinkFinish]
  );

  const cancelCallback = React.useCallback(() => {
    setP2(p1.current);
    isEnabled.current = false;
    onLinkCancel();
  }, [onLinkCancel]);

  usePointerMove({
    eventListenerRef,
    button: 0,
    enableGesture,
    startCallback,
    moveCallback,
    endCallback,
    cancelCallback
  });

  return (
    <Box
      ref={containerRef}
      sx={{
        bottom: 0,
        left: 0,
        position: "absolute",
        right: 0,
        top: 0,
        pointerEvents: "none"
      }}
    >
      <svg width={containerWidth} height={containerHeight}>
        <line
          fill="none"
          stroke={
            theme.palette.mode === "dark"
              ? theme.palette.common.white
              : theme.palette.common.black
          }
          strokeWidth={1}
          x1={p1.current.x}
          y1={p1.current.y}
          x2={p2.x}
          y2={p2.y}
        />
      </svg>
    </Box>
  );
}
