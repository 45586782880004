import AccountTreeIcon from "@mui/icons-material/AccountTree";
import Box from "@mui/material/Box";
import React from "react";
import { Connector, Layout, Shape } from "../../layouts";
import {
  getEventTargetData,
  usePointerDoubleTap,
  useWindowEventListener
} from "../interactions";
import useRefDimensions from "../utils/useRefDimensions";
import { ConnectorsLayer } from "./layers/ConnectorsLayer";
import { ShapesLayer } from "./layers/ShapesLayer";

export interface Props {
  svgRef?: React.RefObject<SVGSVGElement>;
  layout: Layout;
  drawShape: (shape: Shape) => React.ReactNode;
  drawConnector?: (connector: Connector) => React.ReactNode;
  handleClickParentIndicator?: (e: React.MouseEvent) => void;
}

export default function Diagram({
  svgRef,
  layout,
  drawShape,
  drawConnector,
  handleClickParentIndicator
}: Props) {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { width: containerWidth, height: containerHeight } =
    useRefDimensions(containerRef);
  const viewBox = React.useRef("");

  const svgWidth = containerWidth > 0 ? containerWidth : layout.width;
  const svgHeight = containerHeight > 0 ? containerHeight : layout.height;

  viewBox.current = React.useMemo(() => {
    const viewBoxWidth =
      layout.width < containerWidth ? containerWidth : layout.width;
    const viewBoxHeight =
      layout.height < containerHeight ? containerHeight : layout.height;

    const centerOffsetX =
      containerWidth > layout.width ? (layout.width - containerWidth) / 2 : 0;
    const centerOffsetY =
      containerHeight > layout.height
        ? (layout.height - containerHeight) / 2
        : 0;

    return [
      centerOffsetX,
      centerOffsetY,
      viewBoxWidth,
      viewBoxHeight
    ].toString();
  }, [containerHeight, containerWidth, layout.height, layout.width]);

  usePointerDoubleTap({
    eventListenerRef: containerRef,
    callback: React.useCallback(
      (e: PointerEvent) => {
        if (!getEventTargetData(e.target, "id")) {
          svgRef?.current?.setAttribute("viewBox", viewBox.current);
        }
      },
      [svgRef, viewBox]
    )
  });

  useWindowEventListener(
    "keydown",
    React.useCallback(
      (e: KeyboardEvent) => {
        e.preventDefault();
        if (e.key === "Escape") {
          svgRef?.current?.setAttribute("viewBox", viewBox.current);
        }
      },
      [svgRef, viewBox]
    )
  );

  const connectorsLayer = React.useMemo(() => {
    if (layout.connectors.length > 0 && drawConnector) {
      return <ConnectorsLayer layout={layout} drawConnector={drawConnector} />;
    }
  }, [drawConnector, layout]);

  const shapesLayer = React.useMemo(() => {
    return <ShapesLayer layout={layout} drawShape={drawShape} />;
  }, [drawShape, layout]);

  const parentIndicator = React.useMemo(() => {
    return handleClickParentIndicator ? (
      <g
        className="exclude-from-export"
        transform={`translate(${layout.width / 2 + 12} -32) rotate(90,0,0)`}
      >
        <AccountTreeIcon width={24} height={24} />
        <rect
          width={24}
          height={24}
          opacity={0}
          onClick={handleClickParentIndicator}
        />
      </g>
    ) : null;
  }, [handleClickParentIndicator, layout.width]);

  return (
    <Box
      ref={containerRef}
      sx={{
        bottom: 0,
        left: 0,
        position: "absolute",
        right: 0,
        top: 0
      }}
    >
      <svg
        ref={svgRef}
        width={svgWidth}
        height={svgHeight}
        viewBox={viewBox.current}
      >
        {connectorsLayer}
        {shapesLayer}
        {parentIndicator}
      </svg>
    </Box>
  );
}
