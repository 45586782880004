import { Element } from "../../schema";
import { parseElementAttributes } from "../../schemautils";
import createEmptyElement from "../../schemautils/createEmptyElement";
import handleError from "../../utils/handleError";
export default function parseSheet(metatype, worksheet, columnHeaders, allocateAttribute) {
    const range = worksheet["!ref"];
    if (!range) {
        return [];
    }
    const startColumn = range.split(":")[0].replace(/[0-9]/g, "");
    const endColumn = range.split(":")[1].replace(/[0-9]/g, "");
    const startRow = parseInt(range.split(":")[0].replace(/[A-Z]/g, ""), 10);
    const endRow = parseInt(range.split(":")[1].replace(/[A-Z]/g, ""), 10);
    const startColumnIndex = startColumn.charCodeAt(0);
    const endColumnIndex = endColumn.charCodeAt(0);
    // find the header row
    let headerRow = -1;
    const columnIndicies = columnHeaders.map(k => [k, ""]);
    if (!columnHeaders.includes("ID"))
        columnIndicies.unshift(["ID", ""]);
    for (let r = startRow; r <= endRow; r++) {
        for (let c = startColumnIndex; c <= endColumnIndex; c++) {
            const columnRef = `${String.fromCharCode(c)}`;
            const cell = worksheet[`${columnRef}${r}`];
            if (!cell)
                continue;
            const columnIndex = columnIndicies.find(k => k[0] === cell.v);
            if (columnIndex)
                columnIndex[1] = columnRef;
        }
        if (columnIndicies.every(k => !!k[1])) {
            headerRow = r;
            break;
        }
    }
    if (headerRow <= -1) {
        const missingColumns = columnIndicies.filter(k => !k[1]).map(k => k[0]);
        if (missingColumns.length === columnIndicies.length) {
            throw new Error(`The header row is missing.`);
        }
        const isPlural = missingColumns.length > 1;
        const singPlur = isPlural ? "columns are" : "column is";
        const listAnd = isPlural ? " and " : "";
        const lastColumn = missingColumns.pop();
        const columnList = missingColumns.join(", ");
        throw new Error(`The ${columnList}${listAnd}${lastColumn} ${singPlur} missing.`);
    }
    // find the attributes and create the elements
    const elements = [];
    for (let r = headerRow + 1; r <= endRow; r++) {
        let id = "";
        let attributes = {};
        try {
            if (columnIndicies.every(c => !worksheet[`${c[1]}${r}`]?.v))
                break; // empty row
            for (const [key, columnRef] of columnIndicies) {
                const value = worksheet[`${columnRef}${r}`]?.v || "";
                if (key === "ID") {
                    id = Element.shape.id.parse(value);
                    continue;
                }
                allocateAttribute(attributes, key, value);
            }
            parseElementAttributes(metatype, attributes);
        }
        catch (error) {
            handleError(error, "throw", {
                prefix: `Error in row ${r}:`
            });
        }
        const element = createEmptyElement(id, metatype, attributes);
        elements.push(element);
    }
    return elements;
}
