import SettingsIcon from "@mui/icons-material/Settings";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";

export default function SettingsButton() {
  const theme = useTheme();

  const [anchorEl, setAnchor] = React.useState<HTMLElement | null>(null);
  const menuOpen = Boolean(anchorEl);

  const storedThemeMode = localStorage.getItem("themeMode") as
    | "light"
    | "dark"
    | undefined;
  const isDarkPreferred = useMediaQuery("(prefers-color-scheme: dark)");
  const [isThemeDark, setIsThemeDark] = React.useState(
    storedThemeMode ? storedThemeMode === "dark" : isDarkPreferred
  );
  const onChangeThemeMode = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const themeMode = e.target.checked ? "dark" : "light";
      localStorage.setItem("themeMode", themeMode);
      window.dispatchEvent(
        new CustomEvent("setthememode", {
          detail: themeMode
        })
      );
      setIsThemeDark(e.target.checked);
      handleCloseMenu();
    },
    []
  );

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchor(null);
  };

  return (
    <Box>
      <Tooltip
        title="Settings"
        aria-label="Settings"
        placement="bottom"
        disableFocusListener={true}
      >
        <IconButton
          sx={{
            margin: theme.spacing(1)
          }}
          aria-label="Settings"
          onClick={onClick}
          size="large"
        >
          <SettingsIcon
            sx={{
              color: theme.palette.secondary.main
            }}
          />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-settings"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        keepMounted={true}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={menuOpen}
        onClose={handleCloseMenu}
      >
        <MenuItem>
          <FormControlLabel
            control={
              <Switch
                checked={isThemeDark}
                onChange={onChangeThemeMode}
                name="isThemeDark"
              />
            }
            label="Dark theme"
            labelPlacement="start"
          />
        </MenuItem>
      </Menu>
    </Box>
  );
}
