import { z } from "zod";
export const RelationshipType = z.enum([
    "annotates",
    "built_from",
    "derived_from",
    "input_from",
    "preceded_by",
    "specified_by",
    "responsible_for"
]);
export const Relationship = z.object({
    sourceId: z
        .string()
        .min(1, { message: "A relationship may not have an empty source ID." }),
    targetId: z
        .string()
        .min(1, { message: "A relationship may not have an empty target ID." }),
    type: RelationshipType
});
export const RelationshipTarget = Relationship.pick({
    targetId: true,
    type: true
});
