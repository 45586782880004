import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import AnnotationsTable from "./AnnotationsTable";
import StakeholdersTable from "./StakeholdersTable";

export default function ActionsStakeholders() {
  return (
    <Paper
      sx={{
        display: "flex",
        height: "100%",
        width: "100%"
      }}
    >
      <Stack flexGrow={1} spacing={1}>
        <AnnotationsTable mode="Action" />
        <StakeholdersTable />
      </Stack>
    </Paper>
  );
}
