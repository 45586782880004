import { Model } from "../schema";
import { generateModelId } from "../schemautils";
import { getTimeStamp } from "../utils";
/**
 * Update the model's id.
 * @param model The model.
 * @param newId The new model id. If not provided, a new id will be generated.
 * @returns true if updated or false if not.
 */
export default function updateModelId(model, newId = "") {
    if (newId === model.id)
        return false;
    if (newId) {
        Model.shape.id.parse(newId);
    }
    else {
        newId = generateModelId();
    }
    if (model.id !== "emptymodel") {
        model.changeLog.push({
            time: getTimeStamp(),
            operation: "update",
            description: `Updated model ID from ${model.id} to ${newId}.`
        });
    }
    model.id = newId;
    return true;
}
