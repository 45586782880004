import { MilestoneStatus } from "@se-toolkit/model-js/schema";
import { SETColours } from "../../../theme";

export default function calculateMilestoneStatusColours(
  status: MilestoneStatus | undefined
) {
  let leftFill = SETColours.milestoneStatus.Grey;
  let rightFill = SETColours.milestoneStatus.Grey;

  switch (status) {
    case "Aborted":
      leftFill = SETColours.milestoneStatus.Black;
      rightFill = SETColours.milestoneStatus.Black;
      break;
    case "Blocked":
      leftFill = SETColours.milestoneStatus.Green;
      rightFill = SETColours.milestoneStatus.Red;
      break;
    case "Finished":
      leftFill = SETColours.milestoneStatus.Green;
      rightFill = SETColours.milestoneStatus.Green;
      break;
    case "InProgress":
      leftFill = SETColours.milestoneStatus.Green;
      rightFill = SETColours.milestoneStatus.Grey;
      break;
    case "NotStarted":
      leftFill = SETColours.milestoneStatus.Grey;
      rightFill = SETColours.milestoneStatus.Grey;
      break;
    case "OnHold":
      leftFill = SETColours.milestoneStatus.Green;
      rightFill = SETColours.milestoneStatus.Orange;
      break;
  }

  return { leftFill, rightFill };
}
