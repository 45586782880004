import React from "react";

export default function usePriorityComparator() {
  return React.useCallback((a: string, b: string) => {
    if (a === b) return 0;
    switch (a) {
      case "High":
        return 1;
      case "Medium":
        return b === "High" ? -1 : 1;
      case "Low":
        return b === "High" || b === "Medium" ? -1 : 1;
      case "None":
        return b === "Closed" ? 1 : -1;
      case "Closed":
        return -1;
      default:
        return 0;
    }
  }, []);
}
