import useWindowEventListener from "./useWindowEventListener";

export default function useViewKeyboardShortcuts() {
  // shortcuts triggered on keydown
  useWindowEventListener("keydown", (e: KeyboardEvent) => {
    e.preventDefault();
    if (e.key === "ArrowUp") {
      window.dispatchEvent(new CustomEvent("setparentasroot"));
      return;
    }
    handleDepthKey(e.key);
    if (e.key === "a" && (e.ctrlKey || e.metaKey)) {
      window.dispatchEvent(new CustomEvent("selectall"));
      return;
    }
    if (e.key === "d" && (e.ctrlKey || e.metaKey)) {
      window.dispatchEvent(new CustomEvent("selectnone"));
      return;
    }
  });

  // shortcuts triggered on keyup
  useWindowEventListener("keyup", (e: KeyboardEvent) => {
    e.preventDefault();
    if (e.key === "+") {
      window.dispatchEvent(new CustomEvent("createelements"));
      return;
    }
    if (e.key === "Delete" || e.key === "-") {
      window.dispatchEvent(new CustomEvent("deleteelements"));
      return;
    }
  });
}

export function handleDepthKey(key: string) {
  if (["0", "1", "2", "3"].includes(key)) {
    window.dispatchEvent(
      new CustomEvent("setdepth", { detail: parseInt(key) })
    );
  }
}
