import React from "react";
import { Position } from "./Position";
import getPointerPosition from "./getPointerPosition";
import useElementEventListener from "./useElementEventListener";
import useWindowEventListener from "./useWindowEventListener";

export interface Args<T extends HTMLElement | SVGElement> {
  eventListenerRef: React.RefObject<T>;
  button?: number;
  enableGesture?: (e: PointerEvent) => boolean;
  startCallback?: (position?: Position, e?: PointerEvent) => void;
  moveCallback?: (deltaPosition: Position, e?: PointerEvent) => void;
  endCallback?: (position?: Position, e?: PointerEvent) => void;
  cancelCallback?: () => void;
}

/**
 * This custom hook enables the use of a pointer move gesture (pointerdown -> pointermove -> pointerup).
 * @param eventListenerRef to the HTML element to which to attach the event handlers.
 * @param button to use for the gesture.
 * @param enableGesture function to call on pointer down and which returns true to enable the gesture or false to disable it.
 * @param startCallback to call when the pointerdown event fires.
 * @param moveCallback to call when the pointermove event fires.
 * The move callback is passed the change in position of the pointer since the pointerdown event.
 * @param endCallback to call when the pointerup event fires.
 * @param cancelCallback to call when the pointercancel event fires.
 */
export default function usePointerMove<T extends HTMLElement | SVGElement>({
  eventListenerRef,
  button = 0,
  enableGesture = () => true,
  startCallback = () => {},
  moveCallback = () => {},
  endCallback = () => {},
  cancelCallback = () => {}
}: Args<T>): void {
  const isEnabled = React.useRef(false);
  const pointerIds = React.useRef<number[]>([]);
  const startPosition = React.useRef({ x: 0, y: 0 });

  useElementEventListener(
    eventListenerRef,
    "pointerdown",
    React.useCallback(
      (e: PointerEvent) => {
        if (e.button === button) {
          e.preventDefault();
          pointerIds.current.push(e.pointerId);
          isEnabled.current =
            pointerIds.current.length === 1 && enableGesture(e);
          if (isEnabled.current) {
            startPosition.current = getPointerPosition(e);
            startCallback(startPosition.current, e);
          }
        }
      },
      [button, enableGesture, startCallback]
    )
  );

  useElementEventListener(
    eventListenerRef,
    "pointermove",
    React.useCallback(
      (e: PointerEvent) => {
        if (isEnabled.current) {
          const p = getPointerPosition(e);
          moveCallback(
            {
              x: p.x - startPosition.current.x,
              y: p.y - startPosition.current.y
            },
            e
          );
        }
      },
      [moveCallback]
    )
  );

  useWindowEventListener(
    "pointerup",
    React.useCallback(
      (e: PointerEvent) => {
        e.preventDefault();
        if (isEnabled.current) {
          endCallback(getPointerPosition(e), e);
        }
        isEnabled.current = false;
        pointerIds.current = [];
      },
      [endCallback]
    )
  );

  useWindowEventListener(
    "pointercancel",
    React.useCallback(
      (e: PointerEvent) => {
        e.preventDefault();
        if (isEnabled.current) {
          cancelCallback();
        }
        isEnabled.current = false;
        pointerIds.current = [];
      },
      [cancelCallback]
    )
  );

  useWindowEventListener(
    "keydown",
    React.useCallback(
      (e: KeyboardEvent) => {
        e.preventDefault();
        if (e.key === "Escape") {
          if (isEnabled.current) {
            cancelCallback();
          }
          isEnabled.current = false;
          pointerIds.current = [];
        }
      },
      [cancelCallback]
    )
  );
}
