import { Position } from "../../interactions/Position";

export default function getPositionInSvgCoordinates(
  svg: SVGSVGElement,
  p: Position
): Position {
  const viewBox = svg.viewBox.baseVal;
  // handle svg elements where the viewBox is either invalid or has not been explicitly defined
  if (viewBox.width <= 0 || viewBox.height <= 0) {
    return { ...p };
  }

  const x = svg.x.baseVal.value;
  const y = svg.y.baseVal.value;
  const w = svg.width.baseVal.value;
  const h = svg.height.baseVal.value;

  const sx = w / viewBox.width;
  const sy = h / viewBox.height;
  const s = Math.min(sx, sy);

  const tx = x - viewBox.x * s + (w - viewBox.width * s) / 2;
  const ty = y - viewBox.y * s + (h - viewBox.height * s) / 2;

  return {
    x: (p.x - tx) / s,
    y: (p.y - ty) / s
  };
}
