import React from "react";

export default function useElementEventListener<
  K extends keyof HTMLElementEventMap,
  T extends HTMLElement | SVGElement
>(
  ref: React.RefObject<T>,
  type: K,
  callback: (e: HTMLElementEventMap[K]) => void
) {
  React.useEffect(() => {
    if (!ref.current) return;
    const element = ref.current;
    element.addEventListener(type, callback);
    return () => {
      element.removeEventListener(type, callback);
    };
  });
}
