import { GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { AnyElement, RelationshipType } from "@se-toolkit/model-js/schema";
import { findElement } from "@se-toolkit/model-js/search";
import React from "react";
import PaperTable from "../../components/PaperTable";
import useNumberStringComparator from "../gridutils/useNumberStringComparator";

export interface Props {
  elements: AnyElement[];
  relationshipType: RelationshipType;
}

export default function InterfaceDesignTable({
  elements,
  relationshipType
}: Props) {
  const rows: GridRowsProp = React.useMemo(() => {
    const _rows: {
      id: string;
      sourceId: string;
      sourceName: string;
      targetId: string;
      targetName: string;
    }[] = [];
    elements.forEach(e => {
      const relationships = e.relationships.filter(
        r => r.type === relationshipType
      );
      relationships.forEach(r => {
        const target = findElement(elements, r.targetId);
        const targetName =
          target && "name" in target.attributes ? target.attributes.name : "";
        _rows.push({
          id: `${e.id}_${relationshipType}_${r.targetId}`.replace(/\s/g, ""),
          sourceId: e.id,
          sourceName: "name" in e.attributes ? e.attributes.name : "",
          targetId: r.targetId,
          targetName
        });
      });
    });
    return _rows;
  }, [elements, relationshipType]);

  const numberStringComparator = useNumberStringComparator();

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        width: 200,
        sortComparator: numberStringComparator
      },
      {
        field: "targetId",
        headerName: "Origin ID",
        sortComparator: numberStringComparator
      },
      {
        field: "targetName",
        headerName: "Origin Name",
        flex: 1
      },
      {
        field: "sourceId",
        headerName: "Destination ID",
        width: 150,
        sortComparator: numberStringComparator
      },
      {
        field: "sourceName",
        headerName: "Destination Name",
        flex: 1
      }
    ],
    [numberStringComparator]
  );

  return (
    <>
      <PaperTable
        tableName="Interfaces"
        title="Interfaces"
        rows={rows}
        columns={columns}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false
            }
          },
          sorting: {
            sortModel: [{ field: "sourceId", sort: "asc" }]
          }
        }}
      />
    </>
  );
}
