import { animated, useSpring } from "@react-spring/web";
import React from "react";
import { Shape } from "../../../layouts";

export interface Props {
  shape: Shape;
  children: React.ReactNode[];
}

export const AnimatedShape = ({ shape, children }: Props) => {
  const style = useSpring({
    from: {},
    to: {
      transform: `translate(${shape.position.x}px,${shape.position.y}px)`
    }
  });

  return (
    <animated.g style={style}>
      {/* the shape is drawn relative to its top left corner */}
      {children}
    </animated.g>
  );
};
