import { z } from "zod";
import { Element } from "./Element";
export const StakeholderAttributes = z.object({
    name: z
        .string()
        .min(1, { message: "A stakeholder may not have an empty name." }),
    colour: z.string().nullable().default(null),
    description: z.string().default("")
});
export const Stakeholder = Element.extend({
    attributes: StakeholderAttributes
});
