import React from "react";
import useElementEventListener from "./useElementEventListener";

export interface Args<T extends HTMLElement | SVGElement> {
  eventListenerRef: React.RefObject<T>;
  callback: (e: PointerEvent) => void;
  options?: {
    delay?: number;
  };
}

/**
 * This custom hook enables the use of a pointer move gesture (pointerdown -> pointermove -> pointerup).
 * @param eventListenerRef to the HTML element to which to attach the event handlers.
 * @param callback to call when the double tap gesture is detected.
 */
export default function usePointerDoubleTap<
  T extends HTMLElement | SVGElement
>({ eventListenerRef, callback = () => {}, options }: Args<T>): void {
  const isEnabled = React.useRef(false);
  const pointerIds = React.useRef<number[]>([]);

  const delay = options?.delay || 250;

  useElementEventListener(
    eventListenerRef,
    "pointerdown",
    React.useCallback((e: PointerEvent) => {
      e.preventDefault();
      pointerIds.current.push(e.pointerId);
    }, [])
  );

  useElementEventListener(
    eventListenerRef,
    "pointerup",
    React.useCallback(
      (e: PointerEvent) => {
        e.preventDefault();
        if (isEnabled.current) {
          isEnabled.current = false;
          pointerIds.current = [];
          callback(e);
          return;
        }
        isEnabled.current = pointerIds.current.length === 1;
        setTimeout(() => {
          isEnabled.current = false;
          pointerIds.current = [];
        }, delay);
      },
      [callback, delay]
    )
  );
}
