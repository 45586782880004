import Collapse from "@mui/material/Collapse";
import { alpha, styled } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";
import { treeItemClasses, TreeItemProps } from "@mui/x-tree-view/TreeItem";
import { animated, useSpring } from "@react-spring/web";
import CustomTreeItem from "./CustomTreeItem";

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)"
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`
    }
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

export const StyledTreeItem = styled((props: TreeItemProps) => (
  <CustomTreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .leaf": {
      stroke: `${alpha(theme.palette.text.primary, 0.4)}`,
      strokeDasharray: `${theme.spacing(0.5)} ${theme.spacing(0.2)}`
    }
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`
  },
  [`& .${treeItemClasses.content}`]: {
    "&.Mui-focused": {
      backgroundColor: theme.palette.action.focus,
      color: theme.palette.common.white
    },
    "&.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: theme.palette.action.selected,
      color: theme.palette.common.white,
      ":hover": {
        backgroundColor: theme.palette.action.selected
      }
    }
  }
}));
