/**
 * Sort the relationships alphabetically by target.
 * @param relationships The array of relationships to be sorted.
 * @returns The original relationships array with the elements sorted
 * alphabetically by target.
 */
export default function sortRelationships(relationships) {
    relationships.sort((a, b) => {
        return a.targetId.localeCompare(b.targetId, undefined, { numeric: true });
    });
}
