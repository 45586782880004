import { GridPreProcessEditCellProps } from "@mui/x-data-grid";
import { AnyElement, Element } from "@se-toolkit/model-js/schema";
import { findElement } from "@se-toolkit/model-js/search";
import React from "react";
import { enqueueSnackbar, useAppDispatch } from "../../../features";

export default function usePreProcessEditID(elements: AnyElement[]) {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (params: GridPreProcessEditCellProps) => {
      const newId = params.props.value;
      if (newId === params.row.id) return params.props;
      let hasError = !Element.shape.id.safeParse(newId).success;
      if (!hasError && findElement(elements, newId)) {
        dispatch(
          enqueueSnackbar(
            `Cannot update element id. An element with id ${newId} already exists.`,
            { variant: "warning" }
          )
        );
        hasError = true;
      }
      return { ...params.props, error: hasError };
    },
    [dispatch, elements]
  );
}
