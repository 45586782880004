import { z } from "zod";
import { Element } from "./Element";
export const ComponentType = z.enum([
    "Data",
    "Facility",
    "Hardware",
    "Organization",
    "Person",
    "Product",
    "Project",
    "Service",
    "Software",
    "System"
]);
export const ComponentAttributes = z.object({
    name: z
        .string()
        .min(1, { message: "A component may not have an empty name." }),
    type: ComponentType,
    description: z.string().default("")
});
export const Component = Element.extend({ attributes: ComponentAttributes });
