import { findElementInModel } from "../search";
import { getTimeStamp } from "../utils";
import validateRelationship from "../validators/validateRelationship";
/**
 * Replace a relationship of a specific type with a target element for another
 * of the same type with a different target element.
 * @param model The model.
 * @param relationship The relationship to replace.
 * @param newTargetId The id of the new target element of the relationship.
 * @returns true if the model was modified or false if not.
 */
export default function replaceRelationship(model, relationship, newTargetId) {
    const { sourceId, targetId: oldTargetId, type } = relationship;
    if (newTargetId === oldTargetId)
        return false;
    const { sourceElement, targetElement } = validateRelationship(model, {
        ...relationship,
        targetId: newTargetId
    });
    // confirm that the relationship exists
    const relationshipIndex = sourceElement.relationships.findIndex(r => r.targetId === oldTargetId && r.type === type);
    if (relationshipIndex < 0) {
        throw new Error(`Failed to replace relationship. The source element: ${sourceId}, does not have a ${type} relationship with ${oldTargetId}`);
    }
    sourceElement.relationships.splice(relationshipIndex, 1, {
        targetId: newTargetId,
        type
    });
    const oldTargetElement = findElementInModel(model, oldTargetId);
    const oldTargetName = oldTargetElement && "name" in oldTargetElement.attributes
        ? ` (${oldTargetElement.attributes.name})`
        : "";
    const newTargetName = "name" in targetElement.attributes
        ? ` (${targetElement.attributes.name})`
        : "";
    sourceElement.changeLog.push({
        time: getTimeStamp(),
        operation: "update",
        description: `Replaced ${type} relationship target ${oldTargetId}${oldTargetName} with ${newTargetId}${newTargetName}.`
    });
    return true;
}
