import findElement from "./findElement";
/**
 * Find elements in an elements array given an array of element IDs.
 * @param elements An array of alphabetically sorted elements.
 * @param ids The ids of the elements to find.
 * @returns An array of element references.
 */
export default function findElements(elements, ids) {
    const foundIds = [];
    const result = [];
    ids.forEach(id => {
        const element = findElement(elements, id);
        if (element && !foundIds.includes(id)) {
            result.push(element);
            foundIds.push(id);
        }
    });
    return result;
}
