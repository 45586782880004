import { z } from "zod";
import formatVariableName from "./formatVariableName";
/**
 * Handle an error.
 * @param error The error to handle.
 * @param mode If 'parse' return a string, if 'throw' throw an error.
 * @param options An options object with the following attributes:
 * - prefix: A prefix to add to the error message.
 * - logToConsole: If true, log the error to the console.
 * @returns the error message if mode is 'parse'.
 */
export default function handleError(error, mode = "throw", options) {
    const prefix = options?.prefix ? `${options.prefix} ` : "";
    let message = "Failed to parse unknown error.";
    if (options?.logToConsole)
        console.dir(error);
    if (error instanceof z.ZodError) {
        message = `${prefix}${error.issues
            .map((issue) => {
            const message = `${issue.code === "invalid_enum_value"
                ? `Invalid ${formatVariableName(issue.path[0], false)}.`
                : issue.received || issue.message}`;
            const fullStop = message.charAt(message.length - 1) === "." ? "" : ".";
            return `${message}${fullStop}`;
        })
            .join("; ")}`;
    }
    else if (error instanceof Error) {
        message = `${prefix}${error.message}`;
    }
    if (mode === "throw") {
        throw new Error(message);
    }
    return message;
}
