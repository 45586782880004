import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { diffAttributes } from "@se-toolkit/model-js/comparators";
import {
  Milestone,
  MilestoneStatus,
  RelationshipType
} from "@se-toolkit/model-js/schema";
import { formatVariableName } from "@se-toolkit/model-js/utils";
import React from "react";
import {
  createElement,
  enqueueSnackbar,
  updateElement,
  useAppDispatch
} from "../../features";
import StringInputDialog from "../components/StringInputDialog";
import { useWindowEventListener } from "../interactions";

type Mode = "create" | "update";

export interface Props {
  selectedMilestones?: Milestone[];
  relationshipType?: RelationshipType;
}

export default function MilestonesDialog({
  selectedMilestones = [],
  relationshipType
}: Props) {
  const dispatch = useAppDispatch();

  const [mode, setMode] = React.useState<Mode>("create");

  const hasSelection = selectedMilestones.length > 0;
  const initialValue = selectedMilestones
    .map(m => m.attributes.name)
    ?.join(", ");
  const initialStatus: MilestoneStatus | undefined = hasSelection
    ? selectedMilestones.length === 1 || mode === "create"
      ? selectedMilestones[0].attributes.status
      : undefined
    : "NotStarted";

  const [status, setStatus] = React.useState(initialStatus);
  const [isChecked, setIsChecked] = React.useState(hasSelection);

  const [open, setOpen] = React.useState(false);
  const handleOpen = React.useCallback(
    (mode: Mode) => {
      setMode(mode);
      setStatus(initialStatus);
      setIsChecked(hasSelection);
      setOpen(true);
    },
    [hasSelection, initialStatus]
  );
  useWindowEventListener(
    `createelements`,
    React.useCallback(() => handleOpen("create"), [handleOpen])
  );
  useWindowEventListener(
    `updateelements`,
    React.useCallback(() => {
      if (!hasSelection) {
        dispatch(
          enqueueSnackbar(`Please select one or more milestones to update`, {
            variant: "info"
          })
        );
        return;
      }
      handleOpen("update");
    }, [dispatch, handleOpen, hasSelection])
  );
  const handleClose = React.useCallback(() => setOpen(false), []);

  const onChangeStatus = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setStatus(e.currentTarget.value as MilestoneStatus);
    },
    []
  );

  const onChangeCheckbox = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsChecked(e.currentTarget.checked);
    },
    []
  );

  const handleSubmit = React.useCallback(
    (value: string) => {
      setOpen(false);
      const names = value.split(",").map(n => n.trim());
      switch (mode) {
        case "create":
          names.forEach(name => {
            dispatch(
              createElement({
                metatype: "Milestone",
                attributes: {
                  name,
                  status: status || "NotStarted"
                },
                relateToSourceId: isChecked
                  ? selectedMilestones[0].id
                  : undefined,
                relationshipType
              })
            );
          });
          break;
        case "update": {
          if (names.length < selectedMilestones.length) {
            dispatch(
              enqueueSnackbar(
                `Please provide a name for each selected milestone`,
                {
                  variant: "error"
                }
              )
            );
            return;
          }
          selectedMilestones.forEach((m, index) => {
            const diff = diffAttributes(m.attributes, {
              ...m.attributes,
              name: names[index],
              status: status || m.attributes.status
            });
            if (!diff) return;
            dispatch(
              updateElement({
                id: m.id,
                attributes: diff
              })
            );
          });
        }
      }
    },
    [dispatch, isChecked, mode, relationshipType, selectedMilestones, status]
  );

  const statusOptions = React.useMemo(() => {
    const options: React.ReactNode[] = [];
    if (mode === "update" && selectedMilestones.length > 1) {
      options.push(
        <option key="undefined" value="undefined">
          ...
        </option>
      );
    }
    MilestoneStatus.options.forEach(item =>
      options.push(
        <option key={item} value={item}>
          {formatVariableName(item)}
        </option>
      )
    );
    return options;
  }, [mode, selectedMilestones.length]);

  const isRelatedCheckBox = React.useMemo(() => {
    if (mode === "update" || !hasSelection || !relationshipType) {
      return null;
    }

    return (
      <FormControlLabel
        control={<Checkbox checked={isChecked} onChange={onChangeCheckbox} />}
        label={`Relate to ${selectedMilestones[0].attributes.name}`}
      />
    );
  }, [
    hasSelection,
    isChecked,
    mode,
    onChangeCheckbox,
    relationshipType,
    selectedMilestones
  ]);

  return (
    <StringInputDialog
      title={`${mode.charAt(0).toUpperCase() + mode.slice(1)} Milestones`}
      label="Milestone names"
      submitLabel={mode}
      initialValue={mode === "create" ? "" : initialValue}
      open={open}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
    >
      <Box sx={{ paddingTop: 2 }}>
        <TextField
          id="status-input"
          select={true}
          label={"Status"}
          fullWidth={true}
          value={status}
          SelectProps={{
            native: true
          }}
          onChange={onChangeStatus}
        >
          {statusOptions}
        </TextField>
      </Box>
      {isRelatedCheckBox}
    </StringInputDialog>
  );
}
