import Chip from "@mui/material/Chip";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { ComponentType } from "@se-toolkit/model-js/schema";
import React from "react";
import { SETColours } from "../../../theme";

export default function useRenderComponentType() {
  return React.useCallback((params: GridRenderCellParams<any, string>) => {
    if (!ComponentType.safeParse(params.value).success) return null;
    const type: ComponentType = params.value as ComponentType;
    const backgroundColor = SETColours.componentType[type];
    return (
      <Chip
        sx={{
          backgroundColor,
          borderRadius: 0,
          height: 20,
          color: "white"
        }}
        label={
          <span
            data-id={params.row.id}
            data-labelmetatype={"Component"}
            data-labeltext={type}
          >
            {type}
          </span>
        }
        size="small"
      />
    );
  }, []);
}
