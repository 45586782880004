import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { diffAttributes } from "@se-toolkit/model-js/comparators";
import {
  RelationshipType,
  Requirement,
  RequirementAttributes,
  RequirementParts,
  RequirementType,
  VerificationMethod
} from "@se-toolkit/model-js/schema";
import { formatVariableName } from "@se-toolkit/model-js/utils";
import React from "react";
import {
  createElement,
  enqueueSnackbar,
  updateElement,
  useAppDispatch
} from "../../features";
import { useStopPropogation, useWindowEventListener } from "../interactions";

type Mode = "CREATE" | "UPDATE";

export interface Props {
  selectedRequirement?: Requirement;
  relationshipType?: RelationshipType;
}

export default function RequirementDialog({
  selectedRequirement,
  relationshipType
}: Props) {
  const dispatch = useAppDispatch();

  const emptyParts: RequirementParts = React.useMemo(() => {
    const parts: { [key: string]: string } = {};
    Object.keys(RequirementParts.shape).forEach(key => {
      parts[key] = "";
    });
    return parts as RequirementParts;
  }, []);

  const hasSelection = !!selectedRequirement;

  const {
    text: initialText,
    type: initialType,
    verificationMethod: initialVerificationMethod
  }: RequirementAttributes = hasSelection
    ? selectedRequirement.attributes
    : {
        text: "",
        type: "Functional",
        verificationMethod: "Demonstration"
      };

  const [mode, setMode] = React.useState<Mode>("CREATE");
  const [parts, setParts] = React.useState<RequirementParts>(emptyParts);
  const [text, setText] = React.useState<string>(initialText);
  const [type, setType] = React.useState<RequirementType>(initialType);
  const [verificationMethod, setVerificationMethod] =
    React.useState<VerificationMethod>(initialVerificationMethod);
  const [isChecked, setIsChecked] = React.useState(hasSelection);
  const [errors, setErrors] = React.useState<string[]>([]);
  const [open, setOpen] = React.useState(false);

  const handleOpen = React.useCallback(
    (mode: Mode) => {
      setMode(mode);
      setParts(emptyParts);
      setText(initialText);
      setType(initialType);
      setVerificationMethod(initialVerificationMethod);
      setIsChecked(hasSelection);
      setErrors([]);
      setOpen(true);
    },
    [
      emptyParts,
      hasSelection,
      initialText,
      initialType,
      initialVerificationMethod
    ]
  );
  useWindowEventListener(
    `createelements`,
    React.useCallback(() => handleOpen("CREATE"), [handleOpen])
  );
  useWindowEventListener(
    `updateelements`,
    React.useCallback(() => {
      if (!hasSelection) {
        dispatch(
          enqueueSnackbar(`Please select a requirement to update`, {
            variant: "info"
          })
        );
        return;
      }
      handleOpen("UPDATE");
    }, [dispatch, handleOpen, hasSelection])
  );
  const handleClose = React.useCallback(() => setOpen(false), []);

  const createOnChangePart = React.useCallback(
    (key: keyof RequirementParts) =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const newParts = { ...parts };
        newParts[key] = e.currentTarget.value;
        setParts(newParts);
      },
    [parts]
  );

  const onChangeText = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setText(e.currentTarget.value);
    },
    []
  );

  const onChangeType = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setType(e.currentTarget.value as RequirementType);
    },
    []
  );

  const onChangeVerificationMethod = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setVerificationMethod(e.currentTarget.value as VerificationMethod);
    },
    []
  );

  const onChangeCheckbox = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsChecked(e.currentTarget.checked);
    },
    []
  );

  const handleSubmit = React.useCallback(() => {
    if (mode === "CREATE") {
      const validationResult = RequirementParts.safeParse(parts);
      if (!validationResult.success) {
        /**
         * Using any in the line below is a hack to get around the fact that
         * the zod type definitions are not currently available here.
         * The actual type would be z.ZodError.
         * https://github.com/colinhacks/zod#error-handling
         */
        const issues = (validationResult as any).error.format();
        setErrors(Object.keys(issues));
        return;
      }
    }
    setOpen(false);
    switch (mode) {
      case "CREATE":
        const partsArray: string[] = [];
        Object.keys(RequirementParts.shape).forEach(key => {
          const value = parts[key as keyof RequirementParts];
          if (value) partsArray.push(value.trim());
        });
        dispatch(
          createElement({
            metatype: "Requirement",
            attributes: {
              text: partsArray.join(" "),
              type,
              verificationMethod
            },
            relateToSourceId: isChecked ? selectedRequirement?.id : undefined,
            relationshipType
          })
        );
        break;
      case "UPDATE": {
        if (!selectedRequirement) return;
        const diff = diffAttributes(selectedRequirement.attributes, {
          text,
          type,
          verificationMethod
        });
        if (!diff) return;
        dispatch(
          updateElement({
            id: selectedRequirement.id,
            attributes: diff
          })
        );
      }
    }
  }, [
    dispatch,
    isChecked,
    mode,
    parts,
    relationshipType,
    selectedRequirement,
    text,
    type,
    verificationMethod
  ]);

  const typeOptions = React.useMemo(
    () =>
      RequirementType.options.map(item => (
        <option key={item} value={item}>
          {formatVariableName(item)}
        </option>
      )),
    []
  );

  const verificationMethodOptions = React.useMemo(
    () =>
      VerificationMethod.options.map(item => (
        <option key={item} value={item}>
          {item}
        </option>
      )),
    []
  );

  const isRelatedCheckBox = React.useMemo(() => {
    if (mode === "UPDATE" || !hasSelection || !relationshipType) {
      return null;
    }

    return (
      <FormControlLabel
        control={<Checkbox checked={isChecked} onChange={onChangeCheckbox} />}
        label={`Relate to ${selectedRequirement?.id}`}
      />
    );
  }, [
    hasSelection,
    isChecked,
    mode,
    onChangeCheckbox,
    relationshipType,
    selectedRequirement?.id
  ]);

  const stopPropagation = useStopPropogation();

  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      stopPropagation(e);
      if (e.key === "Enter") handleSubmit();
    },
    [handleSubmit, stopPropagation]
  );

  const partsFields = React.useMemo(
    () =>
      Object.entries(parts).map(([key, value]) => (
        <TextField
          key={key}
          id={`${key}-input`}
          type="text"
          label={formatVariableName(key)}
          fullWidth={true}
          value={value || ""}
          variant="standard"
          required={["actor", "action", "objectOfAction"].includes(key)}
          error={errors.includes(key)}
          onChange={createOnChangePart(key as keyof RequirementParts)}
        />
      )),
    [createOnChangePart, errors, parts]
  );

  const textField = React.useMemo(
    () => (
      <TextField
        id="text-input"
        type="text"
        label="Requirement"
        fullWidth={true}
        value={text}
        variant="standard"
        multiline={true}
        onChange={onChangeText}
      />
    ),
    [onChangeText, text]
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      onKeyDown={onKeyDown}
      onKeyUp={stopPropagation}
      aria-labelledby="dialog-title"
    >
      <DialogTitle id="dialog-title">{`${
        mode.charAt(0) + mode.slice(1).toLowerCase()
      } Requirement`}</DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>
        <Stack spacing={2}>
          <Stack spacing={1}>
            {mode === "CREATE" ? partsFields : textField}
          </Stack>
          <Divider />
          <Stack direction="row" spacing={2}>
            <TextField
              id="type-input"
              select={true}
              label={"Type"}
              fullWidth={true}
              value={type}
              SelectProps={{
                native: true
              }}
              onChange={onChangeType}
            >
              {typeOptions}
            </TextField>
            <TextField
              id="verification-method-input"
              select={true}
              label={"Verification Method"}
              fullWidth={true}
              value={verificationMethod}
              SelectProps={{
                native: true
              }}
              onChange={onChangeVerificationMethod}
            >
              {verificationMethodOptions}
            </TextField>
          </Stack>
          {isRelatedCheckBox}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          CANCEL
        </Button>
        <Button onClick={handleSubmit} color="success">
          {mode}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
