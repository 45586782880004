import { createModificationArray } from "../schemautils";
import { findElement } from "../search";
import { getTimeStamp } from "../utils";
import diffAttributes from "./diffAttributes";
/**
 * Compare two element arrays.
 * @param elArrayA The left hand element array (A).
 * @param elArrayB The right hand element array (B).
 * @param mode The mode of the comparison. If "incomplete", elements and
 * relationships that are not present in the element array of the right hand
 * side of the comparison (B) are ignored.
 * @returns A modification instruction, which, when applied, will transform A into B, or null of A and B are identical.
 */
export default function diffElementArrays(elArrayA, elArrayB, mode = "complete") {
    const elementsToDelete = [];
    const elementsToCreate = [];
    const relationshipsToCreate = [];
    const elementsToUpdate = [];
    const elementsToRevert = [];
    const relationshipsToDelete = [];
    if (mode === "complete")
        elementsToDelete.push(...elArrayA.filter(elA => !findElement(elArrayB, elA.id)));
    const elementsToCompare = [];
    elArrayB.forEach(elB => {
        const elA = findElement(elArrayA, elB.id);
        if (!elA)
            elementsToCreate.push(elB);
        else
            elementsToCompare.push([elA, elB]);
    });
    for (const e of elementsToCreate) {
        relationshipsToCreate.push(...e.relationships.map(r => ({ ...r, sourceId: e.id })));
    }
    for (const elements of elementsToCompare) {
        const [elA, elB] = elements;
        const elDiff = diffAttributes(elA.attributes, elB.attributes);
        if (Object.keys(elDiff).length > 0) {
            const elADiff = {};
            const elBDiff = {};
            for (const key of Object.keys(elDiff)) {
                elADiff[key] = elA.attributes[key];
                elBDiff[key] = elB.attributes[key];
            }
            elementsToUpdate.push({ ...elB, attributes: elBDiff });
            elementsToRevert.push({ ...elA, attributes: elADiff });
        }
        // now check relationships where this element is the source
        const relationshipsInA = elA.relationships.map(r => ({
            ...r,
            sourceId: elA.id
        }));
        const relationshipsInB = elB.relationships.map(r => ({
            ...r,
            sourceId: elB.id
        }));
        if (mode === "complete") {
            for (const rA of relationshipsInA) {
                if (!relationshipsInB.find(rB => rB.targetId === rA.targetId && rB.type === rA.type)) {
                    relationshipsToDelete.push(rA);
                }
            }
        }
        for (const rB of relationshipsInB) {
            if (!relationshipsInA.find(rA => rA.targetId === rB.targetId && rA.type === rB.type)) {
                relationshipsToCreate.push(rB);
            }
        }
    }
    const mI = {
        timestamp: getTimeStamp()
    };
    if (elementsToDelete.length > 0)
        mI.deleteElements = createModificationArray(elementsToDelete, "delete");
    if (elementsToCreate.length > 0)
        mI.createElements = createModificationArray(elementsToCreate, "create");
    if (relationshipsToCreate.length > 0)
        mI.createRelationships = relationshipsToCreate;
    if (elementsToUpdate.length > 0) {
        mI.updateElements = createModificationArray(elementsToUpdate, "update");
        mI.revertElements = createModificationArray(elementsToRevert, "update");
    }
    if (relationshipsToDelete.length > 0)
        mI.deleteRelationships = relationshipsToDelete;
    if (Object.keys(mI).length === 1 && Object.keys(mI)[0] === "timestamp")
        return null;
    return mI;
}
