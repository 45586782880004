import { utils as xlsx } from "xlsx";
import { determineElementHierarchy } from "../../analysis";
import { findElement, findElementParents } from "../../search";
import formatWorksheet from "./formatWorksheet";
export default function createStructureSheet(model) {
    const nodes = determineElementHierarchy({
        elements: model.components,
        relationshipType: "built_from"
    }).sort((a, b) => {
        return a.number.localeCompare(b.number, undefined, { numeric: true });
    });
    const rowInfos = [{ level: 0 }];
    const rows = nodes.map(n => {
        rowInfos.push({ level: n.depth });
        const component = n.element;
        const { name, type, description } = component?.attributes || {};
        return {
            number: n.number,
            id: n.id,
            name: `${" ".repeat(n.depth * 4)}${name}`,
            type: type || "",
            description: description || "",
            owner: findElementParents(model.stakeholders, n.id, "responsible_for")[0]
                ?.attributes.name || "",
            specification: component?.relationships
                .filter(r => r.type === "specified_by")
                .map(r => r.targetId)
                .map(id => {
                const element = findElement(model.requirements, id);
                return element ? `${element.id}: ${element.attributes.text}` : "";
            })
                .join("\n") || ""
        };
    });
    const ws = xlsx.json_to_sheet(rows);
    const headers = [
        "Number",
        "ID",
        "Component Name",
        "Type",
        "Description",
        "Owner",
        "Specification"
    ];
    xlsx.sheet_add_aoa(ws, [headers], { origin: "A1" });
    ws["!rows"] = rowInfos;
    formatWorksheet(ws, [headers, ...rows.map(r => Object.values(r))]);
    return ws;
}
