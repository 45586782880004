/*
 * https://github.com/lightzephyr/web.se-toolkit/wiki/2.n%20Application%20State
 */

import React from "react";

type ConfigValue = boolean | number | string | Object;

interface ConfigState {
  [key: string]: ConfigValue;
}

interface ContextState {
  getConfig: (variableName: string) => ConfigValue | undefined;
  setConfig: (variableName: string, value: ConfigValue) => void;
}

const ConfigContext = React.createContext<ContextState>({
  getConfig: () => undefined,
  setConfig: () => {}
});

export default function ConfigContextProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const configState = React.useRef<ConfigState>({});

  const contextState: ContextState = React.useMemo(
    () => ({
      getConfig: (variableName: string) => {
        return configState.current[variableName];
      },
      setConfig: (variableName: string, value: ConfigValue) => {
        configState.current[variableName] = value;
      }
    }),
    []
  );

  return (
    <ConfigContext.Provider value={contextState}>
      {children}
    </ConfigContext.Provider>
  );
}

export function useStaticConfig<T extends ConfigValue>(
  variableName: string,
  defaultValue?: T
): [T, (value: T) => void] {
  const { getConfig, setConfig } = React.useContext(ConfigContext);

  const state = (getConfig(variableName) as T) ?? defaultValue;

  const setConfigState = React.useCallback(
    (value: T) => setConfig(variableName, value),
    [setConfig, variableName]
  );

  return React.useMemo(() => [state, setConfigState], [setConfigState, state]);
}

export function useDynamicConfig<T extends ConfigValue>(
  variableName: string,
  defaultValue?: T
): [T, (value: T) => void] {
  const { getConfig, setConfig } = React.useContext(ConfigContext);

  const [state, setState] = React.useState<T>(
    (getConfig(variableName) as T) ?? defaultValue
  );

  const setConfigState = React.useCallback(
    (value: T) => {
      setConfig(variableName, value);
      setState(value);
    },
    [setConfig, variableName]
  );

  return React.useMemo(() => [state, setConfigState], [setConfigState, state]);
}
