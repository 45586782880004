import { z } from "zod";
import { Element } from "./Element";
export const MilestoneStatus = z.enum([
    "Aborted",
    "Blocked",
    "Finished",
    "InProgress",
    "NotStarted",
    "OnHold"
]);
export const MilestoneAttributes = z.object({
    name: z
        .string()
        .min(1, { message: "A milestone may not have an empty name." }),
    status: MilestoneStatus.default("NotStarted"),
    description: z.string().default("")
});
export const Milestone = Element.extend({ attributes: MilestoneAttributes });
