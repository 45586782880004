import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ViewListIcon from "@mui/icons-material/ViewList";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from "@mui/material/styles";
import { determineElementHierarchy } from "@se-toolkit/model-js/analysis";
import { findElementParents, findElements } from "@se-toolkit/model-js/search";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Link as RouterLink, Routes } from "react-router-dom";
import { makeSelectModelElements, selectModel } from "../../../features";
import ContextMenu from "../../components/ContextMenu";
import ComponentsDialog from "../../composition/ComponentsDialog";
import useDeleteElements from "../../featurehooks/useDeleteElements";
import {
  useSelection,
  useViewKeyboardShortcuts,
  useWindowEventListener
} from "../../interactions";
import { useDynamicConfig } from "../../utils/ConfigContextProvider";
import DepthSelector from "../controls/DepthSelector";
import ElementTreeView from "../treeview/ElementTreeView";
import useSubTabRouting, { TabRoute } from "../utils/useSubTabRouting";
import SystemStructureDiagram, { LabelMode } from "./SystemStructureDiagram";
import SystemStructureTable from "./SystemStructureTable";

export default function SystemStructureView() {
  const theme = useTheme();

  const viewName = "Structure";
  const relationshipType = "built_from";

  const diagramSvgRef = React.useRef<SVGSVGElement>(null);

  const [labelMode, setLabelMode] = useDynamicConfig<LabelMode>(
    `${viewName}.labelMode`,
    "ElementType"
  );
  const onChange = React.useCallback(
    (event: SelectChangeEvent) => {
      setLabelMode(event.target.value as LabelMode);
    },
    [setLabelMode]
  );

  const model = useSelector(selectModel);
  const components = useSelector(makeSelectModelElements("Component"));

  const [rootId, setRootId] = useDynamicConfig<string>(
    `${viewName}.rootId`,
    ""
  );
  const [maxDepth, setMaxDepth] = useDynamicConfig<number>(
    `${viewName}.maxDepth`,
    -1
  );

  const hierarchy = React.useMemo(() => {
    return determineElementHierarchy({
      elements: components,
      relationshipType,
      rootId,
      maxDepth
    });
  }, [maxDepth, components, relationshipType, rootId]);

  const parents = React.useMemo(
    () =>
      rootId ? findElementParents(components, rootId, relationshipType) : [],
    [components, rootId]
  );
  useWindowEventListener(
    "setparentasroot",
    React.useCallback(() => {
      setRootId(parents.length ? parents[0].id : "");
    }, [parents, setRootId])
  );

  const {
    selection: selectedIds,
    setSelection: setSelectedIds,
    handlePointer,
    handleTouch
  } = useSelection(
    viewName,
    hierarchy.map(n => n.id)
  );
  const selectedComponents = React.useMemo(
    () => findElements(components, selectedIds),
    [selectedIds, components]
  );

  useDeleteElements(selectedIds);

  useViewKeyboardShortcuts();

  const tabRoutes: TabRoute[] = [
    {
      icon: (
        <AccountTreeIcon
          sx={{
            transform: "rotate(90deg)"
          }}
        />
      ),
      label: "Diagram",
      path: "/diagram",
      element: (
        <SystemStructureDiagram
          hierarchy={hierarchy}
          selectedIds={selectedIds}
          svgRef={diagramSvgRef}
          labelMode={labelMode}
          showParentIndicator={parents.length > 0}
          initialFileName={`${model.name} ${viewName}`}
          setRootId={setRootId}
          onPointerUp={handlePointer}
          onTouchEnd={handleTouch}
        />
      )
    },
    {
      icon: <ViewListIcon />,
      label: "Table",
      path: "/table",
      element: (
        <SystemStructureTable
          hierarchy={hierarchy}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
        />
      )
    }
  ];

  const defaultTab = tabRoutes[0].path;
  const { basePath, currentTab, indexPath, setLastActiveTab } =
    useSubTabRouting(viewName, tabRoutes, defaultTab);

  const tabs = Object.values(tabRoutes).map(({ icon, label, path }) => (
    <Tab
      key={path}
      icon={icon}
      aria-label={label}
      component={RouterLink}
      to={`${basePath}${path}`}
      value={path}
      onClick={() => setLastActiveTab(path)}
    />
  ));

  const routes = Object.values(tabRoutes).map(({ path, element }) => (
    <Route key={path} path={`${path}/*`} element={element} />
  ));

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%"
      }}
    >
      <ElementTreeView
        viewName={viewName}
        elements={components}
        relationshipType={relationshipType}
        selectedId={rootId}
        setSelectedId={setRootId}
      />
      <Divider orientation="vertical" variant="fullWidth" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          paddingLeft: 0.5
        }}
      >
        <Toolbar
          sx={{
            padding: theme.spacing(0.5)
          }}
          disableGutters
          variant="dense"
        >
          <Box sx={{ flexGrow: 0 }}>
            <DepthSelector depth={maxDepth} setDepth={setMaxDepth} />
          </Box>
          <Box sx={{ flexGrow: 1, marginLeft: theme.spacing(1) }}>
            <FormControl>
              <InputLabel id="label-select-label">Element labels</InputLabel>
              <Select
                labelId="label-select-label"
                id="label-select"
                value={labelMode}
                label="Element labels"
                onChange={onChange}
                size="small"
                disabled={currentTab === "/table"}
              >
                <MenuItem value={"ElementType"}>Element type</MenuItem>
                <MenuItem value={"Stakeholders"}>Element owner</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Tabs
              value={currentTab}
              indicatorColor="secondary"
              textColor="inherit"
            >
              {tabs}
            </Tabs>
          </Box>
        </Toolbar>
        <Divider />
        <Routes>
          <Route index={true} element={<Navigate to={indexPath} />} />
          {routes}
        </Routes>
        <ComponentsDialog
          selectedComponents={selectedComponents}
          relationshipType={relationshipType}
        />
        <ContextMenu
          eventListenerRef={diagramSvgRef}
          mode={selectedIds.length === 1 ? "singular" : "plural"}
          setRootId={selectedIds.length === 1 ? setRootId : undefined}
        />
      </Box>
    </Box>
  );
}
