import { z } from "zod";
import { Annotation } from "./Annotation";
import { Change } from "./Change";
import { Component } from "./Component";
import { Milestone } from "./Milestone";
import { Requirement } from "./Requirement";
import { Stakeholder } from "./Stakeholder";
import { UidSeeds } from "./Uids";
export const SCHEMA_VERSION = "0.14.0";
export const MINIMUM_SCHEMA_VERSION = "0.9.0";
export const Model = z.object({
    id: z.string().min(1, { message: "A model may not have an empty ID." }),
    name: z.string().min(1, { message: "A model may not have an empty name." }),
    SCHEMA_VERSION: z.string().min(MINIMUM_SCHEMA_VERSION.length, {
        message: `Schemas older than version ${MINIMUM_SCHEMA_VERSION} are no longer supported.`
    }),
    changeLog: z.array(Change),
    uidSeeds: UidSeeds,
    // -- Elements --
    annotations: z.array(Annotation),
    components: z.array(Component),
    milestones: z.array(Milestone),
    requirements: z.array(Requirement),
    stakeholders: z.array(Stakeholder)
});
