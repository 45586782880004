import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import {
  DefaultizedPieValueType,
  PieValueType
} from "@mui/x-charts/models/seriesType/pie";
import { countStakeholders } from "@se-toolkit/model-js/analysis";
import { findElement } from "@se-toolkit/model-js/search";
import { formatVariableName } from "@se-toolkit/model-js/utils";
import React from "react";
import { useSelector } from "react-redux";
import { selectModel } from "../../../features";
import { SETColours } from "../../../theme";
import { SvgText } from "../../components/SvgText";

function PieCenterLabel({
  dx = 0,
  dy = 0,
  children
}: {
  dx?: number;
  dy?: number;
  children: React.ReactNode;
}) {
  const theme = useTheme();
  const { width, height, left, top } = useDrawingArea();
  return (
    <SvgText
      color={theme.palette.text.primary}
      x={left + width / 4 + dx * width}
      y={top + dy * height}
      width={width / 2}
      height={height}
    >
      {children}
    </SvgText>
  );
}

export default function StakeholdersPie() {
  const model = useSelector(selectModel);

  const { systemCount, cdrlCount, nonSystemCount } = React.useMemo(
    () => countStakeholders(model.requirements, model.stakeholders),
    [model.requirements, model.stakeholders]
  );
  const systemOwnershipData = React.useMemo(() => {
    const systemOwnershipData: PieValueType[] = [];
    for (const [key, value] of systemCount.items) {
      const stakeholder = findElement(model.stakeholders, key);
      systemOwnershipData.push({
        id: key,
        value,
        label:
          key === "NotOwned"
            ? formatVariableName(key)
            : stakeholder?.attributes.name,
        color:
          key === "NotOwned"
            ? SETColours.requirement.NotOwned
            : stakeholder?.attributes.colour || ""
      });
    }
    return systemOwnershipData;
  }, [model.stakeholders, systemCount.items]);
  const cdrlOwnershipData = React.useMemo(() => {
    const cdrlOwnershipData: PieValueType[] = [];
    for (const [key, value] of cdrlCount.items) {
      const stakeholder = findElement(model.stakeholders, key);
      cdrlOwnershipData.push({
        id: key,
        value,
        label:
          key === "NotOwned"
            ? formatVariableName(key)
            : stakeholder?.attributes.name,
        color:
          key === "NotOwned"
            ? SETColours.requirement.NotOwned
            : stakeholder?.attributes.colour || ""
      });
    }
    return cdrlOwnershipData;
  }, [cdrlCount.items, model.stakeholders]);
  const nonSystemOwnershipData = React.useMemo(() => {
    const nonSystemOwnershipData: PieValueType[] = [];
    for (const [key, value] of nonSystemCount.items) {
      if (key === "NotOwned") continue;
      const stakeholder = findElement(model.stakeholders, key);
      nonSystemOwnershipData.push({
        id: key,
        value,
        label: stakeholder?.attributes.name,
        color: stakeholder?.attributes.colour || ""
      });
    }
    return nonSystemOwnershipData;
  }, [model.stakeholders, nonSystemCount.items]);

  const arcLabel = React.useCallback((item: DefaultizedPieValueType) => {
    return item.label || "";
  }, []);

  const createValueFormatter = React.useCallback(
    (total: number) => (item: DefaultizedPieValueType) => {
      const percent = Math.round((item.value / total) * 100);
      return `${item.value} of ${total} (${percent}%)`;
    },
    []
  );

  const sizing = {
    innerRadius: "50%",
    paddingAngle: 1,
    cornerRadius: 4
  };

  const dxLeft = -0.1;
  const dxRight = 0.4;
  const dyRight = 0.2;

  return (
    <Paper
      sx={{
        position: "relative",
        height: "100%",
        width: "100%"
      }}
    >
      <Box
        sx={{
          bottom: 0,
          left: 0,
          position: "absolute",
          right: 0,
          top: 0
        }}
      >
        <PieChart
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: "white"
            }
          }}
          series={[
            {
              data: systemOwnershipData,
              arcLabel,
              arcLabelMinAngle: 5,
              valueFormatter: createValueFormatter(systemCount.total),
              ...sizing,
              cx: "40%"
            },
            {
              data: cdrlOwnershipData,
              arcLabel,
              arcLabelMinAngle: 5,
              valueFormatter: createValueFormatter(cdrlCount.total),
              ...sizing,
              cx: "90%",
              cy: "30%",
              innerRadius: "25%",
              outerRadius: "60%"
            },
            {
              data: nonSystemOwnershipData,
              arcLabel,
              arcLabelMinAngle: 5,
              valueFormatter: createValueFormatter(nonSystemCount.total),
              ...sizing,
              cx: "90%",
              cy: "90%",
              innerRadius: "25%",
              outerRadius: "45%",
              startAngle: -90,
              endAngle: 90
            }
          ]}
          slotProps={{
            legend: {
              hidden: true
            }
          }}
        >
          <PieCenterLabel dx={dxLeft}>System\n(excl. CDRL)</PieCenterLabel>
          <PieCenterLabel dx={dxRight} dy={-dyRight}>
            CDRL
          </PieCenterLabel>
          <PieCenterLabel dx={dxRight} dy={dyRight + 0.25}>
            Non-System
          </PieCenterLabel>
        </PieChart>
      </Box>
    </Paper>
  );
}
