import { Position } from "./Position";

export interface LayoutProperties {
  nodeWidth: number;
  nodeHeight: number;
  nodeOrientation: Orientation;
  horizontalSpacing: number;
  verticalSpacing: number;
  layoutOrientation?: Orientation;
  horizontalOffset?: number;
  verticalOffset?: number;
}

export interface Layout {
  shapes: Shape[];
  connectors: Connector[];
  width: number;
  height: number;
  orientation?: Orientation;
}

export interface Shape {
  id: string;
  width: number;
  height: number;
  position: Position;
  rotation: number;
}

export interface Connector {
  sourceId: string;
  targetId: string;
  p1: Position;
  p2: Position;
}

export enum Orientation {
  horizontal = "horizontal",
  vertical = "vertical"
}
