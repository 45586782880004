import React from "react";
import useElementEventListener from "./useElementEventListener";
import useWindowEventListener from "./useWindowEventListener";

export interface Anchor {
  id: string;
  element: HTMLDivElement | SVGRectElement | null;
  isFixed: boolean;
  set: (e: HTMLDivElement | SVGRectElement | null, isFixed: boolean) => void;
}

export default function useAnchor(
  eventListenerRef: React.RefObject<HTMLDivElement | SVGSVGElement>
): Anchor {
  const id = React.useRef<string>("");
  const [element, setElement] = React.useState<
    HTMLDivElement | SVGRectElement | null
  >(null);
  const [_isFixed, setIsFixed] = React.useState(false);

  const set = React.useCallback(
    (e: HTMLDivElement | SVGRectElement | null, isFixed: boolean) => {
      id.current = e?.dataset["id"] || "";
      setElement(e);
      if (isFixed !== _isFixed) {
        setIsFixed(isFixed);
      }
    },
    [_isFixed]
  );

  useWindowEventListener(
    "keydown",
    React.useCallback((e: KeyboardEvent) => {
      e.preventDefault();
      if (e.key === "Escape") {
        setElement(null);
        setIsFixed(false);
      }
    }, [])
  );

  useElementEventListener(
    eventListenerRef,
    "click",
    React.useCallback(
      (e: MouseEvent) => {
        e.preventDefault();
        set(null, false);
      },
      [set]
    )
  );

  return React.useMemo(
    () => ({
      id: id.current,
      element,
      isFixed: _isFixed,
      set
    }),
    [element, _isFixed, set]
  );
}
