import resetUidSeeds from "../schemautils/resetUidSeeds";
import { copyModel } from "../utils";
import createElement from "./createElement";
import createRelationship from "./createRelationship";
import deleteElements from "./deleteElements";
import deleteRelationship from "./deleteRelationship";
import updateElement from "./updateElement";
/**
 * Apply a modification instruction to the model.
 * @param model The model.
 * @param modificationInstruction The modification instruction to apply.
 * @returns true if updated or false if not.
 */
export default function applyModification(model, modificationInstruction) {
    const backupCopy = copyModel(model);
    let isModelUpdated = false;
    try {
        if (modificationInstruction.deleteRelationships) {
            for (const relationship of modificationInstruction.deleteRelationships) {
                isModelUpdated = deleteRelationship(model, relationship);
            }
        }
        if (modificationInstruction.deleteElements &&
            modificationInstruction.deleteElements.length > 0) {
            isModelUpdated = deleteElements(model, modificationInstruction.deleteElements.map(e => e.id));
        }
        if (modificationInstruction.updateElements) {
            for (const element of modificationInstruction.updateElements) {
                const { id, attributes } = element;
                isModelUpdated = updateElement(model, id, attributes);
            }
        }
        if (modificationInstruction.createElements) {
            for (const element of modificationInstruction.createElements) {
                const { id, metatype, attributes } = element;
                isModelUpdated = !!createElement(model, metatype, attributes, id);
            }
            if (isModelUpdated)
                resetUidSeeds(model);
        }
        if (modificationInstruction.createRelationships) {
            for (const relationship of modificationInstruction.createRelationships) {
                isModelUpdated = createRelationship(model, relationship);
            }
        }
    }
    catch (e) {
        // If an error occurs, revert the model to its previous state.
        for (const key in model) {
            model[key] = backupCopy[key];
        }
        throw e;
    }
    return isModelUpdated;
}
