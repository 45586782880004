import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import {
  AllocationItem,
  SpecificationItem,
  countAllocation,
  countRequirements
} from "@se-toolkit/model-js/analysis";
import {
  RequirementType,
  VerificationMethod
} from "@se-toolkit/model-js/schema";
import React from "react";
import { useSelector } from "react-redux";
import { selectModel } from "../../../features";
import { SETColours } from "../../../theme";
import InfoTip from "../../components/InfoTip";
import PaperPie from "../../components/PaperPie";
import { generatePieChartData } from "../utils/generatePieChartData";
import StakeholdersPie from "./StakeholdersPie";

export default function RequirementsOverview() {
  const model = useSelector(selectModel);

  const { typeCount, verificationMethodCount } = React.useMemo(
    () => countRequirements(model.requirements),
    [model.requirements]
  );
  const typesData = React.useMemo(
    () =>
      generatePieChartData(
        typeCount,
        key => SETColours.requirementType[key as RequirementType]
      ),
    [typeCount]
  );
  const verificationData = React.useMemo(
    () =>
      generatePieChartData(
        verificationMethodCount,
        key =>
          SETColours.requirementVerificationMethod[key as VerificationMethod]
      ),
    [verificationMethodCount]
  );

  const { requirementCount, componentCount } = React.useMemo(
    () => countAllocation(model.requirements, model.components),
    [model.components, model.requirements]
  );
  const allocationData = React.useMemo(
    () =>
      generatePieChartData(
        requirementCount,
        key => SETColours.requirement[key as AllocationItem]
      ),
    [requirementCount]
  );
  const specificationData = React.useMemo(
    () =>
      generatePieChartData(
        componentCount,
        key => SETColours.component[key as SpecificationItem]
      ),
    [componentCount]
  );

  return (
    <Grid
      sx={{ margin: 0, padding: 0 }}
      container={true}
      spacing={1}
      justifyContent="center"
    >
      <Grid item={true} xs={12} md={6}>
        <Stack direction="row" height="100%">
          <PaperPie title={`Requirements`} data={typesData} />
          <InfoTip>
            The requirements chart shows the number of requirements of each
            type.
            <br />
            <br />
            <b>CDRL = Contract Data Requirements List</b>
          </InfoTip>
        </Stack>
      </Grid>
      <Grid item={true} xs={12} md={6}>
        <Stack direction="row" height="100%">
          <StakeholdersPie />
          <InfoTip>
            The ownership chart shows the number of requirements that each
            stakeholder is responsible for.
          </InfoTip>
        </Stack>
      </Grid>
      <Grid item={true} xs={12} md={8}>
        <Stack direction="row" height="100%">
          <PaperPie title={`Allocation`} data={allocationData} />
          <InfoTip>
            The allocation chart shows the number of requirements that are
            allocated to components, ignoring non-system requirements.
          </InfoTip>
          <PaperPie title={`Components`} data={specificationData} />
          <InfoTip>
            The components chart shows the number of components that are
            specified by at least one system requirement.
          </InfoTip>
        </Stack>
      </Grid>
      <Grid item={true} xs={12} md={4}>
        <Stack direction="row" height="100%">
          <PaperPie title={`Verification`} data={verificationData} />
          <InfoTip>
            The verification chart shows the number of requirements with each
            verification method, ignoring CDRLs and non-system requirements.
          </InfoTip>
        </Stack>
      </Grid>
    </Grid>
  );
}
