import { createSelector } from "@reduxjs/toolkit";
import {
  Annotation as _Annotation,
  AnnotationPriority,
  AnnotationType
} from "@se-toolkit/model-js/schema";
import { findElementParents } from "@se-toolkit/model-js/search";
import React from "react";
import { useSelector } from "react-redux";
import {
  createElement,
  deleteElements,
  makeSelectModelElements,
  updateElement,
  useAppDispatch
} from "../../features";
import ElementsPopover from "../components/ElementsPopover";
import { Anchor } from "../interactions/useAnchor";
import Annotation from "./Annotation";

export interface Props {
  anchor: Anchor;
}

export default function AnnotationsPopover({ anchor }: Props) {
  const { id: elementId, isFixed: isEditable } = anchor;

  const dispatch = useAppDispatch();
  const annotations: _Annotation[] = useSelector(
    createSelector(
      makeSelectModelElements("Annotation"),
      (annotations: _Annotation[]) =>
        findElementParents(annotations, elementId, "annotates")
    )
  );

  const submitChange = React.useCallback(
    (
      id: string,
      text: string,
      type: AnnotationType,
      priority: AnnotationPriority
    ) => {
      if (!id && text) {
        dispatch(
          createElement({
            metatype: "Annotation",
            attributes: { text, type, priority },
            relateToTargetId: elementId,
            relationshipType: "annotates"
          })
        );
      } else if (id && !text) {
        dispatch(deleteElements([id]));
      } else {
        dispatch(
          updateElement({
            id,
            attributes: { text, type, priority }
          })
        );
      }
    },
    [dispatch, elementId]
  );

  const items = React.useMemo(
    () =>
      annotations.map(a => {
        const { text, type, priority, closedOn } = a.attributes;
        return (
          <Annotation
            key={a.id}
            id={a.id}
            text={text}
            type={type}
            priority={priority}
            closedOn={closedOn}
            isEditable={isEditable}
            submitChange={submitChange}
          />
        );
      }),
    [annotations, isEditable, submitChange]
  );

  return (
    <ElementsPopover anchor={anchor} isEnabled={items.length > 0 || isEditable}>
      {items}
      {isEditable ? (
        <Annotation
          id={""}
          text={""}
          type={"Action"}
          priority={"None"}
          closedOn={null}
          isEditable={true}
          submitChange={submitChange}
        />
      ) : null}
    </ElementsPopover>
  );
}
