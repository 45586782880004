import React from "react";
import useElementEventListener from "./useElementEventListener";
import useWindowEventListener from "./useWindowEventListener";

export interface ConnectorAnchor {
  sourceId: string;
  targetId: string;
  element: SVGRectElement | null;
  isConnected: boolean;
  set: (e: SVGRectElement | null, isConnected: boolean) => void;
}

export default function useConnectorAnchor(
  eventListenerRef: React.RefObject<HTMLDivElement | SVGSVGElement>
): ConnectorAnchor {
  const sourceId = React.useRef<string>("");
  const targetId = React.useRef<string>("");
  const [element, setElement] = React.useState<SVGRectElement | null>(null);
  const [_isConnected, setIsConnected] = React.useState(false);

  const set = React.useCallback(
    (e: SVGRectElement | null, isConnected: boolean) => {
      if (e === element) return;
      sourceId.current = e?.dataset["sourceid"] || "";
      targetId.current = e?.dataset["targetid"] || "";
      setElement(e);
      setIsConnected(isConnected);
    },
    [element]
  );

  useWindowEventListener(
    "keydown",
    React.useCallback((e: KeyboardEvent) => {
      e.preventDefault();
      if (e.key === "Escape") setElement(null);
    }, [])
  );

  useElementEventListener(
    eventListenerRef,
    "click",
    React.useCallback(
      (e: MouseEvent) => {
        e.preventDefault();
        set(null, false);
      },
      [set]
    )
  );

  return React.useMemo(
    () => ({
      sourceId: sourceId.current,
      targetId: targetId.current,
      element,
      isConnected: _isConnected,
      set
    }),
    [_isConnected, element, set]
  );
}
