import React from "react";
import InteractionIcon from "../../components/InteractionIcon";

export enum Type {
  Annotations = "Annotations",
  Description = "Description",
  Specification = "Specification"
}

export interface Props {
  type: Type;
  id: string;
  x?: number;
  y?: number;
  width: number;
  height: number;
  color?: string;
  isOn?: boolean;
  isFixed?: boolean;
  isEditable?: boolean;
  setAnchor?: (e: SVGRectElement | null, isClick: boolean) => void;
}

export const Interaction = ({
  type,
  id,
  x = 0,
  y = 0,
  width,
  height,
  color,
  isOn = true,
  isFixed = false,
  isEditable = true,
  setAnchor = () => {}
}: Props) => {
  const rectRef = React.useRef<SVGRectElement>(null);

  const handleClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (!isOn && !isEditable) return;
      setAnchor(e.currentTarget as SVGRectElement, true);
    },
    [isEditable, isOn, setAnchor]
  );

  const handleMouseEnter = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (!isFixed) {
        setAnchor(e.currentTarget as SVGRectElement, false);
      }
    },
    [isFixed, setAnchor]
  );

  const handleMouseLeave = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (!isFixed) {
        setAnchor(null, false);
      }
    },
    [isFixed, setAnchor]
  );

  const iconProps = {
    className: "exclude-from-export",
    width,
    height,
    x,
    y,
    htmlColor: color
  };

  return (
    <>
      {<InteractionIcon type={type} isOn={isOn} {...iconProps} />}
      <rect
        ref={rectRef}
        data-id={id}
        className="exclude-from-export"
        width={width}
        height={height}
        x={x}
        y={y}
        opacity={0}
        pointerEvents="fill"
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </>
  );
};
