import React from "react";
import { useElementEventListener } from ".";
import getEventTargetData from "./getEventTargetData";
import { Anchor } from "./useAnchor";

export default function useContextMenu(
  eventListenerRef: React.RefObject<HTMLDivElement | SVGSVGElement>,
  anchor: Anchor
): void {
  const handlePointerContextMenu = React.useCallback(
    (e: PointerEvent) => {
      e.preventDefault();
      const id = getEventTargetData(e.target, "id");
      if (!id) {
        anchor.set(null, false);
        return;
      }
      anchor.set(e.target as SVGRectElement, true);
    },
    [anchor]
  );

  const handleTouchContextMenu = React.useCallback(
    (e: TouchEvent) => {
      if (
        e.touches.length === 2 &&
        e.touches[0].target === e.touches[1].target
      ) {
        e.preventDefault();
        const id = getEventTargetData(e.target, "id");
        if (!id) {
          anchor.set(null, false);
          return;
        }
        anchor.set(e.target as SVGRectElement, true);
      }
    },
    [anchor]
  );

  useElementEventListener(
    eventListenerRef,
    "contextmenu",
    handlePointerContextMenu
  );
  useElementEventListener(
    eventListenerRef,
    "touchstart",
    handleTouchContextMenu
  );

  useElementEventListener(eventListenerRef, "pointerup", (e: PointerEvent) => {
    e.preventDefault();
    const id = getEventTargetData(e.target, "id");
    if (id !== anchor.id) {
      anchor.set(null, false);
    }
  });
}
