import LibraryAdd from "@mui/icons-material/LibraryAdd";
import { red } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import { Theme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import React from "react";
import { createNewModel, useAppDispatch } from "../../features";
import AlertDialog from "../AlertDialog";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1)
    },
    icon: {
      color: red[400]
    }
  });

export interface Props extends WithStyles<typeof styles> {}

const NewModelButton = ({ classes }: Props) => {
  const dispatch = useAppDispatch();

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setOpen(false);
    dispatch(createNewModel());
  };

  return (
    <>
      <Tooltip
        title="New"
        aria-label="new"
        placement="bottom"
        disableFocusListener={true}
      >
        <IconButton
          className={classes.root}
          aria-label="new"
          onClick={handleClick}
          size="large"
        >
          <LibraryAdd className={classes.icon} />
        </IconButton>
      </Tooltip>
      <AlertDialog
        question="Discard the current model and create a new one?"
        explanation="Creates a new empty model. The current model will be lost so be sure that you have exported it before creating a new one."
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default withStyles(styles, { withTheme: true })(NewModelButton);
