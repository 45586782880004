import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import {
  DefaultizedPieValueType,
  PieValueType
} from "@mui/x-charts/models/seriesType/pie";
import React from "react";
import { SvgText } from "./SvgText";

function PieCenterLabel({ children }: { children: React.ReactNode }) {
  const theme = useTheme();
  const { width, height, left, top } = useDrawingArea();
  return (
    <SvgText
      color={theme.palette.text.primary}
      x={left + width / 4}
      y={top}
      width={width / 2}
      height={height}
    >
      {children}
    </SvgText>
  );
}

export interface Props {
  title: string;
  data: PieValueType[];
  arcLabel?: (item: DefaultizedPieValueType) => string;
}

export default function PaperPie({ title, data, arcLabel }: Props) {
  const total = React.useMemo(
    () => data.reduce((acc, item) => acc + item.value, 0),
    [data]
  );
  const defaultArcLabel = React.useCallback(
    (item: DefaultizedPieValueType) => {
      const percent = Math.round((item.value / total) * 100);
      return `${percent}%`;
    },
    [total]
  );
  arcLabel = arcLabel || defaultArcLabel;

  const valueFormatter = React.useCallback(
    (item: DefaultizedPieValueType) => `${item.value} of ${total}`,
    [total]
  );

  const sizing = {
    innerRadius: "50%",
    paddingAngle: 1,
    cornerRadius: 4
  };

  return (
    <Paper
      sx={{
        position: "relative",
        height: "100%",
        width: "100%"
      }}
    >
      <Box
        sx={{
          bottom: 0,
          left: 0,
          position: "absolute",
          right: 0,
          top: 0
        }}
      >
        <PieChart
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: "white"
            }
          }}
          series={[
            {
              data,
              arcLabel,
              arcLabelMinAngle: 5,
              valueFormatter,
              ...sizing
            }
          ]}
          slotProps={{
            legend: {
              direction: "column",
              position: { vertical: "middle", horizontal: "right" },
              padding: 0,
              labelStyle: {
                fontSize: "0.6em"
              }
            }
          }}
        >
          <PieCenterLabel>{title}</PieCenterLabel>
        </PieChart>
      </Box>
    </Paper>
  );
}
