import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import {
  Navigate,
  Route,
  Link as RouterLink,
  Routes,
  useLocation
} from "react-router-dom";
import ConfigContextProvider from "./utils/ConfigContextProvider";
import ErrorPage from "./utils/ErrorPage";
import DashBoardView from "./views/dashboard/DashboardView";
import InterfaceDesignView from "./views/interfacedesign/InterfaceDesignView";
import RequirementsView from "./views/requirements/RequirementsView";
import RoadmapView from "./views/roadmap/RoadmapView";
import SystemStructureView from "./views/systemstructure/SystemStructureView";
import { TabRoute } from "./views/utils/useSubTabRouting";

export default function TabBar() {
  const theme = useTheme();

  const tabRoutes: TabRoute[] = [
    {
      label: "Dashboard",
      path: "/dashboard",
      element: <DashBoardView />
    },
    {
      label: "Requirements",
      path: "/requirements",
      element: <RequirementsView />
    },
    {
      label: "Structure",
      path: "/structure",
      element: <SystemStructureView />
    },
    {
      label: "Interfaces",
      path: "/interfaces",
      element: <InterfaceDesignView />
    },
    {
      label: "Roadmap",
      path: "/roadmap",
      element: <RoadmapView />
    }
  ];

  const defaultTab = tabRoutes[0].path;

  const location = useLocation();
  const currentTab =
    tabRoutes.find(tabRoute => location.pathname.includes(tabRoute.path))
      ?.path || defaultTab;

  const handleChange = React.useCallback(
    (e: React.SyntheticEvent, value: string) => {
      window.dispatchEvent(new CustomEvent("changeview", { detail: value }));
    },
    []
  );

  const tabs = tabRoutes.map(({ label, path }) => (
    <Tab
      key={path}
      label={label}
      component={RouterLink}
      to={path}
      value={path}
    />
  ));

  const routes = Object.values(tabRoutes).map(({ path, element }) => (
    <Route key={path} path={`${path}/*`} element={element} />
  ));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1
      }}
    >
      <AppBar position="static">
        <Tabs
          sx={{ backgroundColor: "primary.main" }}
          value={currentTab}
          indicatorColor="secondary"
          textColor="inherit"
          onChange={handleChange}
        >
          {tabs}
        </Tabs>
      </AppBar>
      <ConfigContextProvider>
        <Box sx={{ position: "relative", flexGrow: 1 }}>
          <ErrorBoundary FallbackComponent={ErrorPage} resetKeys={[currentTab]}>
            <Box
              sx={{
                bottom: 0,
                left: 0,
                padding: theme.spacing(1),
                position: "absolute",
                right: 0,
                top: 0
              }}
            >
              <Routes>
                <Route index={true} element={<Navigate to={defaultTab} />} />
                {routes}
              </Routes>
            </Box>
          </ErrorBoundary>
        </Box>
      </ConfigContextProvider>
    </Box>
  );
}
