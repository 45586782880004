/**
 * Copy an element.
 * @param element The element to be copied.
 * @returns A deep copy of the element.
 */
export default function copyElement(element) {
    return {
        ...element,
        changeLog: element.changeLog.map(c => ({ ...c })),
        relationships: element.relationships.map(r => ({ ...r })),
        attributes: { ...element.attributes }
    };
}
