import React from "react";
import {
  deleteElements,
  enqueueSnackbar,
  useAppDispatch
} from "../../features";
import { useWindowEventListener } from "../interactions";

export default function useDeleteElements(ids: string[]) {
  const dispatch = useAppDispatch();

  const handleDelete = React.useCallback(() => {
    if (ids.length === 0) {
      dispatch(
        enqueueSnackbar(`Please select one or more elements to delete`, {
          variant: "info"
        })
      );
      return;
    }

    dispatch(deleteElements(ids));
    window.dispatchEvent(new CustomEvent("selectnone"));
  }, [dispatch, ids]);

  useWindowEventListener("deleteelements", handleDelete);
}
