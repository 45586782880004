import { createDefaultSheetSelection } from "./SheetSelection";
/**
 * @param workbook The xlsx workbook to be imported.
 * @param sheetSelection The sheets to be imported. If empty, all sheets are included.
 * @returns true if the sheet selection was modified or false if not and an array of error strings.
 */
export default function refineSheetSelection(workbook, sheetSelection) {
    const dSS = createDefaultSheetSelection();
    let isSelectionUpdated = false;
    const errors = [];
    if (sheetSelection.length === 0) {
        for (const sheetName of workbook.SheetNames) {
            const sheet = dSS.find(ss => ss[0] === sheetName);
            if (sheet) {
                sheetSelection.push(sheet);
                isSelectionUpdated = true;
            }
            else {
                errors.push(`Failed to import sheet "${sheetName}" from workbook. The corresponding sheet type does not exist.`);
            }
        }
        return [isSelectionUpdated, errors];
    }
    for (let i = sheetSelection.length - 1; i >= 0; i--) {
        const [sheetName, sheetType] = sheetSelection[i];
        if (!sheetName) {
            if (workbook.SheetNames.length === 1) {
                sheetSelection[i][0] = workbook.SheetNames[0];
                isSelectionUpdated = true;
                continue;
            }
            const sheet = dSS.find(ss => ss[1] === sheetType);
            if (sheet && workbook.SheetNames.includes(sheet[0])) {
                sheetSelection[i][0] = sheet[0];
                isSelectionUpdated = true;
                continue;
            }
            errors.push(`Failed to import unnamed sheet with type ${sheetType} from workbook. A corresponding sheet does not exist.`);
            sheetSelection.splice(i, 1);
            isSelectionUpdated = true;
            continue;
        }
        if (!workbook.SheetNames.includes(sheetName)) {
            errors.push(`Failed to import sheet "${sheetName}" from workbook. The sheet does not exist.`);
            sheetSelection.splice(i, 1);
            isSelectionUpdated = true;
        }
    }
    return [isSelectionUpdated, errors];
}
