import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

export interface Props {
  children: string | React.ReactNode;
}

export default function InfoTip({ children }: Props) {
  return (
    <Box sx={{ position: "relative", width: 0, height: 0 }}>
      <Tooltip placement="bottom-end" title={children}>
        <InfoIcon sx={{ position: "absolute", right: 10, top: 10 }} />
      </Tooltip>
    </Box>
  );
}
