import { findElement } from "../search";
/**
 * Count the requirements that are allocated to components and components that
 * are specified by requirements
 * @returns A count of the following items:
 * - allocatedRequirements: The number of requirements that are allocated to components, ignoring non-system requirements
 * - unallocatedRequirements: The number of requirements that are not allocated to components, ignoring non-system requirements
 * - specifiedComponents: The number of components that are specified by requirements
 * - unspecifiedLeafComponents: The number of leaf components that are not specified by requirements
 * - unspecifiedNonLeafComponents: The number of non-leaf components that are not specified by requirements
 */
export default function countAllocation(requirements, components) {
    const systemRequirements = requirements.filter(r => r.attributes.type !== "NonSystem");
    const allocatedRequirements = new Set();
    const specifiedComponents = new Set();
    const unspecifiedLeafComponents = new Set();
    const unspecifiedNonLeafComponents = new Set();
    for (const c of components) {
        let isSpecified = false;
        for (const r of c.relationships) {
            if (r.type === "specified_by" &&
                findElement(systemRequirements, r.targetId)) {
                allocatedRequirements.add(r.targetId);
                specifiedComponents.add(c.id);
                isSpecified = true;
            }
        }
        if (!isSpecified) {
            if (c.relationships.find(r => r.type === "built_from")) {
                unspecifiedNonLeafComponents.add(c.id);
                continue;
            }
            unspecifiedLeafComponents.add(c.id);
        }
    }
    const requirementItems = new Map();
    requirementItems.set("Allocated", allocatedRequirements.size);
    requirementItems.set("NotAllocated", systemRequirements.length - allocatedRequirements.size);
    const componentItems = new Map();
    componentItems.set("Specified", specifiedComponents.size);
    componentItems.set("UnspecifiedLeaf", unspecifiedLeafComponents.size);
    componentItems.set("UnspecifiedNonLeaf", unspecifiedNonLeafComponents.size);
    return {
        requirementCount: {
            items: requirementItems,
            total: systemRequirements.length
        },
        componentCount: {
            items: componentItems,
            total: components.length
        }
    };
}
