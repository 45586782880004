import { GridActionsCellItem, GridRowParams } from "@mui/x-data-grid";
import React from "react";
import InteractionIcon, { Type } from "../../components/InteractionIcon";
import { Anchor } from "../../interactions/useAnchor";

export default function useRenderInteractionIcon(
  type: Type,
  anchor: Anchor,
  switchKey: string,
  isEditable: boolean = true
) {
  const handleClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      anchor.set(e.currentTarget as HTMLDivElement, true);
    },
    [anchor]
  );

  const handleMouseEnter = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (!anchor.isFixed) {
        anchor.set(e.currentTarget as HTMLDivElement, false);
      }
    },
    [anchor]
  );

  const handleMouseLeave = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (!anchor.isFixed) {
        anchor.set(null, false);
      }
    },
    [anchor]
  );

  return React.useCallback(
    (params: GridRowParams) => {
      return [
        <GridActionsCellItem
          data-id={params.row.id}
          icon={<InteractionIcon type={type} isOn={!!params.row[switchKey]} />}
          label={type}
          onClick={(e: React.MouseEvent) => {
            if (!params.row[switchKey] && !isEditable) return;
            handleClick(e);
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      ];
    },
    [
      handleClick,
      handleMouseEnter,
      handleMouseLeave,
      isEditable,
      switchKey,
      type
    ]
  );
}
