import findElementInModel from "../search/findElementInModel";
import getTimeStamp from "../utils/getTimeStamp";
import sortRelationships from "../utils/sortRelationships";
import validateRelationship from "../validators/validateRelationship";
/**
 * Move relationships to a new source element.
 * @param model The model.
 * @param relationships The relationships to move.
 * @param newSourceId The id of the new source element of the relationship.
 * @returns true if the model was modified or false if not.
 */
export default function moveRelationships(model, relationships, newSourceId) {
    let isModelModified = false;
    relationships.forEach(relationship => {
        const { sourceElement: newSourceElement, targetElement } = validateRelationship(model, {
            ...relationship,
            sourceId: newSourceId
        });
        if (relationship.sourceId) {
            const sourceElement = findElementInModel(model, relationship.sourceId);
            if (!sourceElement) {
                throw new Error(`Failed to move relationship. The current source element: ${relationship.sourceId}, does not exist.`);
            }
            // confirm that the relationships exists
            const relationshipIndex = sourceElement.relationships.findIndex(r => r.targetId === relationship.targetId && r.type === relationship.type);
            if (relationshipIndex < 0) {
                throw new Error(`Failed to move relationship. The current source element: ${relationship.sourceId}, does not have a ${relationship.type} relationship with ${relationship.targetId}`);
            }
            sourceElement.relationships.splice(relationshipIndex, 1);
            isModelModified = true;
            model.changeLog.push({
                time: getTimeStamp(),
                operation: "update",
                description: `Moved ${relationship.type} relationship with ${relationship.targetId} from ${relationship.sourceId} to ${newSourceId}.`
            });
        }
        // confirm that the new relationship doesn't exist already
        if (newSourceElement.relationships.find(r => r.targetId === relationship.targetId && r.type === relationship.type))
            return;
        newSourceElement.relationships.push({
            targetId: relationship.targetId,
            type: relationship.type
        });
        sortRelationships(newSourceElement.relationships);
        isModelModified = true;
        if (!relationship.sourceId) {
            const targetName = "name" in targetElement.attributes
                ? ` (${targetElement.attributes.name})`
                : "";
            newSourceElement.changeLog.push({
                time: getTimeStamp(),
                operation: "create",
                description: `Created ${relationship.type} relationship with ${relationship.targetId}${targetName}.`
            });
            return;
        }
    });
    return isModelModified;
}
