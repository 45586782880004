import md5 from "md5";
import { SCHEMA_VERSION } from "../schema";
export const MODEL_ID_PATTERN = /^setm[0-9]{4}[0-9]{6}[a-z0-9]{8}$/i;
/*
 * Generate a unique model identifier.
 * Format: setm<version><year><month><8 char uid>
 * Version: <major><minor> (padded to two chars each)
 * Example: setm0013202301abcdefgh
 */
export default function generateModelId() {
    let version = SCHEMA_VERSION.split(".");
    const major = version[0].padStart(2, "0");
    const minor = version[1].padStart(2, "0");
    const date = new Date();
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const alphabet = "abcdefghijklmnopqrstuvwxyz";
    const firstLetter = alphabet[Math.floor(Math.random() * alphabet.length)];
    const timestamp = Date.now().toString();
    const pseudoRandomNumber = (Math.random() * 1000).toString();
    const hash = md5(`${firstLetter}${timestamp}${pseudoRandomNumber}`);
    const letter = hash.match(/[a-z]/i);
    const randomString = `${letter}${hash.substring(1, 4)}${hash.substring(hash.length - 4)}`;
    return `setm${major}${minor}${year}${month}${randomString}`;
}
