import { ElementMetatype, SeedTypes } from "../schema";
import getAllElements from "../schemautils/getAllElements";
import getElementsArray from "../schemautils/getElementsArray";
import getTimeStamp from "../utils/getTimeStamp";
/**
 * Delete elements from the model.
 * @param model The model.
 * @param ids An array of element ids to be deleted.
 * @returns true if the model was updated or false if not.
 * Relationships are also deleted.
 */
export default function deleteElements(model, ids) {
    if (ids.length === 0)
        return false;
    const deletedIds = [];
    ids.forEach(id => {
        ElementMetatype.options.forEach(metatype => {
            const elements = getElementsArray(model, metatype);
            const index = elements.findIndex(a => a.id === id);
            if (index === -1)
                return;
            const attributes = elements.splice(index, 1)[0].attributes;
            const hasSubtype = "type" in attributes && SeedTypes.safeParse(attributes.type).success;
            const type = (hasSubtype ? attributes.type : metatype);
            const name = "name" in attributes ? ` (${attributes.name})` : "";
            model.changeLog.push({
                time: getTimeStamp(),
                operation: "delete",
                description: `Deleted ${hasSubtype ? `${type.toLowerCase()} ` : ""}${metatype.toLowerCase()}: ${id}${name}.`
            });
            deletedIds.push(id);
        });
    });
    if (deletedIds.length) {
        getAllElements(model).forEach(e => {
            for (let i = e.relationships.length - 1; i >= 0; i--) {
                if (deletedIds.includes(e.relationships[i].targetId)) {
                    e.relationships.splice(i, 1);
                }
            }
        });
    }
    return deletedIds.length > 0;
}
