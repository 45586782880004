import { RequirementType, VerificationMethod } from "../schema";
/**
 * Count requirements types and verification methods
 * @returns A count of the following items:
 * - types: A map of requirement types to the number of requirements of that type
 * - verificationMethods: A map of verification methods to the number of
 *   requirements that use that method, ignoring CDRLs and non-system requirements
 */
export default function countRequirements(requirements) {
    const types = new Map();
    RequirementType.options.forEach(key => {
        types.set(key, 0);
    });
    const verificationMethods = new Map();
    VerificationMethod.options.forEach(key => {
        verificationMethods.set(key, 0);
    });
    let vmTotal = 0;
    for (const r of requirements) {
        const { type, verificationMethod } = r.attributes;
        if (RequirementType.options.includes(type)) {
            types.set(type, types.get(type) + 1);
        }
        if (type === "CDRL" || type === "NonSystem")
            continue;
        vmTotal++;
        if (VerificationMethod.options.includes(verificationMethod)) {
            verificationMethods.set(verificationMethod, verificationMethods.get(verificationMethod) + 1);
        }
    }
    return {
        typeCount: {
            items: types,
            total: requirements.length
        },
        verificationMethodCount: {
            items: verificationMethods,
            total: vmTotal
        }
    };
}
