/**
 * Get all elements in the model.
 * @param model The model.
 * @returns An array containing references to all elements in the model alphabetically sorted by id.
 */
export default function getAllElements(model) {
    return [
        ...model.annotations,
        ...model.components,
        ...model.milestones,
        ...model.requirements,
        ...model.stakeholders
    ].sort((a, b) => {
        return a.id.localeCompare(b.id, undefined, { numeric: true });
    });
}
