import { MilestoneStatus } from "@se-toolkit/model-js/schema";
import React from "react";
import { updateElement, useAppDispatch } from "../../features";

export default function useUpdateMilestoneStatus() {
  const dispatch = useAppDispatch();

  const setStatus = React.useCallback(
    (id: string, status: MilestoneStatus) => {
      dispatch(updateElement({ id, attributes: { status } }));
    },
    [dispatch]
  );

  const decrementStatus = React.useCallback(
    (id: string, status: MilestoneStatus) => {
      switch (status) {
        case undefined:
        case "NotStarted":
          setStatus(id, "InProgress");
          break;
        case "Aborted":
        case "InProgress":
          setStatus(id, "NotStarted");
          break;
        case "Blocked":
          setStatus(id, "Aborted");
          break;
      }
    },
    [setStatus]
  );

  const incrementStatus = React.useCallback(
    (id: string, status: MilestoneStatus) => {
      switch (status) {
        case "InProgress":
          setStatus(id, "Finished");
          break;
        case "Finished":
          setStatus(id, "OnHold");
          break;
        case "OnHold":
          setStatus(id, "Blocked");
          break;
        case "Blocked":
          setStatus(id, "InProgress");
          break;
      }
    },
    [setStatus]
  );

  return {
    decrementStatus,
    incrementStatus
  };
}
