import parseSheet from "./parseSheet";
export const columnHeaders = ["ID", "Component Name", "Type", "Description"];
export default function parseStructureSheet(worksheet) {
    const allocateAttributes = (attributes, key, value) => {
        switch (key) {
            case "Component Name":
                attributes["name"] = value.trim();
                break;
            default:
                attributes[key.toLowerCase()] = value;
                break;
        }
    };
    try {
        return parseSheet("Component", worksheet, columnHeaders, allocateAttributes);
    }
    catch (error) {
        throw new Error(`Failed to parse structure sheet. ${error.message}`);
    }
}
