import { ElementRelationshipTypes, Relationship } from "../schema";
import findElementInModel from "../search/findElementInModel";
import isCircularRelationship from "./isCircularRelationship";
/**
 * Validate a relationship.
 * @param model The model.
 * @param relationship The relationship to be validated.
 * @returns The source element.
 */
export default function validateRelationship(model, relationship) {
    Relationship.parse(relationship);
    const { sourceId, targetId, type } = relationship;
    const sourceElement = findElementInModel(model, sourceId);
    if (!sourceElement) {
        throw new Error(`Invalid relationship. The source element: ${sourceId}, does not exist.`);
    }
    const targetElement = findElementInModel(model, targetId);
    if (!targetElement) {
        throw new Error(`Invalid relationship. The target element: ${targetId}, does not exist.`);
    }
    if (sourceId === targetId) {
        throw new Error(`Invalid relationship. The source and target are the same element: ${sourceId}.`);
    }
    if (!ElementRelationshipTypes[sourceElement.metatype].sourceOf.includes(type)) {
        throw new Error(`Invalid relationship. The source element: which is of metatype ${sourceElement.metatype}, may not have a relationship of type ${type}.`);
    }
    if (!ElementRelationshipTypes[targetElement.metatype].targetOf.includes(type)) {
        throw new Error(`Invalid relationship. The target element: which is of metatype ${targetElement.metatype}, may not have a relationship of type ${type}.`);
    }
    if (isCircularRelationship(model, relationship)) {
        throw new Error(`Invalid relationship. The source element: ${sourceId}, is a descendant of the target element: ${targetId}, which would result in a circular relationship.`);
    }
    return { sourceElement, targetElement };
}
