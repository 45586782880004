import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import RemoveDoneIcon from "@mui/icons-material/RemoveDone";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import {
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridRowClassNameParams,
  GridRowModel,
  GridRowParams,
  GridRowsProp
} from "@mui/x-data-grid";
import {
  AnnotationPriority,
  AnnotationType
} from "@se-toolkit/model-js/schema";
import {
  getAllElements,
  getTargetMetatypes
} from "@se-toolkit/model-js/schemautils";
import { findElement, findElementParents } from "@se-toolkit/model-js/search";
import { getTimeStamp } from "@se-toolkit/model-js/utils";
import React from "react";
import { useSelector } from "react-redux";
import {
  deleteElements,
  enqueueSnackbar,
  makeSelectModelElements,
  replaceRelationship,
  selectModel,
  updateElement,
  updateElementId,
  useAppDispatch
} from "../../../features";
import { SETColours } from "../../../theme";
import PaperTable from "../../components/PaperTable";
import StakeholderMenu from "../../composition/StakeholderMenu";
import ElementPickerPopover from "../controls/ElementPickerPopover";
import useDateTimeFormatter from "../gridutils/useDateTimeFormatter";
import useNumberStringComparator from "../gridutils/useNumberStringComparator";
import usePreProcessEditID from "../gridutils/usePreProcessEditID";
import usePriorityComparator from "../gridutils/usePriorityComparator";
import useRenderStakeholder from "../gridutils/useRenderStakeholder";

export interface Props {
  mode?: AnnotationType;
}

export default function AnnotationsTable({ mode }: Props) {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const model = useSelector(selectModel);
  const annotations = React.useMemo(
    () =>
      mode
        ? model.annotations.filter(a => a.attributes.type === mode)
        : model.annotations,
    [mode, model.annotations]
  );
  const annotationTargets = React.useMemo(() => {
    const targetMetatypes = getTargetMetatypes("annotates");
    const elements = getAllElements(model);
    return elements.filter(e => targetMetatypes.includes(e.metatype));
  }, [model]);
  const stakeholders = useSelector(makeSelectModelElements("Stakeholder"));

  const rows: GridRowsProp = React.useMemo(
    () =>
      annotations.map(a => {
        const stakeholder = findElementParents(
          stakeholders,
          a.id,
          "responsible_for"
        )[0];
        const elementId =
          a.relationships.find(r => r.type === "annotates")?.targetId || "";
        const element = findElement(annotationTargets, elementId);
        const elementName =
          element && "name" in element.attributes
            ? element.attributes.name
            : "";
        const elementOwner = elementId
          ? findElementParents(stakeholders, elementId, "responsible_for")[0]
              ?.attributes.name
          : "";
        return {
          id: a.id,
          ...a.attributes,
          stakeholderId: stakeholder?.id,
          elementName,
          elementId,
          elementOwner
        };
      }),
    [annotations, annotationTargets, stakeholders]
  );

  const preProcessEditID = usePreProcessEditID(annotations);

  const processRowUpdate = React.useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel): GridRowModel => {
      let isUpdated = false;
      if (newRow.id && newRow.id !== oldRow.id) {
        dispatch(
          updateElementId({
            oldId: oldRow.id,
            newId: newRow.id
          })
        );
        isUpdated = true;
      }
      if (newRow.type && newRow.type !== oldRow.type) {
        dispatch(
          updateElement({
            id: oldRow.id,
            attributes: { type: newRow.type }
          })
        );
        isUpdated = true;
      }
      const oldText = oldRow.text.trim();
      const newText = newRow.text.trim();
      if (newText && newText !== oldText) {
        dispatch(
          updateElement({
            id: oldRow.id,
            attributes: { text: newText }
          })
        );
        isUpdated = true;
      }
      if (newRow.priority && newRow.priority !== oldRow.priority) {
        dispatch(
          updateElement({
            id: oldRow.id,
            attributes: { priority: newRow.priority }
          })
        );
        isUpdated = true;
      }
      if (newRow.remarks !== oldRow.remarks) {
        dispatch(
          updateElement({
            id: oldRow.id,
            attributes: { remarks: newRow.remarks }
          })
        );
        isUpdated = true;
      }
      return isUpdated ? newRow : oldRow;
    },
    [dispatch]
  );

  const renderActions = React.useCallback(
    (params: GridRowParams) => {
      const closedText = `${params.row.closedOn ? "Reopen" : "Close"} ${
        params.row.id
      }`;
      return [
        <GridActionsCellItem
          icon={
            <Tooltip title={closedText} placement="left">
              {params.row.closedOn ? <RemoveDoneIcon /> : <DoneIcon />}
            </Tooltip>
          }
          label={closedText}
          onClick={() => {
            if (!params.row.closedOn && !params.row.remarks) {
              dispatch(
                enqueueSnackbar(
                  `It is recommended to provide a rationale for closing ${params.row.id} in the remarks.`,
                  {
                    variant: "info"
                  }
                )
              );
            }
            dispatch(
              updateElement({
                id: params.row.id,
                attributes: {
                  closedOn: params.row.closedOn ? null : getTimeStamp()
                }
              })
            );
          }}
        />,
        <ElementPickerPopover
          elements={annotationTargets}
          tooltipText={`Move ${params.row.id} to another element`}
          handleSubmit={(newTargetId: string) => {
            const oldTargetId = params.row.elementId;
            if (newTargetId === oldTargetId) return;
            dispatch(
              replaceRelationship({
                relationship: {
                  sourceId: params.row.id,
                  targetId: oldTargetId,
                  type: "annotates"
                },
                newTargetId
              })
            );
          }}
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title={`Delete ${params.row.id}`} placement="left">
              <DeleteIcon />
            </Tooltip>
          }
          label="Delete"
          onClick={() => dispatch(deleteElements([params.row.id]))}
        />
      ];
    },
    [dispatch, annotationTargets]
  );

  const priorityComparator = usePriorityComparator();
  const renderOwner = useRenderStakeholder(stakeholders);
  const numberStringComparator = useNumberStringComparator();
  const valueFormatterDateTime = useDateTimeFormatter({ showTime: false });

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        editable: true,
        sortComparator: numberStringComparator,
        preProcessEditCellProps: preProcessEditID
      },
      {
        field: "type",
        type: "singleSelect",
        headerName: "Type",
        valueOptions: AnnotationType.options,
        editable: true
      },
      {
        field: "text",
        headerName: `${mode || "Annotation"}`,
        flex: 1,
        editable: true
      },
      {
        field: "priority",
        type: "singleSelect",
        headerName: "Priority",
        valueOptions: AnnotationPriority.options,
        editable: true,
        cellClassName: (params: GridCellParams<any, number>) =>
          !params.row.closedOn
            ? `annotation-priority--${params.row.priority.toLowerCase()}`
            : "",
        valueGetter: (params: GridCellParams<any, string>) =>
          params.row.closedOn ? "Closed" : params.value,
        sortComparator: priorityComparator
      },
      {
        field: "stakeholderId",
        headerName: "Owner",
        width: 150,
        renderCell: renderOwner
      },
      {
        field: "remarks",
        headerName: "Remarks",
        flex: 1,
        editable: true
      },
      {
        field: "closedOn",
        type: "date",
        headerName: "Closed On",
        valueFormatter: valueFormatterDateTime
      },
      {
        field: "elementName",
        headerName: "Element Name",
        width: 150
      },
      {
        field: "elementId",
        headerName: "Element ID"
      },
      {
        field: "elementOwner",
        headerName: "Element Owner",
        width: 150
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 120,
        getActions: renderActions
      }
    ],
    [
      numberStringComparator,
      preProcessEditID,
      mode,
      priorityComparator,
      renderOwner,
      valueFormatterDateTime,
      renderActions
    ]
  );

  const getRowClassName = React.useCallback(
    (params: GridRowClassNameParams<any>): string => {
      const priorityClass = params.row.closedOn
        ? " annotation-priority--closed"
        : "";
      if (params.row.isHighlighted) return `highlight${priorityClass}`;
      return `${
        params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
      }${priorityClass}`;
    },
    []
  );

  const gridRef = React.useRef<HTMLDivElement>(null);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        "& .annotation-priority--closed": {
          color: theme.palette.grey[500]
        },
        "& .annotation-priority--low": {
          backgroundColor: SETColours.annotationPriority.Low,
          color: theme.palette.common.white
        },
        "& .annotation-priority--medium": {
          backgroundColor: SETColours.annotationPriority.Medium,
          color: theme.palette.common.white
        },
        "& .annotation-priority--high": {
          backgroundColor: SETColours.annotationPriority.High,
          color: theme.palette.common.white
        }
      }}
    >
      <PaperTable
        tableName={`Dashboard.Annotations.${mode}`}
        gridRef={gridRef}
        title={`${
          mode
            ? mode === "Opportunity"
              ? "Opportunities"
              : `${mode}s`
            : "Annotations"
        }`}
        rows={rows}
        columns={columns}
        isCellEditable={params => !params.row.closedOn}
        processRowUpdate={processRowUpdate}
        initialState={{
          columns: {
            columnVisibilityModel: {
              type: !mode,
              elementOwner: false
            }
          },
          pagination: {
            paginationModel: {
              pageSize: 5
            }
          },
          sorting: {
            sortModel: [{ field: "priority", sort: "desc" }]
          }
        }}
        getRowClassName={getRowClassName}
      />
      <StakeholderMenu
        eventListenerRef={gridRef}
        relationshipType={"responsible_for"}
      />
    </Box>
  );
}
