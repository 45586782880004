import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import undoable from "redux-undo";
import { saveState } from "../utils/persistState";
import { modelListenerMiddleware } from "./model/modelMiddleware";
import modelReducer from "./model/modelSlice";
import notificationsReducer from "./notifications/notificationsSlice";

export const reducer = {
  model: undoable(modelReducer),
  notifications: notificationsReducer
};

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().prepend(modelListenerMiddleware.middleware)
});

store.subscribe(() => {
  const { model } = store.getState().model.present;
  saveState(model.id, model);
  localStorage.setItem("modelId", model.id);
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export type RootState = ReturnType<typeof store.getState>;

export default store;

export const setupTestStore = (preloadedState?: RootState) => {
  return configureStore({
    reducer,
    preloadedState
  });
};
