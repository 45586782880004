import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { HierarchyNode } from "@se-toolkit/model-js/analysis";
import React from "react";
import {
  Connector,
  LayoutProperties,
  subtractPositions
} from "../../../layouts";
import AnnotationsPopover from "../../composition/AnnotationsPopover";
import DescriptionPopover from "../../composition/DescriptionPopover";
import Diagram from "../../diagrams/Diagram";
import { ZConnector } from "../../diagrams/shapes/ZConnector";
import useInsertRelationship from "../../featurehooks/useInsertRelationship";
import useMoveRelationships from "../../featurehooks/useMoveRelationships";
import useToggleRelationship from "../../featurehooks/useToggleRelationship";
import {
  getEventTargetData,
  useAnchor,
  useElementEventListener,
  useLinkSourceToTarget,
  usePan,
  useZoom
} from "../../interactions";
import DynamicLink from "../controls/DynamicLink";
import SaveAsImageButton from "../controls/SaveAsImageButton";
import SaveAsSVGButton from "../controls/SaveAsSVGButton";
import useDrawMilestone from "../utils/useDrawMilestone";
import useHierarchyLayout from "../utils/useHierarchyLayout";
import layoutProperties from "./layoutProperties.json";

export interface Props {
  hierarchy: HierarchyNode[];
  selectedIds: string[];
  svgRef: React.RefObject<SVGSVGElement>;
  initialFileName?: string;
  onPointerUp?: (e: React.PointerEvent) => void;
  onTouchEnd?: (e: React.TouchEvent) => void;
}

export default function RoadmapDiagram({
  hierarchy,
  selectedIds,
  svgRef,
  initialFileName = "Roadmap",
  onPointerUp = () => {},
  onTouchEnd = () => {}
}: Props) {
  const diagramRef = React.useRef<HTMLDivElement>(null);

  const diagramLayout = useHierarchyLayout({
    hierarchy,
    layoutProperties: layoutProperties.hierarchy as LayoutProperties,
    mirrorHorizontally: true
  });

  const descriptionAnchor = useAnchor(diagramRef);
  const annotationsAnchor = useAnchor(diagramRef);

  useElementEventListener(
    diagramRef,
    "dblclick",
    React.useCallback((e: MouseEvent) => {
      e.preventDefault();
      const id = getEventTargetData(e.target, "id");
      if (id) {
        window.dispatchEvent(new CustomEvent("updateelements"));
      }
    }, [])
  );

  const linkSourceToTarget = useToggleRelationship("preceded_by");
  const moveSourceToTarget = useMoveRelationships(hierarchy, "preceded_by");
  const insertSourceAtConnector = useInsertRelationship(
    hierarchy,
    "preceded_by"
  );
  const { isLinkActive, onLinkStart, onLinkFinish, onLinkCancel } =
    useLinkSourceToTarget({
      diagramSvgRef: svgRef,
      hierarchyLayout: diagramLayout,
      linkSourceToTarget,
      moveSourceToTarget,
      insertSourceAtConnector
    });

  usePan(svgRef, { multiplier: 1.1 });
  useZoom(svgRef, { multiplier: 3.0 });

  const drawShape = useDrawMilestone({
    selectedIds,
    descriptionAnchor,
    annotationsAnchor
  });

  const drawConnector = React.useCallback(
    (connector: Connector) => {
      return (
        <ZConnector
          connector={{
            ...connector,
            p2: subtractPositions(connector.p2, {
              x: layoutProperties.hierarchy.nodeWidth / 20,
              y: 0
            })
          }}
          orientation={diagramLayout.orientation}
          isBezier={true}
          arrowSize={7.5}
          highlightOnHover={isLinkActive}
        />
      );
    },
    [diagramLayout.orientation, isLinkActive]
  );

  return (
    <Paper
      ref={diagramRef}
      sx={{
        position: "relative",
        height: "100%"
      }}
      onPointerUp={onPointerUp}
      onTouchEnd={onTouchEnd}
    >
      <Diagram
        svgRef={svgRef}
        layout={diagramLayout}
        drawShape={drawShape}
        drawConnector={drawConnector}
      />
      <DynamicLink
        eventListenerRef={diagramRef}
        onLinkStart={onLinkStart}
        onLinkFinish={onLinkFinish}
        onLinkCancel={onLinkCancel}
      />
      <DescriptionPopover anchor={descriptionAnchor} />
      <AnnotationsPopover anchor={annotationsAnchor} />
      <Box sx={{ position: "absolute", top: 16, right: 16 }}>
        <SaveAsSVGButton svgRef={svgRef} initialFileName={initialFileName} />
        <SaveAsImageButton svgRef={svgRef} initialFileName={initialFileName} />
      </Box>
    </Paper>
  );
}
