import { AnyElement } from "@se-toolkit/model-js/schema";
import {
  Connector,
  Layout,
  LayoutProperties,
  Orientation,
  Shape
} from "./Layout";
import {
  calculateMaxHeight,
  calculateMaxWidth
} from "./utils/calculateMaxDimensions";
import createCalculatorFnOfIndex from "./utils/createCalculatorFnOfIndex";

/**
 * Create a matrix layout
 * @param elements An array of model elements.
 * @param layoutProperties The layout properties describing how to layout the nodes.
 * @param invertRelationships Invert the relationships (source becomes target and vice versa).
 * @returns A Layout object containing shapes as well as the total width and height of the layout.
 */
export default function createMatrixLayout(
  elements: AnyElement[],
  layoutProperties: LayoutProperties,
  invertRelationships = false
): Layout {
  const calculateXPosition = createCalculatorFnOfIndex(
    layoutProperties.nodeWidth,
    layoutProperties.horizontalSpacing
  );

  const calculateYPosition = createCalculatorFnOfIndex(
    layoutProperties.nodeHeight,
    layoutProperties.verticalSpacing
  );

  const shapes: Shape[] = [];
  elements.forEach((element, index) => {
    const position = {
      x: calculateXPosition(index),
      y: calculateYPosition(index)
    };

    shapes.push({
      id: element.id,
      width: layoutProperties.nodeWidth,
      height: layoutProperties.nodeHeight,
      position,
      rotation:
        layoutProperties.nodeOrientation === Orientation.vertical ? -90 : 0
    });
  });

  const connectors: Connector[] = [];
  shapes.forEach((shape, index) => {
    shapes.forEach((s, i) => {
      if (i === index) return;
      const targetShape = shapes[i];

      const isReversed = i < index;

      connectors.push({
        sourceId: invertRelationships ? targetShape.id : shape.id,
        targetId: invertRelationships ? shape.id : targetShape.id,
        p1: {
          x:
            shape.position.x +
            (isReversed
              ? -layoutProperties.nodeWidth / 2
              : layoutProperties.nodeWidth / 2),
          y: shape.position.y
        },
        p2: {
          x: targetShape.position.x,
          y:
            targetShape.position.y +
            (isReversed
              ? layoutProperties.nodeHeight / 2
              : -layoutProperties.nodeHeight / 2)
        }
      });
    });
  });

  return {
    shapes,
    connectors,
    width: calculateMaxWidth(
      shapes,
      layoutProperties.nodeOrientation === Orientation.horizontal
        ? layoutProperties.nodeWidth
        : layoutProperties.nodeHeight
    ),
    height: calculateMaxHeight(
      shapes,
      layoutProperties.nodeOrientation === Orientation.vertical
        ? layoutProperties.nodeHeight
        : layoutProperties.nodeWidth
    )
  };
}
