import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import React from "react";
import { Release } from "../App";
import { fetchModel, useAppDispatch } from "../features";
import MailChimpForm from "./MailChimpForm";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "60%",
      maxHeight: "80%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.background.popover,
      zIndex: 2000
    },
    title: {
      padding: theme.spacing(1)
    },
    subtitle: {
      padding: theme.spacing(2)
    },
    list: {
      textAlign: "left",
      padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
      margin: theme.spacing(1),
      overflow: "auto",
      backgroundColor:
        theme.palette.mode === "dark"
          ? theme.palette.grey[800]
          : theme.palette.grey[200],
      borderRadius: theme.spacing(1)
    }
  });

export interface Props extends WithStyles<typeof styles> {
  version?: string;
  release?: Release;
}

const Welcome = ({ classes }: Props) => {
  const dispatch = useAppDispatch();

  const [open, setOpen] = React.useState(true);

  const loadTheoreticalSystem = React.useCallback(() => {
    dispatch(
      fetchModel(
        "https://cdn.jsdelivr.net/gh/lightzephyr/se-toolkit-examples@master/Theoretical%20System%2014.json"
      )
    );
  }, [dispatch]);

  const handleDismiss = React.useCallback(() => {
    setOpen(false);
  }, []);

  return open ? (
    <ClickAwayListener onClickAway={handleDismiss}>
      <Paper className={classes.root} variant="outlined">
        <Typography className={classes.title} variant="h3">
          Welcome to the Systems Engineering Toolkit
        </Typography>
        <Divider />
        <Typography
          sx={{
            padding: 2
          }}
          variant="h4"
          color="red"
        >
          SE-Toolkit is moving to a new domain!
        </Typography>
        <Typography
          sx={{
            display: "flex",
            padding: 2
          }}
          variant="h5"
        >
          {`From version 0.15, it is now online at:`}
          <Link
            sx={{ paddingLeft: 1 }}
            href="https://web.mbse.app"
            target="_blank"
            rel="noopener"
          >
            web.mbse.app
          </Link>
        </Typography>
        <Typography variant="body1" color="orange">
          se-toolkit.app will be forwarded to mbse.app from 1 January 2025,
          which will cause any model saved in the browser to be lost.
        </Typography>
        <Typography variant="body1" color="green">
          Please download your model to a json file and migrate to the mbse.app
          domain before 1 January 2025.
        </Typography>
        <Divider />
        <MailChimpForm />
        <Box
          sx={{
            display: "flex",
            padding: 1,
            width: "100%",
            justifyContent: "space-between"
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={loadTheoreticalSystem}
          >
            Load Theoretical System Model
          </Button>
          <Button variant="contained" color="primary" onClick={handleDismiss}>
            Dismiss
          </Button>
        </Box>
      </Paper>
    </ClickAwayListener>
  ) : null;
};

export default withStyles(styles, { withTheme: true })(Welcome);
