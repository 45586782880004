import { Model } from "../schema";
import { getTimeStamp } from "../utils";
import updateModelId from "./updateModelId";
/**
 * Update the model name.
 * @param model The model.
 * @param newName The new model name.
 * @param generateNewID If true, a new ID will be generated. Default is false.
 * @returns true if updated or false if not.
 */
export default function updateModelName(model, newName, generateNewID = false) {
    Model.shape.name.parse(newName);
    if (newName === model.name && !generateNewID)
        return false;
    if (newName !== model.name) {
        model.changeLog.push({
            time: getTimeStamp(),
            operation: "update",
            description: `Updated model name from '${model.name}' to '${newName}'.`
        });
        model.name = newName;
    }
    if (generateNewID) {
        return updateModelId(model);
    }
    return true;
}
