import { createSelector } from "@reduxjs/toolkit";
import { Component } from "@se-toolkit/model-js/schema";
import { findElementParents } from "@se-toolkit/model-js/search";
import React from "react";
import { useSelector } from "react-redux";
import { makeSelectModelElements } from "../../features";
import ElementData from "../components/ElementData";
import ElementsPopover from "../components/ElementsPopover";
import { Anchor } from "../interactions/useAnchor";

export interface Props {
  anchor: Anchor;
}

export default function AllocationPopover({ anchor }: Props) {
  const { id: elementId } = anchor;

  const elements: Component[] = useSelector(
    createSelector(
      makeSelectModelElements("Component"),
      (components: Component[]) =>
        findElementParents(components, elementId, "specified_by")
    )
  );

  const items = React.useMemo(
    () =>
      elements.map(e => (
        <ElementData
          key={e.id}
          id={e.id}
          label="Component Name"
          text={e.attributes.name}
        />
      )),
    [elements]
  );

  return (
    <ElementsPopover anchor={anchor} isEnabled={items.length > 0}>
      {items}
    </ElementsPopover>
  );
}
