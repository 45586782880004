import { Layout } from "../Layout";
import { Position } from "../Position";

export default function findConnectorAtPosition(
  layout: Layout,
  position: Position
): { sourceId: string; targetId: string } | null {
  /*
   * This calculation models the connector as an ellipse and checks to see
   * whether the given position is within the ellipse. The ellipse is modeled
   * with a minimum radius in order to prevent it dissapearing when the
   * connector is a horizontal or vertical line.
   */

  const rMin = 16;

  const connector = layout.connectors.find(c => {
    const { p1, p2 } = c;

    const dx = Math.abs(p2.x - p1.x);
    const dy = Math.abs(p2.y - p1.y);

    const xHalf = (p2.x - p1.x) / 2;
    const yHalf = (p2.y - p1.y) / 2;
    const x = p1.x + xHalf;
    const y = p1.y + yHalf;

    const rx = Math.max(dx / 2, rMin);
    const ry = Math.max(dy / 2, rMin);

    const a = Math.pow(position.x - x, 2) / Math.pow(rx, 2);
    const b = Math.pow(position.y - y, 2) / Math.pow(ry, 2);

    return a + b <= 1;
  });

  return connector
    ? { sourceId: connector.sourceId, targetId: connector.targetId }
    : null;
}
