import { ActionCreators } from "redux-undo";
import { useAppDispatch } from "../../features";
import useWindowEventListener from "./useWindowEventListener";

export default function useAppKeyboardShortcuts() {
  const dispatch = useAppDispatch();

  // shortcuts triggered on keydown
  useWindowEventListener("keydown", (e: KeyboardEvent) => {
    e.preventDefault();
    if (e.key === "d" && (e.ctrlKey || e.metaKey)) return;
    if (e.key === "s" && (e.ctrlKey || e.metaKey)) return;
    if (e.key === "z" && (e.ctrlKey || e.metaKey) && !e.shiftKey) {
      dispatch(ActionCreators.undo());
      return;
    }
    if (
      (e.key === "z" && e.metaKey && e.shiftKey) ||
      (e.key === "y" && e.ctrlKey)
    ) {
      dispatch(ActionCreators.redo());
      return;
    }
  });

  // shortcuts triggered on keyup
  useWindowEventListener("keyup", (e: KeyboardEvent) => {
    e.preventDefault();
    if (e.key === "s" && (e.ctrlKey || e.metaKey)) {
      window.dispatchEvent(new CustomEvent("savemodel"));
      return;
    }
  });
}
