import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Navigate, Route, Link as RouterLink, Routes } from "react-router-dom";
import useSubTabRouting, { TabRoute } from "../utils/useSubTabRouting";
import ActionsStakeholders from "./ActionsStakeholders";
import AssumptionsLessons from "./AssumptionsLessons";
import ChangeLogTable from "./ChangeLogTable";
import ComponentStats from "./ComponentStats";
import OpportunitiesRisks from "./OpportunitiesRisks";

export default function DashboardView() {
  const tabRoutes: TabRoute[] = [
    {
      label: "Actions & Stakeholders",
      path: "/actionsstakeholders",
      element: <ActionsStakeholders />
    },
    {
      label: "Opportunities & Risks",
      path: "/opportunitiesrisks",
      element: <OpportunitiesRisks />
    },
    {
      label: "Assumptions & Lessons",
      path: "/assumptionslessons",
      element: <AssumptionsLessons />
    },
    {
      label: "Statistics",
      path: "/statistics",
      element: <ComponentStats />
    },
    {
      label: "Change Log",
      path: "/changelog",
      element: <ChangeLogTable />
    }
  ];

  const defaultTab = tabRoutes[0].path;
  const { basePath, currentTab, indexPath, setLastActiveTab } =
    useSubTabRouting("dashboard", tabRoutes, defaultTab);

  const tabs = Object.values(tabRoutes).map(({ label, path }) => (
    <Tab
      key={path}
      label={label}
      component={RouterLink}
      to={`${basePath}${path}`}
      value={path}
      onClick={() => setLastActiveTab(path)}
    />
  ));

  const routes = Object.values(tabRoutes).map(({ path, element }) => (
    <Route key={path} path={`${path}/*`} element={element} />
  ));

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%"
      }}
    >
      <Tabs
        sx={{ borderRight: 1, borderColor: "divider" }}
        value={currentTab}
        orientation="vertical"
        indicatorColor="secondary"
        textColor="inherit"
      >
        {tabs}
      </Tabs>
      <Routes>
        <Route index={true} element={<Navigate to={indexPath} />} />
        {routes}
      </Routes>
    </Box>
  );
}
