import { Element } from "../schema";
import { getElementsArray } from "../schemautils";
import getAllElements from "../schemautils/getAllElements";
import findElementInModel from "../search/findElementInModel";
import getTimeStamp from "../utils/getTimeStamp";
import sortElements from "../utils/sortElements";
import sortRelationships from "../utils/sortRelationships";
/**
 * Update an element's id.
 * @param model The model.
 * @param oldId The old element id.
 * @param newId The new element id.
 * @returns true if updated or false if not.
 */
export default function updateElementId(model, oldId, newId) {
    Element.shape.id.parse(newId);
    if (newId === oldId)
        return false;
    const element = findElementInModel(model, oldId);
    if (!element) {
        throw new Error(`Failed to update element ID. An element with id ${oldId} does not exist.`);
    }
    if (findElementInModel(model, newId)) {
        throw new Error(`Cannot update element id. An element with id ${newId} already exists.`);
    }
    element.changeLog.push({
        time: getTimeStamp(),
        operation: "update",
        description: `Updated element ID from ${oldId} to ${newId}.`
    });
    element.id = newId;
    sortElements(getElementsArray(model, element.metatype));
    getAllElements(model).forEach(element => {
        element.relationships.forEach(relationship => {
            if (relationship.targetId === oldId) {
                relationship.targetId = newId;
            }
        });
        sortRelationships(element.relationships);
    });
    return true;
}
