import { diffWords } from "diff";
export default function diffText(left, right) {
    let result = "";
    const diff = diffWords(left, right);
    let hasRemoved = false;
    diff.forEach((item, index) => {
        const itemValue = item.value.trim();
        const leadingChar = index > 0 && itemValue !== "." ? " " : "";
        const trailingChar = index === diff.length - 1 ? ")" : "";
        if (!itemValue)
            return;
        if (item.removed) {
            result += `${leadingChar}(-${itemValue}${trailingChar}`;
            hasRemoved = true;
            return;
        }
        if (item.added) {
            result += `${hasRemoved ? "|" : `${leadingChar}(`}+${itemValue})`;
            hasRemoved = false;
            return;
        }
        if (hasRemoved) {
            result += `)`;
            hasRemoved = false;
        }
        result += `${leadingChar}${itemValue}`;
    });
    return result;
}
