/**
 * Compare the attributes of two objects.
 * @param left The left hand object.
 * @param right The right hand object.
 * @returns An object containing the keys that are different.
 * The value of the difference is the value of the key in the right hand object.
 * A key whose value is null is present in the left hand object but not present
 * in the right hand object.
 */
export default function diffAttributes(left, right) {
    const result = {};
    const leftKeys = Object.keys(left);
    leftKeys.forEach(key => {
        if (!(key in right)) {
            result[key] = null;
            return;
        }
        const leftValue = typeof left[key] === "string" ? left[key].trim() : left[key];
        const rightValue = typeof right[key] === "string"
            ? right[key].trim()
            : right[key];
        if (leftValue !== undefined &&
            rightValue !== undefined &&
            leftValue !== rightValue) {
            result[key] = rightValue;
        }
    });
    Object.keys(right).forEach(key => {
        if (leftKeys.indexOf(key) === -1) {
            const rightValue = right[key];
            if (rightValue !== undefined) {
                result[key] = rightValue;
            }
        }
    });
    if (!Object.keys(result).length)
        return {};
    return result;
}
