import { GridValueFormatterParams } from "@mui/x-data-grid";
import dayjs from "dayjs";
import React from "react";

export interface Args {
  showTime?: boolean;
}

export default function useDateTimeFormatter({ showTime = true }: Args = {}) {
  return React.useCallback(
    (params: GridValueFormatterParams<string>) => {
      if (!params.value) return "";
      return dayjs(parseInt(params.value) * 1000).format(
        `YYYY-MM-DD${showTime ? " @ HH:mm" : ""}`
      );
    },
    [showTime]
  );
}
