export interface Attributes {
  [key: string]: string;
}

const ns = "http://www.w3.org/2000/svg";
const makeSvgNode = (name: string, doc: Document) =>
  doc.createElementNS(ns, name);

const makeTextSpanNode = (text: string, dy: string, doc: Document) => {
  const node = doc.createTextNode(text);
  const span = makeSvgNode("tspan", doc);
  span.setAttribute("x", "0");
  span.setAttribute("y", "0");
  span.setAttribute("dy", dy);
  span.appendChild(node);
  return span;
};

const makeTextNode = (
  texts: string[],
  attrs: Attributes = {},
  doc: Document
) => {
  const node = makeSvgNode("text", doc);
  node.setAttribute("x", "0");
  node.setAttribute("y", "0");
  for (const attr in attrs) {
    node.setAttribute(attr, attrs[attr]);
  }
  texts.forEach((t, i) => node.appendChild(makeTextSpanNode(t, `${i}em`, doc)));
  return node;
};

// Takes a string, or array of strings, some svg attrs, and gives you back a
// {width, height} of the resulting svg box containing the strings.
const svgTextSize = (
  texts: string | string[],
  attrs: Attributes,
  doc: Document = document
) => {
  const textArr = Array.isArray(texts) ? texts : [texts];
  const svg = makeSvgNode("svg", doc);
  const textNode = makeTextNode(textArr, attrs, doc);
  svg.appendChild(textNode);
  doc.body.appendChild(svg);
  const { width, height } = textNode.getBoundingClientRect();
  doc.body.removeChild(svg);
  return { width, height };
};

export default function wrapSvgText(
  text: string,
  width: number,
  attrs: Attributes = {},
  doc = document
) {
  const words = text.split(/ +/);
  let lines: string[] = [];
  let currentLine: string[] = [];
  words.forEach(word => {
    const wordsPerLine = word.split(/\n|\s*\\n\s*/);
    if (wordsPerLine.length > 1) {
      currentLine.push(wordsPerLine[0].trim());
      lines.push(currentLine.join(" "));
      currentLine = [wordsPerLine[1].trim()];
      return;
    }
    const newLine = [...currentLine, word];
    const size = svgTextSize(newLine.join(" "), attrs, doc);
    if (size.width > width) {
      lines.push(currentLine.join(" "));
      currentLine = [word];
    } else {
      currentLine.push(word);
    }
  });
  lines.push(currentLine.join(" "));
  if (lines[0] === "") {
    lines.shift();
  }
  const size = svgTextSize(lines, attrs, doc);
  return { lines, size };
}
