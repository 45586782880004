import { HierarchyNode } from "@se-toolkit/model-js/analysis";
import { Layout, LayoutProperties, Orientation, Shape } from "./Layout";
import {
  calculateMaxHeight,
  calculateMaxWidth
} from "./utils/calculateMaxDimensions";
import { createConnectors } from "./utils/createConnectors";

/**
 * Create a hierarchy layout
 * @param hierarchy The hierarchical representation of the model.
 * @param layoutProperties The layout properties describing how to layout the nodes.
 * @returns A Layout object containing shapes and connectors as well as the total width and height of the layout.
 */
export default function createHierarchyLayout(
  hierarchy: HierarchyNode[],
  layoutProperties: LayoutProperties
): Layout {
  const xOffset =
    layoutProperties.nodeOrientation === Orientation.vertical
      ? layoutProperties.nodeHeight / 2
      : layoutProperties.nodeWidth / 2;
  const yOffset =
    layoutProperties.nodeOrientation === Orientation.vertical
      ? layoutProperties.nodeWidth / 2
      : layoutProperties.nodeHeight / 2;

  const xMultiplier =
    layoutProperties.nodeWidth + layoutProperties.horizontalSpacing;
  const yMultiplier =
    layoutProperties.nodeHeight + layoutProperties.verticalSpacing;

  const shapes: Shape[] = hierarchy.map(node => {
    const x =
      (layoutProperties.layoutOrientation === Orientation.vertical
        ? node.width
        : node.depth) || 0;

    const y =
      (layoutProperties.layoutOrientation === Orientation.vertical
        ? node.depth
        : node.width) || 0;

    const position = {
      x: x * xMultiplier + xOffset,
      y: y * yMultiplier + yOffset
    };

    return {
      id: node.id,
      width: layoutProperties.nodeWidth,
      height: layoutProperties.nodeHeight,
      position,
      rotation:
        layoutProperties.nodeOrientation === Orientation.vertical ? -90 : 0
    };
  });

  return {
    shapes,
    connectors: createConnectors(shapes, hierarchy, layoutProperties),
    width: calculateMaxWidth(
      shapes,
      layoutProperties.nodeOrientation === Orientation.horizontal
        ? layoutProperties.nodeWidth
        : layoutProperties.nodeHeight
    ),
    height: calculateMaxHeight(
      shapes,
      layoutProperties.nodeOrientation === Orientation.vertical
        ? layoutProperties.nodeHeight
        : layoutProperties.nodeWidth
    ),
    orientation: layoutProperties.layoutOrientation
  };
}
