import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { AnyElement } from "@se-toolkit/model-js/schema";
import { findElement } from "@se-toolkit/model-js/search";
import React from "react";
import { enqueueSnackbar, useAppDispatch } from "../../../features";
import {
  Connector,
  LayoutProperties,
  createMatrixLayout
} from "../../../layouts";
import AnnotationsPopover from "../../composition/AnnotationsPopover";
import DescriptionPopover from "../../composition/DescriptionPopover";
import SpecificationPopover from "../../composition/SpecificationPopover";
import Diagram from "../../diagrams/Diagram";
import { LConnector } from "../../diagrams/shapes/LConnector";
import useToggleRelationship from "../../featurehooks/useToggleRelationship";
import {
  useAnchor,
  useConnectorAnchor,
  usePan,
  useWindowEventListener
} from "../../interactions";
import useDrawComponent from "../utils/useDrawComponent";
import layoutProperties from "./layoutProperties.json";

export interface Props {
  elements: AnyElement[];
}

export default function InterfaceDesignDiagram({ elements }: Props) {
  const dispatch = useAppDispatch();

  const diagramRef = React.useRef<HTMLDivElement>(null);
  const svgRef = React.useRef<SVGSVGElement>(null);

  const diagramLayout = React.useMemo(() => {
    return createMatrixLayout(
      elements,
      layoutProperties.matrix as LayoutProperties,
      true
    );
  }, [elements]);

  const descriptionAnchor = useAnchor(diagramRef);
  const annotationsAnchor = useAnchor(diagramRef);
  const specificationAnchor = useAnchor(diagramRef);
  const interfaceAnchor = useConnectorAnchor(diagramRef);

  usePan(svgRef, { multiplier: 1.1 });

  const hasWheelInfoDisplayed = React.useRef(false);
  useWindowEventListener(
    "wheel",
    React.useCallback(() => {
      if (!hasWheelInfoDisplayed.current) {
        dispatch(
          enqueueSnackbar(`Pan the diagram by grabbing it and dragging it.`, {
            variant: "info"
          })
        );
        hasWheelInfoDisplayed.current = true;
      }
    }, [dispatch])
  );

  const linkSourceToTarget = useToggleRelationship("input_from");

  const drawShape = useDrawComponent({
    descriptionAnchor,
    annotationsAnchor,
    specificationAnchor
  });

  const drawConnector = React.useCallback(
    (connector: Connector) => {
      const { sourceId, targetId } = connector;

      const isConnected = !!findElement(elements, sourceId)?.relationships.find(
        r => r.targetId === targetId && r.type === "input_from"
      );

      return (
        <LConnector
          connector={connector}
          width={layoutProperties.matrix.nodeWidth / 2}
          height={layoutProperties.matrix.nodeHeight / 2}
          arrowSize={15}
          squareSize={25}
          isConnected={isConnected}
          onClick={() => {
            linkSourceToTarget(sourceId, targetId);
          }}
          setAnchor={interfaceAnchor.set}
        />
      );
    },
    [elements, interfaceAnchor.set, linkSourceToTarget]
  );

  const noElementsMessage = React.useMemo(
    () =>
      elements.length === 0 ? (
        <Typography
          sx={{
            display: "flex",
            height: "100%",
            alignItems: "center",
            justifyContent: "center"
          }}
          variant="body1"
        >
          No interfaces to display. The selected element is a leaf element.
        </Typography>
      ) : null,
    [elements.length]
  );

  return (
    <Paper
      ref={diagramRef}
      sx={{
        position: "relative",
        height: "100%"
      }}
    >
      <Diagram
        svgRef={svgRef}
        layout={diagramLayout}
        drawShape={drawShape}
        drawConnector={drawConnector}
      />
      <DescriptionPopover anchor={descriptionAnchor} />
      <AnnotationsPopover anchor={annotationsAnchor} />
      <SpecificationPopover anchor={specificationAnchor} />
      {noElementsMessage}
    </Paper>
  );
}
