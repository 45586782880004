import LayersIcon from "@mui/icons-material/Layers";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React from "react";
import { useWindowEventListener } from "../../interactions";

export interface Props {
  depth: number;
  disabled?: boolean;
  setDepth: (value: number) => void;
}

export default function DepthSelector({
  depth,
  disabled = false,
  setDepth
}: Props) {
  useWindowEventListener(
    "setdepth",
    React.useCallback(
      (e: CustomEvent) => {
        if (!e.detail) {
          setDepth(-1);
          return;
        }
        setDepth(parseInt(e.detail));
      },
      [setDepth]
    )
  );

  const buttons = React.useMemo(() => {
    return [1, 2, 3].map(d => (
      <Tooltip
        key={`depth${d}`}
        title={`Set depth to ${d} (${d})`}
        aria-label={`Set depth to ${d} (${d})`}
        placement="bottom"
        disableFocusListener={true}
      >
        <span>
          <Button
            sx={{
              maxHeight: "32px",
              padding: "0px 10px",
              textTransform: "none"
            }}
            color="inherit"
            aria-label={`Set depth to ${d} (${d})`}
            size="medium"
            variant={depth === d ? "outlined" : undefined}
            disabled={disabled}
            onClick={() => setDepth(d)}
          >
            <Typography variant="h5">{d}</Typography>
          </Button>
        </span>
      </Tooltip>
    ));
  }, [depth, disabled, setDepth]);

  return (
    <>
      <Tooltip
        title="Set depth to ∞ (0)"
        aria-label="Set depth to ∞ (0)"
        placement="bottom"
        disableFocusListener={true}
      >
        <span>
          <Button
            sx={{
              maxHeight: "32px",
              padding: "0px 10px",
              textTransform: "none"
            }}
            color="inherit"
            aria-label="Depth"
            size="medium"
            variant={depth === -1 ? "outlined" : undefined}
            disabled={disabled}
            onClick={() => setDepth(-1)}
          >
            <LayersIcon fontSize="large" />
            <Typography variant="h5">∞</Typography>
          </Button>
        </span>
      </Tooltip>
      {buttons}
    </>
  );
}
