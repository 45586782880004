import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "../notifications/notificationsSlice";
import { RootState } from "../store";
import {
  clearError,
  createElement,
  createNewModel,
  createRelationship,
  deleteElements,
  deleteRelationship,
  insertRelationship,
  loadModel,
  moveRelationships,
  purgeChangeLogs,
  replaceRelationship,
  setLastSaved,
  toggleRelationship,
  updateElement,
  updateElementId,
  updateModelName
} from "./modelSlice";

// Create the middleware instance and methods
export const modelListenerMiddleware = createListenerMiddleware();

const modelModiferMatcher = isAnyOf(
  createElement,
  createRelationship,
  deleteElements,
  deleteRelationship,
  insertRelationship,
  moveRelationships,
  purgeChangeLogs,
  replaceRelationship,
  toggleRelationship,
  updateElement,
  updateElementId,
  updateModelName
);

// Listen for new models and clear the settings, modification counter, and last saved timestamp
modelListenerMiddleware.startListening({
  matcher: isAnyOf(createNewModel, loadModel),
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(setLastSaved());
  }
});

// Listen for model modifications and check for errors
modelListenerMiddleware.startListening({
  matcher: modelModiferMatcher,
  effect: async (action, listenerApi) => {
    const { error } = (listenerApi.getState() as RootState).model.present;
    if (error) {
      console.log(`Error executing action: ${action.type}: ${error}`);
      listenerApi.dispatch(enqueueSnackbar(error, { variant: "warning" }));
      listenerApi.dispatch(clearError());
    }
  }
});
