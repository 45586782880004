import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowModel,
  GridRowParams,
  GridRowsProp
} from "@mui/x-data-grid";
import { HierarchyNode } from "@se-toolkit/model-js/analysis";
import { Milestone } from "@se-toolkit/model-js/schema";
import { findElementParents } from "@se-toolkit/model-js/search";
import React from "react";
import { useSelector } from "react-redux";
import {
  deleteElements,
  makeSelectModelElements,
  selectModel,
  updateElement,
  updateElementId,
  useAppDispatch
} from "../../../features";
import { Type } from "../../components/InteractionIcon";
import PaperTable from "../../components/PaperTable";
import AnnotationsPopover from "../../composition/AnnotationsPopover";
import { useAnchor } from "../../interactions";
import ExportToXlsxButton from "../controls/ExportToXlsxButton";
import useNumberStringComparator from "../gridutils/useNumberStringComparator";
import usePreProcessEditID from "../gridutils/usePreProcessEditID";
import useRenderInteractionIcon from "../gridutils/useRenderInteractionIcon";
import useRenderMilestoneStatus from "../gridutils/useRenderMilestoneStatus";

export interface Props {
  hierarchy: HierarchyNode[];
  selectedIds?: string[];
  setSelectedIds?: (ids: string[]) => void;
}

export default function RoadmapTable({
  hierarchy,
  selectedIds = [],
  setSelectedIds = () => {}
}: Props) {
  const dispatch = useAppDispatch();

  const model = useSelector(selectModel);
  const annotations = useSelector(makeSelectModelElements("Annotation"));
  const milestones = useSelector(makeSelectModelElements("Milestone"));

  const rows: GridRowsProp = React.useMemo(
    () =>
      hierarchy.map(e => {
        const milestone = e.element as Milestone | undefined;
        const hasAnnotations: boolean = !!findElementParents(
          annotations,
          e.id,
          "annotates"
        ).length;
        return {
          number: e.number,
          id: e.id,
          ...milestone?.attributes,
          hasAnnotations
        };
      }),
    [annotations, hierarchy]
  );

  const processRowUpdate = React.useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel): GridRowModel => {
      let isUpdated = false;
      if (newRow.id && newRow.id !== oldRow.id) {
        dispatch(
          updateElementId({
            oldId: oldRow.id,
            newId: newRow.id
          })
        );
        isUpdated = true;
      }
      if (newRow.name && newRow.name !== oldRow.name) {
        dispatch(
          updateElement({
            id: oldRow.id,
            attributes: { name: newRow.name }
          })
        );
        isUpdated = true;
      }
      if (newRow.description !== oldRow.description) {
        dispatch(
          updateElement({
            id: oldRow.id,
            attributes: { description: newRow.description }
          })
        );
        isUpdated = true;
      }
      return isUpdated ? newRow : oldRow;
    },
    [dispatch]
  );

  const renderActions = React.useCallback(
    (params: GridRowParams) => [
      <GridActionsCellItem
        icon={
          <Tooltip title={`Delete ${params.row.id}`} placement="left">
            <DeleteIcon />
          </Tooltip>
        }
        label="Delete"
        onClick={() => dispatch(deleteElements([params.row.id]))}
      />
    ],
    [dispatch]
  );

  const preProcessEditID = usePreProcessEditID(milestones);
  const renderStatus = useRenderMilestoneStatus();
  const numberStringComparator = useNumberStringComparator();

  const gridRef = React.useRef<HTMLDivElement>(null);
  const annotationsAnchor = useAnchor(gridRef);
  const renderAnnotations = useRenderInteractionIcon(
    Type.Annotations,
    annotationsAnchor,
    "hasAnnotations"
  );

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "number",
        headerName: "Number",
        sortComparator: numberStringComparator
      },
      {
        field: "id",
        headerName: "ID",
        editable: true,
        sortComparator: numberStringComparator,
        preProcessEditCellProps: preProcessEditID
      },
      {
        field: "name",
        headerName: "Name",
        width: 250,
        editable: true
      },
      {
        field: "status",
        headerName: "Status",
        width: 150,
        renderCell: renderStatus
      },
      {
        field: "description",
        headerName: "Description",
        flex: 1,
        editable: true
      },
      {
        field: "annotations",
        type: "actions",
        headerName: "Annotations",
        getActions: renderAnnotations
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        getActions: renderActions
      }
    ],
    [
      numberStringComparator,
      preProcessEditID,
      renderStatus,
      renderAnnotations,
      renderActions
    ]
  );

  // TODO: Remove this limitation by upgrading to x-data-grid pro
  const singleSelectedId = React.useMemo(
    () => (selectedIds.length > 0 ? [selectedIds[0]] : []),
    [selectedIds]
  );

  return (
    <>
      <PaperTable
        tableName="Roadmap"
        gridRef={gridRef}
        title="Milestones"
        rows={rows}
        columns={columns}
        processRowUpdate={processRowUpdate}
        rowSelectionModel={singleSelectedId}
        onRowSelectionModelChange={setSelectedIds}
        initialState={{
          columns: {
            columnVisibilityModel: {
              number: false
            }
          },
          sorting: {
            sortModel: [{ field: "number", sort: "desc" }]
          }
        }}
      />
      <AnnotationsPopover anchor={annotationsAnchor} />
      <Box sx={{ position: "absolute", top: 16, right: 16 }}>
        <ExportToXlsxButton
          model={model}
          sheetSelection={[["Roadmap", "Roadmap"]]}
          initialFileName={`${model.name} Roadmap`}
        />
      </Box>
    </>
  );
}
