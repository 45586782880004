import { styled, useTheme } from "@mui/material/styles";
import { Connector, Orientation } from "../../../layouts";

export interface Props {
  connector: Connector;
  orientation?: Orientation;
  isBezier?: boolean;
  arrowSize?: number;
  highlightOnHover?: boolean;
}

export const ZConnector = ({
  connector,
  orientation = Orientation.horizontal,
  isBezier = false,
  arrowSize = 0,
  highlightOnHover = false
}: Props) => {
  const theme = useTheme();

  const { sourceId: source, targetId: target, p1, p2 } = connector;

  const dx = Math.abs(p2.x - p1.x);
  const dy = Math.abs(p2.y - p1.y);
  const minWidth = parseInt(theme.spacing(2));

  const xHalf = (p2.x - p1.x) / 2;
  const yHalf = (p2.y - p1.y) / 2;
  const x = p1.x + xHalf;
  const y = p1.y + yHalf;

  const x0 = -xHalf;
  const y0 = -yHalf;
  let x3 = xHalf;
  let y3 = yHalf;

  let x1 = x0;
  let y1 = y0;
  let x2 = x3;
  let y2 = y3;
  let arrowPoints = "";

  switch (orientation) {
    case Orientation.horizontal:
      x1 = 0;
      x2 = 0;
      x3 = x3 - arrowSize;
      arrowPoints = `${x3},${y3}, ${x3},${y3 + arrowSize / 2}, ${
        x3 + arrowSize
      },${y3}, ${x3},${y3 - arrowSize / 2}, ${x3},${y3}`;
      break;
    case Orientation.vertical:
      y1 = 0;
      y2 = 0;
      y3 = y3 - arrowSize;
      arrowPoints = `${x3},${y3}, ${x3 + arrowSize / 2},${y3}, ${x3},${
        y3 + arrowSize
      }, ${x3 - arrowSize / 2},${y3}, ${x3},${y3}`;
      break;
    default:
      break;
  }

  return (
    <g transform={`translate(${x},${y})`}>
      {isBezier ? (
        <path
          className="connector"
          d={`M ${x0} ${y0} C ${x1} ${y1} ${x2} ${y2} ${x3} ${y3}`}
        />
      ) : (
        <polyline
          className="connector"
          points={`${x0},${y0} ${x1},${y1} ${x2},${y2} ${x3},${y3}`}
        />
      )}
      {arrowSize > 0 ? (
        <polyline className="arrow" points={arrowPoints} />
      ) : null}
      {highlightOnHover ? (
        <StyledEllipse
          rx={Math.max(dx / 2, minWidth)}
          ry={Math.max(dy / 2, minWidth)}
          data-source={source}
          data-target={target}
          className="connector-underlay exclude-from-export"
          fill={"url(#gradientDefinition)"}
          fillOpacity={0}
          stroke="none"
        />
      ) : null}
    </g>
  );
};

const StyledEllipse = styled("ellipse")({
  "&:hover": {
    fillOpacity: 0.5
  }
});
