import jsonp from "jsonp";
import React from "react";

/*
 * This code was adapted for typescript from use-mailchimp-form on 17.06.2020.
 * https://github.com/imgarylai/use-mailchimp-form.
 */

export interface FormFields {
  [key: string]: string;
}

export enum State {
  Idle = "Idle",
  Submitting = "Submitting",
  Error = "Error",
  Success = "Success"
}

export function useMailChimpAPI(url: string) {
  const [state, setState] = React.useState(State.Idle);
  const [message, setMessage] = React.useState("");

  const handleSubmit = (event: React.FormEvent, data: FormFields) => {
    event.preventDefault();
    const query = new URLSearchParams(data).toString();
    const endpoint = url.replace("/post?", "/post-json?") + "&" + query;

    setState(State.Submitting);
    setMessage(State.Submitting);

    jsonp(endpoint, { param: "c" }, (error: Error | null, data: any) => {
      let message = "";
      if (error) {
        setState(State.Error);
        message = error.message;
      } else if (data.result !== "success") {
        setState(State.Error);
        message = data.msg;
      } else {
        setState(State.Success);
        message = data.msg;
      }
      const pattern = new RegExp("<s*a[^>]*>(.*?)<s*/s*a>", "g");
      setMessage(message.replace(pattern, ""));
    });
  };

  return { state, message, handleSubmit };
}

export function useFormFields(
  initialState: FormFields
): [FormFields, (event: React.ChangeEvent<HTMLInputElement>) => void] {
  const [fields, setValues] = React.useState(initialState);

  const setField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...fields,
      [event.target.id]: event.target.value
    });
  };

  return [fields, setField];
}
