import diffText from "./diffText";
/**
 * Generate a change description for a text update.
 * @param attribute The name of the attribute to use in the change description.
 * @param oldText The old text.
 * @param newText The new text.
 * @returns the generated change description or null if the text has not changed.
 */
export default function generateTextChangeDescription(attribute, oldText, newText) {
    if (newText === oldText) {
        return null;
    }
    if (oldText && !newText) {
        return `Removed ${attribute}: "${oldText}"`;
    }
    else if (!oldText && newText) {
        return `Added ${attribute}: "${newText}"`;
    }
    else {
        return `Updated ${attribute}: "${diffText(oldText, newText)}"`;
    }
}
