import { MINIMUM_SCHEMA_VERSION, Model, SCHEMA_VERSION } from "../schema";
import upgradeModel from "./upgradeModel";
/**
 * Validate the model
 * @param obj A javascript object.
 */
export default function validateModel(obj) {
    if (!obj) {
        throw new ReferenceError("The model is not defined.");
    }
    if (!obj.hasOwnProperty("SCHEMA_VERSION")) {
        throw new TypeError("The model does not have the property: SCHEMA_VERSION.");
    }
    if (obj.SCHEMA_VERSION.localeCompare(MINIMUM_SCHEMA_VERSION, undefined, {
        numeric: true
    }) < 0) {
        throw new RangeError(`Schemas older than version ${MINIMUM_SCHEMA_VERSION} are no longer supported.`);
    }
    if (obj.SCHEMA_VERSION.localeCompare(SCHEMA_VERSION, undefined, {
        numeric: true
    }) < 0) {
        upgradeModel(obj);
    }
    Model.parse(obj);
}
