import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { HierarchyNode } from "@se-toolkit/model-js/analysis";
import React from "react";
import { Connector, LayoutProperties } from "../../../layouts";
import AnnotationsPopover from "../../composition/AnnotationsPopover";
import ComponentTypeMenu from "../../composition/ComponentTypeMenu";
import DescriptionPopover from "../../composition/DescriptionPopover";
import SpecificationPopover from "../../composition/SpecificationPopover";
import StakeholderMenu from "../../composition/StakeholderMenu";
import Diagram from "../../diagrams/Diagram";
import { ZConnector } from "../../diagrams/shapes/ZConnector";
import useInsertRelationship from "../../featurehooks/useInsertRelationship";
import useMoveRelationships from "../../featurehooks/useMoveRelationships";
import useToggleRelationship from "../../featurehooks/useToggleRelationship";
import {
  getEventTargetData,
  useAnchor,
  useElementEventListener,
  useLinkSourceToTarget,
  usePan,
  useZoom
} from "../../interactions";
import DynamicLink from "../controls/DynamicLink";
import SaveAsImageButton from "../controls/SaveAsImageButton";
import SaveAsSVGButton from "../controls/SaveAsSVGButton";
import useDrawComponent from "../utils/useDrawComponent";
import useHierarchyLayout from "../utils/useHierarchyLayout";
import layoutProperties from "./layoutProperties.json";

export type LabelMode = "ElementType" | "Stakeholders";

export interface Props {
  hierarchy: HierarchyNode[];
  selectedIds: string[];
  svgRef: React.RefObject<SVGSVGElement>;
  labelMode?: LabelMode;
  showParentIndicator?: boolean;
  initialFileName?: string;
  setRootId?: (id: string) => void;
  onPointerUp?: (e: React.PointerEvent) => void;
  onTouchEnd?: (e: React.TouchEvent) => void;
}

export default function SystemStructureDiagram({
  hierarchy,
  selectedIds,
  svgRef,
  labelMode = "ElementType",
  showParentIndicator = false,
  initialFileName = "System Structure",
  setRootId = () => {},
  onPointerUp = () => {},
  onTouchEnd = () => {}
}: Props) {
  const diagramRef = React.useRef<HTMLDivElement>(null);

  const diagramLayout = useHierarchyLayout({
    hierarchy,
    layoutProperties: layoutProperties.hierarchy as LayoutProperties
  });

  const descriptionAnchor = useAnchor(diagramRef);
  const annotationsAnchor = useAnchor(diagramRef);
  const specificationAnchor = useAnchor(diagramRef);

  useElementEventListener(
    diagramRef,
    "dblclick",
    React.useCallback(
      (e: MouseEvent) => {
        e.preventDefault();
        const id = getEventTargetData(e.target, "id");
        if (id) {
          if (e.ctrlKey || e.metaKey) {
            setRootId(id);
            return;
          }
          window.dispatchEvent(new CustomEvent("updateelements"));
        }
      },
      [setRootId]
    )
  );

  const linkSourceToTarget = useToggleRelationship("built_from", "toSource");
  const moveSourceToTarget = useMoveRelationships(
    hierarchy,
    "built_from",
    "toSource"
  );
  const insertSourceAtConnector = useInsertRelationship(
    hierarchy,
    "built_from"
  );
  const { isLinkActive, onLinkStart, onLinkFinish, onLinkCancel } =
    useLinkSourceToTarget({
      diagramSvgRef: svgRef,
      hierarchyLayout: diagramLayout,
      linkSourceToTarget,
      moveSourceToTarget,
      insertSourceAtConnector
    });

  usePan(svgRef, { multiplier: 1.1 });
  useZoom(svgRef, { multiplier: 3.0 });

  const handleClickParentIndicator = React.useMemo(() => {
    return showParentIndicator
      ? () => window.dispatchEvent(new CustomEvent("setparentasroot"))
      : undefined;
  }, [showParentIndicator]);

  const drawShape = useDrawComponent({
    selectedIds,
    descriptionAnchor,
    annotationsAnchor,
    specificationAnchor,
    labelMode
  });

  const drawConnector = React.useCallback(
    (connector: Connector) => {
      return (
        <ZConnector
          connector={connector}
          orientation={diagramLayout.orientation}
          highlightOnHover={isLinkActive}
        />
      );
    },
    [diagramLayout.orientation, isLinkActive]
  );

  const labelMenu = React.useMemo(
    () =>
      labelMode === "Stakeholders" ? (
        <StakeholderMenu
          eventListenerRef={diagramRef}
          relationshipType={"responsible_for"}
        />
      ) : (
        <ComponentTypeMenu eventListenerRef={diagramRef} />
      ),
    [labelMode]
  );

  return (
    <Paper
      ref={diagramRef}
      sx={{
        position: "relative",
        height: "100%"
      }}
      onPointerUp={onPointerUp}
      onTouchEnd={onTouchEnd}
    >
      <Diagram
        svgRef={svgRef}
        layout={diagramLayout}
        drawShape={drawShape}
        drawConnector={drawConnector}
        handleClickParentIndicator={handleClickParentIndicator}
      />
      <DynamicLink
        eventListenerRef={diagramRef}
        onLinkStart={onLinkStart}
        onLinkFinish={onLinkFinish}
        onLinkCancel={onLinkCancel}
      />
      {labelMenu}
      <DescriptionPopover anchor={descriptionAnchor} />
      <AnnotationsPopover anchor={annotationsAnchor} />
      <SpecificationPopover anchor={specificationAnchor} />
      <Box sx={{ position: "absolute", top: 16, right: 16 }}>
        <SaveAsSVGButton svgRef={svgRef} initialFileName={initialFileName} />
        <SaveAsImageButton svgRef={svgRef} initialFileName={initialFileName} />
      </Box>
    </Paper>
  );
}
