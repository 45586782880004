import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { ClickAwayListener } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { AnyElement } from "@se-toolkit/model-js/schema";
import React from "react";
import SearchInput from "../../components/SearchInput";

export interface Props {
  elements: AnyElement[];
  disabled?: boolean;
  tooltipText?: string;
  handleSubmit: (id: string) => void;
}

export default function ElementPickerPopover({
  elements,
  disabled = false,
  tooltipText = "Pick an element",
  handleSubmit
}: Props) {
  const theme = useTheme();

  const options = React.useMemo(
    () =>
      elements.map(e => ({
        id: e.id,
        label: `${"name" in e.attributes ? e.attributes.name : ""} (${e.id})`
      })),
    [elements]
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const onClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);

  return (
    <>
      <Tooltip
        title={tooltipText}
        aria-label={tooltipText}
        placement="left"
        disableFocusListener={true}
      >
        <span>
          <IconButton
            aria-label="submit"
            size="small"
            onClick={onClick}
            disabled={disabled}
          >
            <DoubleArrowIcon sx={{ height: 20, width: 20 }} />
          </IconButton>
        </span>
      </Tooltip>
      <Popper
        sx={{
          minWidth: "20%",
          maxHeight: "50%",
          overflow: "auto"
        }}
        id="element-picker-popover"
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper
            sx={{
              backgroundColor: theme.palette.background.popover,
              border: `${theme.spacing(0.1)} solid ${
                theme.palette.secondary.main
              }`,
              padding: 2
            }}
          >
            <SearchInput
              options={options}
              onClose={handleClose}
              selectOption={handleSubmit}
            />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
}
