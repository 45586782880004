import DeleteIcon from "@mui/icons-material/Delete";
import { red } from "@mui/material/colors";
import Fab from "@mui/material/Fab";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import getCurrentView from "../../utils/getCurrentView";
import { useWindowEventListener } from "../interactions";

export default function DeleteButton() {
  const theme = useTheme();

  let view = getCurrentView();
  const [disabled, setDisabled] = React.useState(
    !view || view === "Interfaces"
  );
  useWindowEventListener(
    "changeview",
    React.useCallback((e: CustomEvent) => {
      setDisabled(["/dashboard", "/interfaces"].includes(e.detail));
    }, [])
  );

  const onClick = React.useCallback(() => {
    window.dispatchEvent(new CustomEvent("deleteelements"));
  }, []);

  return (
    <>
      <Tooltip
        title="Delete (-, delete)"
        aria-label="Delete (-, delete)"
        placement="right"
        disableFocusListener={true}
      >
        <span>
          <Fab
            sx={{
              margin: theme.spacing(1),
              color: red[400]
            }}
            aria-label="Delete"
            color="primary"
            size="medium"
            disabled={disabled}
            onClick={onClick}
          >
            <DeleteIcon />
          </Fab>
        </span>
      </Tooltip>
    </>
  );
}
