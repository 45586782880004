import parseElementAttributes from "./parseElementAttributes";
export default function createEmptyElement(id, metatype, attributes) {
    if (!id) {
        throw new RangeError(`Cannot create an element with an empty id.`);
    }
    const changeLog = [];
    const relationships = [];
    const _attributes = parseElementAttributes(metatype, attributes);
    return {
        id,
        metatype,
        changeLog,
        relationships,
        attributes: _attributes
    };
}
