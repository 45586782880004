import { z } from "zod";
import { Element } from "./Element";
import { Relationship } from "./Relationship";
/*
 * Refer to https://github.com/lightzephyr/se-toolkit-model-js/wiki/Modifications.
 */
export const ModificationInstruction = z.object({
    timestamp: z.number().min(1546297200, {
        message: "A modification instruction may not have a time stamp earlier than 2019."
    }),
    createElements: z
        .array(Element.pick({ id: true, metatype: true, attributes: true }))
        .optional(),
    createRelationships: z.array(Relationship).optional(),
    deleteElements: z
        .array(Element.pick({ id: true, metatype: true, attributes: true }))
        .optional(),
    deleteRelationships: z.array(Relationship).optional(),
    revertElements: z
        .array(Element.pick({ id: true, attributes: true }))
        .optional(),
    updateElements: z
        .array(Element.pick({ id: true, attributes: true }))
        .optional()
});
