import React from "react";
import InteractionIcon, { Type } from "../../components/InteractionIcon";

export interface Props {
  sourceId: string;
  targetId: string;
  x?: number;
  y?: number;
  width: number;
  height: number;
  color?: string;
  isOn?: boolean;
  setAnchor?: (e: SVGRectElement | null, isConnected: boolean) => void;
  onClick?: (e: React.MouseEvent) => void;
  onMouseEnter?: (e: React.MouseEvent) => void;
  onMouseLeave?: (e: React.MouseEvent) => void;
}

export default function Interface({
  sourceId,
  targetId,
  x = 0,
  y = 0,
  width,
  height,
  color,
  isOn = true,
  setAnchor = () => {},
  onClick = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {}
}: Props) {
  const rectRef = React.useRef<SVGRectElement>(null);

  const handleClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      setAnchor(null, isOn);
      onClick(e);
    },
    [isOn, onClick, setAnchor]
  );

  const handleMouseEnter = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      setAnchor(e.currentTarget as SVGRectElement, isOn);
      onMouseEnter(e);
    },
    [isOn, onMouseEnter, setAnchor]
  );

  const handleMouseLeave = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      setAnchor(null, isOn);
      onMouseLeave(e);
    },
    [isOn, onMouseLeave, setAnchor]
  );

  const iconProps = {
    className: "exclude-from-export",
    width,
    height,
    x,
    y,
    htmlColor: color
  };

  return (
    <>
      {<InteractionIcon type={Type.Link} isOn={isOn} {...iconProps} />}
      <rect
        ref={rectRef}
        data-sourceid={sourceId}
        data-targetid={targetId}
        className="exclude-from-export"
        width={width}
        height={height}
        x={x}
        y={y}
        opacity={0}
        pointerEvents="fill"
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </>
  );
}
