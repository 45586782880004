import { PieValueType } from "@mui/x-charts/models/seriesType/pie";
import { Count } from "@se-toolkit/model-js/analysis/Count";
import { formatVariableName } from "@se-toolkit/model-js/utils";

export function generatePieChartData(
  count: Count,
  getColour: (key: string) => string
): PieValueType[] {
  const data: PieValueType[] = [];
  for (const [key, value] of count.items) {
    data.push({
      id: key,
      value,
      label: formatVariableName(key),
      color: getColour(key)
    });
  }
  return data;
}
