import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import React from "react";
import { purgeChangeLogs, useAppDispatch } from "../../../features";
import TextWithInlineDateInput from "../../components/TextWithInlineDateInput";
import TextWithInlineNumberInput from "../../components/TextWithInlineNumberInput";
import { useStopPropogation } from "../../interactions";

export default function PurgeChangeLogsButton() {
  const dispatch = useAppDispatch();

  const [open, setOpen] = React.useState(false);
  const [mode, setMode] = React.useState("all");
  const [numberValue, setNumberValue] = React.useState(1);
  const [dateValue, setDateValue] = React.useState(Date.now() / 1000);

  const onClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = React.useCallback(() => {
    setOpen(false);
    switch (mode) {
      case "all":
        dispatch(purgeChangeLogs());
        break;
      case "number":
        dispatch(purgeChangeLogs({ preserveLastEntries: numberValue }));
        break;
      case "date":
        dispatch(purgeChangeLogs({ preserveAfterDate: dateValue }));
        break;
    }
  }, [dateValue, dispatch, numberValue, mode]);

  const stopPropagation = useStopPropogation();

  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      stopPropagation(e);
      if (e.key === "Enter") onSubmit();
    },
    [onSubmit, stopPropagation]
  );

  const handleModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMode((event.target as HTMLInputElement).value);
  };

  const handleNumberChange = (value: number) => {
    if (mode !== "number") {
      setMode("number");
    }
    setNumberValue(value);
  };

  const handleDateChange = (value: number) => {
    if (mode !== "date") {
      setMode("date");
    }
    setDateValue(value);
  };

  return (
    <>
      <Button
        sx={{ marginLeft: 5 }}
        variant="outlined"
        color="warning"
        onClick={onClick}
      >
        Purge Change Logs
      </Button>
      <Dialog
        open={open}
        onClose={onClose}
        onKeyDown={onKeyDown}
        onKeyUp={stopPropagation}
        aria-labelledby="purge-change-log-dialog"
        maxWidth="xl"
      >
        <DialogTitle id="purge-change-log-dialog-title">
          Purge Change Logs
        </DialogTitle>
        <DialogContent>
          <RadioGroup
            aria-labelledby="purge-change-log-mode-radio-group"
            name="purge-change-log-mode"
            value={mode}
            onChange={handleModeChange}
          >
            <FormControlLabel
              value="all"
              control={<Radio />}
              label="Purge the change logs of all entries"
            />
            <FormControlLabel
              value="number"
              control={<Radio />}
              label={
                <TextWithInlineNumberInput
                  value={numberValue}
                  leadingText="Purge the change logs of all but the last"
                  trailingText="entries"
                  min={1}
                  handleChange={handleNumberChange}
                />
              }
            />
            <FormControlLabel
              value="date"
              control={<Radio />}
              label={
                <TextWithInlineDateInput
                  value={dateValue}
                  leadingText="Purge change log entries older than"
                  handleChange={handleDateChange}
                />
              }
            />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="inherit">
            Cancel
          </Button>
          <Button onClick={onSubmit} color="warning">
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
