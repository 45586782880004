import EditIcon from "@mui/icons-material/Edit";
import Fab from "@mui/material/Fab";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import getCurrentView from "../../utils/getCurrentView";
import { useWindowEventListener } from "../interactions";

export default function UpdateButton() {
  const theme = useTheme();

  let view = getCurrentView();
  const [disabled, setDisabled] = React.useState(
    !view || view === "Interfaces"
  );
  useWindowEventListener(
    "changeview",
    React.useCallback((e: CustomEvent) => {
      setDisabled(["/dashboard", "/interfaces"].includes(e.detail));
    }, [])
  );

  const onClick = React.useCallback(() => {
    window.dispatchEvent(new CustomEvent("updateelements"));
  }, []);

  return (
    <>
      <Tooltip
        title="Update"
        aria-label="Update"
        placement="right"
        disableFocusListener={true}
      >
        <span>
          <Fab
            sx={{ margin: theme.spacing(1) }}
            aria-label="Update"
            color="primary"
            size="medium"
            disabled={disabled}
            onClick={onClick}
          >
            <EditIcon />
          </Fab>
        </span>
      </Tooltip>
    </>
  );
}
