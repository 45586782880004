/**
 * Create a calculator function
 * @param dimension The dimension parameter.
 * @param spacing The spacing parameter.
 * @returns A function that calculates the position as a function of
 * the provided dimension and spacing parameters as well as an index argument.
 * This function is intended to calculate the position at which to draw a node
 * when drawing a hierarchical map or tree structure. In this case, the dimension is
 * typically the width or height of the node, while the spacing is the spacing
 * between two adjacent nodes. This index argument becomes the index of the node
 * within an array of siblings.
 */
export default function createCalculatorFnOfIndex(
  dimension: number,
  spacing: number
) {
  return (index: number) => {
    return dimension / 2 + index * (dimension + spacing);
  };
}
