import TextField from "@mui/material/TextField";
import React from "react";

export interface Props {
  value: string;
  submitChange: (value: string) => void;
}

export default function Description({ value, submitChange }: Props) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const newValue = React.useRef<string>(value);

  const onClick = React.useCallback((e: React.MouseEvent<HTMLInputElement>) => {
    if (inputRef.current && inputRef.current !== document.activeElement) {
      e.preventDefault();
      const input = inputRef.current;
      input.focus();
      input.setSelectionRange(input.value.length, input.value.length);
    }
  }, []);

  const onChangeDescription = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      newValue.current = e.currentTarget.value;
    },
    []
  );

  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter" && inputRef.current) e.stopPropagation();
      if (e.key === "Escape" && inputRef.current) {
        newValue.current = value;
        inputRef.current.value = value;
      }
    },
    [value]
  );

  React.useEffect(() => {
    return () => {
      if (newValue.current.trim() !== value) {
        submitChange(newValue.current.trim());
      }
    };
  }, [submitChange, value]);

  let placeholder = value
    ? "Existing description will be deleted. Press escape to cancel."
    : "Enter a new description...";

  return (
    <TextField
      inputRef={inputRef}
      id="description-input"
      type="text"
      label="Description"
      color="secondary"
      multiline={true}
      fullWidth={true}
      margin="dense"
      variant="outlined"
      defaultValue={value}
      placeholder={placeholder}
      onClick={onClick}
      onChange={onChangeDescription}
      onKeyDown={onKeyDown}
    />
  );
}
