import copyElement from "./copyElement";
/**
 * Copy the model.
 * @param model The model to be copied.
 * @returns A deep copy of the model.
 */
export default function copyModel({ id, name, SCHEMA_VERSION, changeLog, uidSeeds, annotations, components, milestones, requirements, stakeholders }) {
    return {
        id,
        name,
        SCHEMA_VERSION,
        changeLog: changeLog.map(c => ({ ...c })),
        uidSeeds: { ...uidSeeds },
        annotations: annotations.map(a => copyElement(a)),
        components: components.map(c => copyElement(c)),
        milestones: milestones.map(m => copyElement(m)),
        requirements: requirements.map(r => copyElement(r)),
        stakeholders: stakeholders.map(s => copyElement(s))
    };
}
