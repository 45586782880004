export default function getEventTargetData(
  e: EventTarget | null,
  attribute: string
) {
  if (
    (e instanceof HTMLElement || e instanceof SVGElement) &&
    e.dataset.hasOwnProperty(attribute)
  ) {
    return e.dataset[attribute];
  }
  return undefined;
}
